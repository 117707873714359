import { Controller } from "stimulus"
import { interpolate } from "../../utils"

export default class extends Controller {
  static targets = ["wrapper"]

  initialize() {
    this.scrolling = false
  }

  gotoPrevious() {
    this.scrollTo(this.previousItemPosition)
  }

  gotoNext() {
    this.scrollTo(this.nextItemPosition)
  }

  get currentPosition() {
    return this.wrapperTarget.scrollLeft
  }

  get previousItemPosition() {
    const position = this.currentPosition
    const children = this.wrapperTarget.querySelectorAll('.carousel__item')
    let offset = 0

    for(let item of children) {
      const itemOffset = item.offsetLeft - item.parentNode.offsetLeft
      if(itemOffset == position) return offset;
      if(itemOffset + item.clientWidth > position) return offset;
      offset = itemOffset
    }

    return offset
  }

  get nextItemPosition() {
    const position = this.currentPosition
    const children = this.wrapperTarget.querySelectorAll('.carousel__item')
    let offset = 0

    for(let item of children) {
      offset = item.offsetLeft - item.parentNode.offsetLeft
      if(offset > position) return offset;
    }

    return offset
  }

  scrollTo(position) {
    if(this.scrolling) return false;

    const start  = this.currentPosition
    const end    = position
    const target = this.wrapperTarget

    this.scrolling = true

    interpolate(250, {
      onTick: (t) => {
        target.scrollLeft = (start * (1 - t)) + (end * t)
      },
      onComplete: () => {
        this.scrolling = false
      }
    })
  }
}
