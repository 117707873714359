import api from '../api'

export const STORE_ALL_MESSAGES = 'STORE_ALL_MESSAGES'
export const STORE_MESSAGE = 'STORE_MESSAGE'
export const STORE_MESSAGE_SERIES = 'STORE_MESSAGE_SERIES'

export const loadMessages = ({ includeMessageId }) => (dispatch) => {
  let url = '/admin/messages'
  if (includeMessageId) {
    url += `?include_message_id=${includeMessageId}`
  }

  return api
    .get(url, { headers: { Accept: 'application/json' } })
    .then(({ data: messages }) => {
      dispatch({
        type: STORE_ALL_MESSAGES,
        messages,
      })
    })
}

export const loadMostRecentMessage = () => (dispatch) =>
  api
    .get('/admin/messages?limit=1', { headers: { Accept: 'application/json' } })
    .then(({ data: messages }) => {
      dispatch({
        type: STORE_MESSAGE,
        message: messages[0],
      })
    })

export const loadMessageSeries = ({ includeSeriesId }) => (dispatch) => {
  let url = '/admin/series'
  if (includeSeriesId) {
    url += `?include_series_id=${includeSeriesId}`
  }

  return api
    .get(url, { headers: { Accept: 'application/json' } })
    .then(({ data: series }) => {
      dispatch({
        type: STORE_MESSAGE_SERIES,
        series,
      })
    })
}
