import { useDispatch } from 'react-redux'
import { compact, get } from 'lodash'
import api from '../api'

export const STORE_FEEDS = 'STORE_FEEDS'

export const loadFeeds = () => (dispatch) => {
  const url = "/admin/feeds"
  return api
    .get(url, { headers: { Accept: 'application/json' } })
    .then(({ data: feeds }) => {
      dispatch({
        type: STORE_FEEDS,
        feeds
      })
    })
}
