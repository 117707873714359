import { compact, get } from 'lodash'
import api from '../api'
import { confirmChangesCanBeDiscarded } from '../actions'
import { updateCardPreview } from './cardPreview/actions'

export const SET_EDITING = 'SET_EDITING'
export const UPDATE_COMPONENT = 'UPDATE_COMPONENT'
export const UPDATE_VERSION = 'UPDATE_VERSION'

function setEditingIgnoringChanges(revisionOrVersion, fallback) {
  return {
    type: SET_EDITING,
    editing: revisionOrVersion,
    fallback,
  }
}

export function setEditing(revisionOrVersion, fallback = null) {
  return function(dispatch) {
    return dispatch(confirmChangesCanBeDiscarded()).then(
      () => {
        dispatch(setEditingIgnoringChanges(revisionOrVersion, fallback))
        dispatch(updateCardPreview())
      },
      (error) => null,
    )
  }
}

export function updateComponent(component, field, value) {
  return {
    type: UPDATE_COMPONENT,
    component,
    field,
    value,
  }
}

export function updateVersion(field, value) {
  return {
    type: UPDATE_VERSION,
    field,
    value,
  }
}
