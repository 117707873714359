import { SECTION_HEIGHT } from '../../config/layouts'

const gridPosition = ({ numColumns, numBlocks, columnIndex, height }) => {
  if (numColumns === 1) {
    if (numBlocks === 1) {
      // +-------+
      // |       |
      // +-------+
      return 'full'
    } else {
      // +-------+
      // |_|_|_|_|
      // | | | | |
      // +-------+
      return 'grid'
    }
  } else if (numBlocks > 2) {
    // +-------+
    // |???|_|_|
    // |???| | |
    // +-------+
    return 'grid'
  } else if (height === SECTION_HEIGHT.short) {
    // +-------+
    // | | | | |
    // +-------+
    return 'grid'
  } else if (numBlocks > 1) {
    // +-------+
    // | | | | |
    // | | | | |
    // +-------+
    return 'quarter'
  } else if (columnIndex === 0) {
    // +-------+
    // | X |   |
    // +-------+
    return 'left'
  } else {
    // +-------+
    // |   | X |
    // +-------+
    return 'right'
  }
}

export default gridPosition
