import { Controller } from "stimulus"
import { Attachment } from "trix"
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from '../../editPageView/store'
import MediaChooser from '../../editPageView/components/pageEditor/components/MediaChooser'
import { delegateListener, getCSRFSettings } from "../../utils"

const videoEditorTemplate = document.createElement("details")
videoEditorTemplate.classList.add("details-modal", "details-modal--with-overlay", "details-modal--detached")
videoEditorTemplate.innerHTML = `
  <summary></summary>
  <div class="details-modal__content details-modal__content--detached">
    <h2>Video Attachment</h2>

    <label>Title</label>
    <input type="text" data-target="trix-video-attachment.title">

    <label>Video Source</label>
    <select data-target="trix-video-attachment.videoSource">
      <option>YouTube</option>
      <option>Vimeo</option>
    </select>

    <label>Video ID</label>
    <input type="text" data-target="trix-video-attachment.videoId">

    <label data-action="click->trix-video-attachment#showMediaChooser" class="bg-gray__btn btn-fit-content">Choose Cover Image</label>
    <input type="hidden" data-target="trix-video-attachment.coverImageId">

    <img
      class="margin-top-1 trix-video-attachment-cover-image"
      data-target="trix-video-attachment.coverImageFile"
      src=""
    />

    <a
      class="margin-top-1"
      href=""
      data-action="click->trix-video-attachment#removeCoverImage"
      data-target="trix-video-attachment.coverImageRemove">
      Remove
    </a>

    <div class="margin-top-1">
      <button type="button" class="bg-primary__btn" data-action="trix-video-attachment#attachVideo">Save Attachment</button>
    </div>
  </div>
`

export default class extends Controller {
  static targets = ["field", "title", "videoSource", "videoId", "coverImageId", "coverImageFile", "coverImageRemove"]

  connect() {
    this.trix = this.fieldTarget.editor
    this.videoEditor.open = false
    this.coverImageUrl = null;

    delegateListener(this.fieldTarget, "dblclick", `.attachment[data-trix-content-type="application/vnd.symplexi.video.html"]`, (e, target) => {
      this.editVideoAttachment(target)
    })
  }

  newVideoAttachment(event) {
    this.titleTarget.value = ""
    this.videoSourceTarget.value = "YouTube"
    this.videoIdTarget.value = ""
    this.coverImageIdTarget.value = ""
    this.coverImageFileTarget.src = "";
    this.coverImageUrl = null;
    this.coverImageRemoveTarget.style.display = 'none';
    this.videoEditor.open = true
  }

  editVideoAttachment(attachment) {
    const attachmentData = JSON.parse(attachment.dataset.trixAttachment)
    this.titleTarget.value = attachmentData.title
    this.videoSourceTarget.value = attachmentData.videoSource
    this.videoIdTarget.value = attachmentData.videoId
    this.coverImageIdTarget.value = attachmentData.coverImageId
    this.coverImageUrl = attachmentData.coverImageUrl
    this.videoEditor.open = true

    if (this.coverImageUrl) {
      this.coverImageFileTarget.src = this.coverImageUrl
      this.coverImageRemoveTarget.style.display = 'block';
    }
  }

  attachVideo() {
    if (!this.videoIdTarget.value) {
      alert('Video ID is required.')
      return
    }

    this.createVideoAttachment({
      title: this.titleTarget.value,
      videoSource: this.videoSourceTarget.value,
      videoId: this.videoIdTarget.value,
      coverImageId: this.coverImageIdTarget.value,
      coverImageUrl: this.coverImageUrl
    })
    this.videoEditor.open = false
  }

  createVideoAttachment(settings) {
    fetch("/trix/attachments/videos", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        ...getCSRFSettings(),
        ...settings
      })
    }).then(response => response.json())
      .then(attachment => {
        this.insertVideoAttachment({
          ...settings,
          ...attachment
        })
      })
  }

  insertVideoAttachment(data) {
    const attachment = new Attachment({
      contentType: "application/vnd.symplexi.video.html",
      ...data
    })

    this.trix.insertAttachment(attachment)
  }

  removeCoverImage(event) {
    event.preventDefault();
    this.coverImageIdTarget.value = ''
    this.coverImageFileTarget.src = ''
    this.coverImageUrl = null
    this.coverImageRemoveTarget.style.display = 'none'
  }

  setCoverImage(chosenImage) {
    this.coverImageIdTarget.value = chosenImage.mediaId
    this.coverImageUrl = chosenImage.imageUrl
    this.coverImageFileTarget.src = this.coverImageUrl
    this.coverImageRemoveTarget.style.display = 'block'
  }

  showMediaChooser() {
    this.mediaChooser.open = true
  }

  get videoEditor() {
    if(this.renderedVideoEditor) {
      return this.renderedVideoEditor
    }

    this.renderedVideoEditor = this.element.appendChild(videoEditorTemplate)
    return this.renderedVideoEditor
  }

  get mediaChooser() {
    if(this.renderedMediaChooser) {
      return this.renderedMediaChooser
    }

    const popupContainer = document.createElement("details")
    popupContainer.classList.add("details-modal", "details-modal--with-overlay", "details-modal--detached")
    popupContainer.innerHTML = `<summary></summary>`

    const popupContent = document.createElement("div")
    popupContent.classList.add("details-modal__content", "details-modal__content--detached", "details-modal__content--fullscreen")
    ReactDOM.render(
      <Provider store={store}>
        <MediaChooser
          onChoose={chosen => {
            this.setCoverImage(chosen)
            this.mediaChooser.open = false
          }}
        />
      </Provider>,
      popupContent
    )
    popupContainer.appendChild(popupContent)

    document.body.appendChild(popupContainer)

    this.renderedMediaChooser = popupContainer

    return this.renderedMediaChooser
  }
}
