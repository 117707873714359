import { STORE_ENVIRONMENTS } from './actions'

function environments(state = [], action) {
  switch (action.type) {
    case STORE_ENVIRONMENTS:
      return action.environments
    default:
      return state
  }
}

export default environments
