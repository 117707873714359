import React, { Component } from 'react'
import Select2 from 'react-select2-wrapper'
import { connect } from 'react-redux'
import { loadEnvironments } from '../../../../store/environments/actions'

class EnvironmentSelectInput extends Component {
  componentDidMount() {
    this.props.loadEnvironments()
  }

  render() {
    const { onChange, value, environments, placeholder } = this.props
    return (
      <Select2
        multiple
        defaultValue={value}
        onChange={onChange}
        options={{
          placeholder: placeholder,
        }}
        data={environments.map((environment) => ({
          id: environment.id,
          text: environment.name,
        }))}
      />
    )
  }
}

const mapStateToProps = (state) => ({ environments: state.environments })
const mapDispatchToProps = { loadEnvironments }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnvironmentSelectInput)
