import { Component } from 'react'

export default class JSColor extends Component {
  componentDidMount() {
    window?.jscolor?.installByClassName?.(this.props.jsColorClassName)
  }

  render() {
    return this.props.children
  }
}
