import React from 'react'
import { Formik } from 'formik'
import { get } from 'lodash'
import Panel from '../../Panel'
import FieldContainer from '../../FieldContainer'
import RunValidationTrigger from './RunValidationTrigger'
import MediaField from '../../MediaChooser/MediaField'
import urlRegex from '../../urlRegex'
import JSColor from './JSColor'
import { clearExportData } from './utils'

const EditSocialCalloutBlock = ({
  config,
  newBlock,
  onSave,
  onDelete,
  onCancel,
}) => (
  <JSColor jsColorClassName="jscolor-social-callout">
    <Panel title="Social Callout">
      <Formik
        initialValues={config}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => onSave(clearExportData(values))}
        render={({
          values,
          errors,
          handleChange,
          setFieldValue,
          validateForm,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <MediaField
              label="Background Image"
              mediaId={values.backgroundImageId}
              exportedFileName={get(values, 'exportData.backgroundImage')}
              onChange={(backgroundImageId) =>
                setFieldValue('backgroundImageId', backgroundImageId)
              }
            />
            <div className="df-row align-flex-start">
              <div className="img-upload-preview">
                <div
                  id="backgroundColorPreview"
                  className="primary-color-preview"
                  style={{ backgroundColor: values.backgroundColor }}
                >
                  Aa
                </div>
              </div>
              <FieldContainer
                containerClasses="flex-1"
                error={errors.backgroundColor}
              >
                <label htmlFor="backgroundColor">Background Color</label>
                <div className="edit-social-callout__background-input">
                  <input
                    type="text"
                    id="backgroundColor"
                    data-testid="backgroundColorField"
                    className="jscolor-social-callout"
                    data-jscolor="{styleElement: 'backgroundColorPreview'}"
                    // defaultValue instead of value because we're not using onChange
                    defaultValue={values.backgroundColor}
                    // onChange does not get value set by jscolor picker, onBlur does
                    onBlur={() => {
                      if (event.target.value) {
                        setFieldValue('backgroundColor', event.target.value)
                      }
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue('backgroundColor', '000000')
                      document
                        .getElementById('backgroundColor')
                        .jscolor.fromString('000000')
                    }}
                    className="far fa-trash-alt"
                  />
                </div>
              </FieldContainer>
            </div>
            <FieldContainer error={errors.title}>
              <label htmlFor="title">Title</label>
              <textarea
                type="text"
                id="title"
                data-testid="titleField"
                className="edit-social-callout__textarea"
                value={values.title}
                onChange={handleChange}
              />
            </FieldContainer>
            <FieldContainer error={errors.facebookURL}>
              <label htmlFor="subtitle">Facebook URL</label>
              <input
                type="text"
                id="facebookURL"
                data-testid="facebookURLField"
                value={values.facebookURL}
                onChange={handleChange}
                placeholder="https://www.facebook.com"
              />
            </FieldContainer>
            <FieldContainer error={errors.instagramURL}>
              <label htmlFor="subtitle">Instagram URL</label>
              <input
                type="text"
                id="instagramURL"
                data-testid="instagramURLField"
                value={values.instagramURL}
                onChange={handleChange}
                placeholder="https://www.instagram.com"
              />
            </FieldContainer>
            <FieldContainer error={errors.spotifyURL}>
              <label htmlFor="subtitle">Spotify URL</label>
              <input
                type="text"
                id="spotifyURL"
                data-testid="spotifyURLField"
                value={values.spotifyURL || ''}
                onChange={handleChange}
                placeholder="https://spotify.com"
              />
            </FieldContainer>
            <FieldContainer error={errors.twitterURL}>
              <label htmlFor="subtitle">Twitter URL</label>
              <input
                type="text"
                id="twitterURL"
                data-testid="twitterURLField"
                value={values.twitterURL}
                onChange={handleChange}
                placeholder="https://twitter.com"
              />
            </FieldContainer>
            <FieldContainer error={errors.youtubeURL}>
              <label htmlFor="subtitle">YouTube URL</label>
              <input
                type="text"
                id="youtubeURL"
                data-testid="youtubeURLField"
                value={values.youtubeURL}
                onChange={handleChange}
                placeholder="https://www.youtube.com"
              />
            </FieldContainer>
            <div>
              <button
                type="submit"
                data-testid="saveButton"
                onClick={handleSubmit}
                className="bg-green__btn l-margin-right-sm"
              >
                Save Block
              </button>
              <button
                type="submit"
                onClick={onCancel}
                className="bg-gray__btn l-margin-right-sm"
              >
                Cancel
              </button>
              <button
                type="button"
                title="Delete Block"
                onClick={onDelete}
                className="page-editor-edit-form__delete-icon"
              >
                <i className="far fa-trash-alt" />
              </button>
            </div>
            {newBlock ? null : (
              <RunValidationTrigger validateForm={validateForm} />
            )}
          </form>
        )}
      />
    </Panel>
  </JSColor>
)

const validate = (values) => {
  const errors = {}
  if (!values.title) {
    errors.title = 'Title is required'
  }
  if (!values.backgroundColor) {
    errors.backgroundColor = 'Background color is required'
  }
  if (values.facebookURL && !values.facebookURL.match(urlRegex)) {
    errors.facebookURL = 'Facebook URL is not valid'
  }
  if (values.instagramURL && !values.instagramURL.match(urlRegex)) {
    errors.instagramURL = 'Instagram URL is not valid'
  }
  if (values.spotifyURL && !values.spotifyURL.match(urlRegex)) {
    errors.spotifyURL = 'Spotify URL is not valid'
  }
  if (values.twitterURL && !values.twitterURL.match(urlRegex)) {
    errors.twitterURL = 'Twitter URL is not valid'
  }
  if (values.youtubeURL && !values.youtubeURL.match(urlRegex)) {
    errors.youtubeURL = 'YouTube URL is not valid'
  }

  return errors
}

export default EditSocialCalloutBlock
