import { STORE_PAGE } from '../../actions'
import { UPDATE_HEADER } from './actions'

const initialData = {}

function header(state = initialData, action) {
  switch (action.type) {
    // case STORE_PAGE:
    //   return action.page.header || {}
    case UPDATE_HEADER:
      return action.header
    default:
      return state
  }
}

export default header
