class TrixVideoAttachment extends HTMLElement {
  connectedCallback() {
    this.loadContent()
  }

  loadContent() {
    if(!this.insideEditor()) {
      fetch(`/trix/attachments/videos/${this.attachmentId}?title=${this.title}&videoSource=${this.videoSource}&videoId=${this.videoId}&coverImageId=${this.coverImageId}`, { headers: { "Accept" : "application/json" }})
        .then(response => response.json())
        .then(json => this.updateContent(json.content))
    }
  }

  updateContent(content) {
    this.innerHTML = content
  }

  insideEditor() {
    let parent = this.parentNode
    while(parent && parent.matches) {
      if(parent.matches("trix-editor")) {
        return true
      }

      parent = parent.parentNode
    }

    return false
  }

  get attachmentId() {
    return this.getAttribute("attachment-id")
  }

  get title() {
    return this.getAttribute("title")
  }

  get videoSource() {
    return this.getAttribute("video-source")
  }

  get videoId() {
    return this.getAttribute("video-id")
  }

  get coverImageId() {
    return this.getAttribute("cover-image-id")
  }
}
    
customElements.define('trix-video-attachment', TrixVideoAttachment)
