import React from 'react'

const EmptyBlockView = ({ height, gridPosition }) => {
  const blockHeight = gridPosition == 'full' ? 'tall' : height

  const classNames = `page-section__empty-block content-block-fixed-height__${blockHeight}`
  return (
    <div className="content-block-controller__content-block">
      <div className={classNames} />
    </div>
  )
}

export default EmptyBlockView
