import { Component } from 'react'
import { connect } from 'react-redux'
import { loadMostRecentMessage } from '../../../../../../store/messages/actions'

class MostRecentMessageLoader extends Component {
  componentDidMount() {
    this.props.loadMostRecentMessage()
  }

  render() {
    return null
  }
}

const mapDispatchToProps = { loadMostRecentMessage }

export default connect(
  null,
  mapDispatchToProps,
)(MostRecentMessageLoader)
