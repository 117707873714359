import { combineReducers } from 'redux'

import {
  REQUEST_PAGE,
  STORE_PAGE,
  STORE_PUBLISHED_REVISION,
  SET_ERROR,
  CLEAR_ERROR,
  SET_PAGE_VALIDATION_ERRORS,
  CLEAR_PAGE_VALIDATION_ERRORS,
  SAVING,
  OPEN_SCHEDULE_VERSION_MODAL,
  CLOSE_SCHEDULE_VERSION_MODAL,
  OPEN_SCHEDULE_ARCHIVE_MODAL,
  CLOSE_SCHEDULE_ARCHIVE_MODAL,
  UPDATE_PERMISSIONS,
  SET_ALERT,
  CLEAR_ALERT,
  STORE_REVISIONS,
  STORE_VERSIONS,
  STORE_VERSION,
  REMOVE_VERSION
} from './actions'

import { SET_EDITING } from './editing/actions'

import editing from './editing/reducers'
import media, { mediaTags } from './media/reducers'
import pages from './pages/reducers'
import messages from './messages/reducers'
import events from './events/reducers'
import environments from './environments/reducers'
import feeds from './feeds/reducers'

function schedulingVersion(state = false, action) {
  switch (action.type) {
    case OPEN_SCHEDULE_VERSION_MODAL:
      return true
    case CLOSE_SCHEDULE_VERSION_MODAL:
      return false
    default:
      return state
  }
}

function schedulingArchive(state = false, action) {
  switch (action.type) {
    case OPEN_SCHEDULE_ARCHIVE_MODAL:
      return true
    case CLOSE_SCHEDULE_ARCHIVE_MODAL:
      return false
    default:
      return state
  }
}

function error(state = null, action) {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...action.error,
        cleared: action.cleared || false,
      }
    case CLEAR_ERROR:
      return {
        ...(state || {}),
        cleared: true,
      }
    case SAVING:
    case SET_EDITING:
      return null
    default:
      return state
  }
}

function pageValidationErrors(state = null, action) {
  switch (action.type) {
    case SET_PAGE_VALIDATION_ERRORS:
      return action.error
    case CLEAR_PAGE_VALIDATION_ERRORS:
      return null
    default:
      return state
  }
}

function revisions(state = [], action) {
  switch(action.type) {
    case STORE_REVISIONS:
      return [...action.revisions]
    case STORE_PUBLISHED_REVISION:
      return [...state, action.revision]
    default:
      return state
  }
}

function versions(state = [], action) {
  switch(action.type) {
    case STORE_VERSIONS:
      return [...action.versions]
    case STORE_VERSION: {
      const { version } = action
      const i = state.findIndex(other => other.id == version.id)
      let versions = [...state]

      if (i === -1) {
        versions.push(version)
      } else {
        versions.splice(i, 1, version)
      }

      return versions
    }
    case REMOVE_VERSION: {
      const { version } = action
      const i = state.findIndex(other => other.id == version.id)
      let versions = [...state]

      if (i !== -1) {
        versions.splice(i, 1)
      }

      return versions
    }
    default:
      return state
  }
}

function page(state = null, action) {
  switch (action.type) {
    case REQUEST_PAGE:
      return null
    case STORE_PAGE:
      return state ? { ...state, ...action.page } : action.page
    case STORE_PUBLISHED_REVISION:
      const { revision } = action

      return {
        ...state,
        is_archived: false,
        is_drafting: false,
        is_published: true,
        latest_revision: revision,
        live_revision: revision,
      }
    default:
      return state
  }
}

function loading(state = false, action) {
  switch (action.type) {
    case REQUEST_PAGE:
      return true
    case STORE_PAGE:
      return false
    default:
      return state
  }
}

function saving(state = false, action) {
  switch (action.type) {
    case REQUEST_PAGE:
    case STORE_PAGE:
      return false
    case SAVING:
      return action.status
    default:
      return state
  }
}

const defaultPermissions = {
  'pages.change_type': false,
  'pages.change_name': false,
  'pages.change_slug': false,
  'pages.change_environment': false,
  'editor.change_code': false,
}

function permissions(state = defaultPermissions, action) {
  switch (action.type) {
    case UPDATE_PERMISSIONS:
      return action.permissions
    default:
      return state
  }
}

function alert(state = {}, action) {
  switch (action.type) {
    case SET_ALERT:
      return {
        message: action.message,
        level: action.level,
      }
    case CLEAR_ALERT:
    case STORE_PAGE:
    case STORE_VERSION:
    case REQUEST_PAGE:
    case SET_EDITING:
    case SAVING:
      return {}
    default:
      return state
  }
}

const eventDetailsEvents = (state = [], action) => {
  switch (action.type) {
    case "SET_EVENT_DETAILS_EVENTS":
      return action.events
    default:
      return state
  }
}

export default combineReducers({
  error,
  pageValidationErrors,
  page,
  revisions,
  versions,
  loading,
  saving,
  editing,
  media,
  mediaTags,
  pages,
  messages,
  events,
  environments,
  schedulingVersion,
  schedulingArchive,
  permissions,
  alert,
  eventDetailsEvents,
  feeds
})
