import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducers from './reducers'
import { devToolsEnhancer } from 'redux-devtools-extension'

export default createStore(
  reducers,
  compose(
    applyMiddleware(thunk),
    devToolsEnhancer(),
  ),
)
