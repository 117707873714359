import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select2 from 'react-select2-wrapper'
import { loadPages } from '../../../../store/pages/actions'
import FieldContainer from '../FieldContainer'

class PageSelectInput extends Component {
  state = { loaded: false }

  componentDidMount() {
    const includePageId = this.props.value
    this.props.loadPages({ includePageId }).then(() => {
      this.setState({ loaded: true })
    })
  }

  render() {
    if (!this.state.loaded) {
      return <p>Loading…</p>
    }

    const {
      onChange,
      value,
      error,
      pages,
      exportedName,
      fieldName,
    } = this.props
    const emptyValue = exportedName
      ? `(select a page; previously ${exportedName})`
      : '(select a page)'

    const selectedPage = pages.find((testPage) => Number(value) === testPage.id)

    let errorToShow = error
    if (
      !errorToShow &&
      (selectedPage !== undefined && selectedPage.status !== 'published')
    ) {
      errorToShow = `Page is ${selectedPage.status}`
    }

    return (
      <FieldContainer
        error={errorToShow}
        testId={this.props['data-testid']}
        fieldClasses="invisible-select page-editor-select2-container"
      >
        <label htmlFor="cardableId">{fieldName}</label>
        <Select2
          data-testid={this.props['data-testid']}
          onChange={onChange}
          defaultValue={value}
          data={[
            { id: '', text: emptyValue },
            ...pages.map((page) => ({
              id: page.id,
              text: `${page.name} - /${page.slug} ${
                page.status !== 'published' ? ` (${page.status})` : ''
              }`,
            })),
          ]}
        />
      </FieldContainer>
    )
  }
}

const mapStateToProps = (state) => ({ pages: state.pages })
const mapDispatchToProps = { loadPages }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageSelectInput)
