import { Controller } from "stimulus"
import { Attachment } from "trix"
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from '../../editPageView/store'
import MediaChooser from '../../editPageView/components/pageEditor/components/MediaChooser'
import { getCSRFSettings } from "../../utils"

export default class extends Controller {
  static targets = ["field"]

  connect() {
    this.trix = this.fieldTarget.editor
    this.mediaChooser.open = false
  }

  newMediaAttachment() {
    this.mediaChooser.open = true
  }

  createMediaAttachment(mediaId) {
    fetch(`/trix/attachments/media`, {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ...getCSRFSettings(),
        attachment: {
          image_id: mediaId
        }
      })
    })
      .then(response => response.json())
      .then(media => this.insertMediaAttachment(media))
  }

  insertMediaAttachment(media) {
    const attachment = new Attachment({
      sgid: media.sgid,
      content: media.content
    })

    this.trix.insertAttachment(attachment)
    this.trix.insertString("\n")
  }

  get mediaChooser() {
    if(this.renderedMediaChooser) {
      return this.renderedMediaChooser
    }

    const popupContainer = document.createElement("details")
    popupContainer.classList.add("details-modal", "details-modal--with-overlay", "details-modal--detached")
    popupContainer.innerHTML = `<summary></summary>`

    const popupContent = document.createElement("div")
    popupContent.classList.add("details-modal__content", "details-modal__content--detached", "details-modal__content--fullscreen")
    ReactDOM.render(
      <Provider store={store}>
        <MediaChooser
          onChoose={chosen => {
            this.createMediaAttachment(chosen.mediaId)
            this.mediaChooser.open = false
          }}
        />
      </Provider>,
      popupContent
    )
    popupContainer.appendChild(popupContent)

    document.body.appendChild(popupContainer)

    this.renderedMediaChooser = popupContainer

    return this.renderedMediaChooser
  }
}
