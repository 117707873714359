import React from 'react'
import { interpolate } from '../utils'

const FADE_DURATION = 250

class ContextNotification extends React.Component {
  state = { opacity: 1 }

  componentDidMount() {
    setTimeout(() => {
      this.fadeOut()
    }, this.props.duration)

    if (this.props.target) {
      this.setState({
        targetPosition: this.props.target.getBoundingClientRect(),
      })
    }
  }

  fadeOut = () => {
    interpolate(FADE_DURATION, {
      onTick: (t) => {
        this.setState({ opacity: 1 - t })
      },
      onComplete: () => {
        this.props.onComplete()
      },
    })
  }

  render() {
    const { targetPosition } = this.state
    const top = targetPosition ? targetPosition.bottom : 0
    const right = targetPosition ? window.innerWidth - targetPosition.right : 0

    const xOffset = 0
    const yOffset = 10

    return (
      <span
        className="context-notification"
        style={{
          opacity: this.state.opacity,
          top: `${top + yOffset}px`,
          right: `${right + xOffset}px`,
        }}
      >
        {this.props.message}
      </span>
    )
  }
}

export default ContextNotification
