import { whenReady } from '../utils'

const key = process.env.GOOGLE_MAPS_API_KEY || ""

window["__loadingGoogleMapsApi"] = window["__loadingGoogleMapsApi"] || null

export const loadGoogleMapsApi = () => {
  if(window["__loadingGoogleMapsApi"]) {
    return window["__loadingGoogleMapsApi"]
  }

  window["__loadingGoogleMapsApi"] = new Promise((resolve, reject) => {
    if(window.google && window.google.maps) {
      resolve(window.google.maps)
    }else{
      whenReady()
        .then(() => {
          const script = document.createElement("script")
          script.src = `https://maps.googleapis.com/maps/api/js?key=${key}`
          script.onload = () => resolve(window.google.maps)
          document.body.appendChild(script)
        })
    }
  })

  return window["__loadingGoogleMapsApi"]
}