import { findIndex } from 'lodash'
import {
  STORE_MEDIA_PAGE,
  STORE_MEDIA_ITEMS,
  STORE_MEDIA_TAGS
} from './actions'

const initialData = {}

const mergeById = (array = [], toMerge) => {
  const updatedArray = [...array]
  for (const element of toMerge) {
    const i = findIndex(updatedArray, ['id', element.id])
    if (i === -1) {
      updatedArray.push(element)
    } else {
      updatedArray[i] = element
    }
  }
  return updatedArray
}

function media(state = initialData, action) {
  switch (action.type) {
    case STORE_MEDIA_PAGE: {
      const {
        mediaType,
        numPages,
        numPerPage,
        currentPageNum,
        pageMedia,
        searchText,
        sortProperty,
        sortAscending,
      } = action

      const pageIds = pageMedia.map((page) => page.id)

      const currentMedia = (state[action.mediaType] || {}).media
      const updatedMedia = mergeById(currentMedia, pageMedia)

      return {
        ...state,
        mediaType,
        [action.mediaType]: {
          numPages,
          numPerPage,
          currentPageNum,
          pageIds,
          searchText,
          sortProperty,
          sortAscending,
          media: updatedMedia,
        },
      }
    }
    case STORE_MEDIA_ITEMS: {
      const { mediaItems } = action

      const mediaTypeData = state[action.mediaType] || {}

      const currentMedia = mediaTypeData.media
      const updatedMedia = mergeById(currentMedia, mediaItems)

      return {
        ...state,
        [action.mediaType]: {
          ...mediaTypeData,
          media: updatedMedia,
        },
      }
    }
    default: {
      return state
    }
  }
}

export function mediaTags(state = [], action) {
  switch(action.type) {
    case STORE_MEDIA_TAGS: {
      return [...action.tags]
    }
    default: {
      return state
    }
  }
}

export default media
