const initialState = {
  loading: false,
  html: '',
}

import { UPDATE_CARD_PREVIEW } from './actions'

export function cardPreview(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CARD_PREVIEW: {
      return {
        loading: false,
        html: action.html,
      }
    }
    default:
      return state
  }
}

export default cardPreview
