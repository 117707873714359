import { Component } from 'react'

const { min, max } = Math

export default class ExternalPaginator extends Component {
  goToPrevious = () => {
    const { currentPageNum, loadPage } = this.props
    const newPageNum = max(currentPageNum - 1, 0)
    loadPage(newPageNum)
  }

  goToNext = () => {
    const { currentPageNum, numPages, loadPage } = this.props
    const newPageNum = min(currentPageNum + 1, numPages)
    loadPage(newPageNum)
  }

  goToPage = (newPageNum) => this.props.loadPage(newPageNum)

  render() {
    const { render } = this.props

    return render({
      goToPrevious: this.goToPrevious,
      goToNext: this.goToNext,
      goToPage: this.goToPage,
    })
  }
}
