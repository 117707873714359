export const ADD_SECTION = 'ADD_SECTION'
export const REMOVE_SECTION = 'REMOVE_SECTION'
export const DUPLICATE_SECTION = 'DUPLICATE_SECTION'
export const UPDATE_SECTION_SETTINGS = 'UPDATE_SECTION_SETTINGS'
export const SWAP_COLUMNS = 'SWAP_COLUMNS'
export const UPDATE_BLOCK = 'UPDATE_BLOCK'
export const DELETE_BLOCK = 'DELETE_BLOCK'
export const SET_BLOCK_TYPE = 'SET_BLOCK_TYPE'
export const TOGGLE_SECTION_VISIBILITY = 'TOGGLE_SECTION_VISIBILITY'
export const TOGGLE_SECTION_LOCKED = 'TOGGLE_SECTION_LOCKED'
export const MOVE_SECTION_UP = 'MOVE_SECTION_UP'
export const MOVE_SECTION_DOWN = 'MOVE_SECTION_DOWN'

export const addSection = ({ index, layout }) => ({
  type: ADD_SECTION,
  index,
  layout,
})

export const removeSection = (uuid) => ({
  type: REMOVE_SECTION,
  uuid,
})

export const duplicateSection = (uuid) => ({
  type: DUPLICATE_SECTION,
  uuid,
})

export const updateSectionSettings = (updatedSection) => ({
  type: UPDATE_SECTION_SETTINGS,
  updatedSection,
})

export const swapColumns = (uuid) => ({
  type: SWAP_COLUMNS,
  uuid,
})

export const updateBlock = (updatedBlock) => ({
  type: UPDATE_BLOCK,
  updatedBlock,
})

export const deleteBlock = (uuid) => ({
  type: DELETE_BLOCK,
  uuid,
})

export const setBlockType = ({ uuid, blockType }) => ({
  type: SET_BLOCK_TYPE,
  uuid,
  blockType,
})

export const toggleSectionVisibility = (uuid) => ({
  type: TOGGLE_SECTION_VISIBILITY,
  uuid,
})

export const toggleSectionLocked = (uuid, locked) => ({
  type: TOGGLE_SECTION_LOCKED,
  uuid,
  locked
})

export const moveSectionUp = (uuid) => ({
  type: MOVE_SECTION_UP,
  uuid,
})

export const moveSectionDown = (uuid) => ({
  type: MOVE_SECTION_DOWN,
  uuid,
})
