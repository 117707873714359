import React from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import Panel from '../../Panel'
import Select2 from 'react-select2-wrapper'
import FieldContainer from '../../FieldContainer'
import RunValidationTrigger from './RunValidationTrigger'

const EditEventDetailsForm = ({
  config,
  newBlock,
  onSave,
  onDelete,
  onCancel,
}) => {
  const events = useSelector(state => state.eventDetailsEvents) || []

  return (
    <Panel title="Event Details">
    <Formik
      initialValues={config}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => onSave(values)}
      render={({
        values,
        errors,
        setFieldValue,
        validateForm,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <FieldContainer
            error={errors.eventId}
            fieldClasses="invisible-select page-editor-select2-container"
          >
            <label>Event</label>
            <Select2
              onChange={(event) => setFieldValue('eventId', event.target.value)}
              defaultValue={values.eventId}
              data={[
                { id: '', text: 'Select an event' },
                ...events.map((event) => ({
                  id: event.id,
                  text: `${event.title} - ${event.formatted_date} (${event.status})`,
                })),
              ]}
            />
          </FieldContainer>

          <div className="l-margin-top-sm">
            <button
              type="submit"
              onClick={handleSubmit}
              className="bg-green__btn l-margin-right-sm"
            >
              Save Block
            </button>
            <button
              type="submit"
              onClick={onCancel}
              className="bg-gray__btn l-margin-right-sm"
            >
              Cancel
            </button>
            <button
              type="button"
              title="Delete Block"
              onClick={onDelete}
              className="page-editor-edit-form__delete-icon"
            >
              <i className="far fa-trash-alt" />
            </button>
          </div>
          {newBlock ? null : (
            <RunValidationTrigger validateForm={validateForm} />
          )}
        </form>
      )}
    />
    </Panel>
  )
}

const validate = (values) => {
  const errors = {}
  if (!values.eventId) {
    errors.eventId = 'Choose an event'
  }
  return errors
}

export default EditEventDetailsForm
