import { combineReducers } from 'redux'
import {
  STORE_ALL_MESSAGES,
  STORE_MESSAGE,
  STORE_MESSAGE_SERIES,
} from './actions'

function messages(state = [], action) {
  switch (action.type) {
    case STORE_ALL_MESSAGES: {
      return action.messages
    }
    case STORE_MESSAGE: {
      const { message } = action
      const i = state.findIndex((testMessage) => testMessage.id === message.id)
      if (i === -1) {
        return [message, ...state]
      } else {
        return [...state].slice(i, 1, message)
      }
    }
    default: {
      return state
    }
  }
}

function series(state = [], action) {
  switch (action.type) {
    case STORE_MESSAGE_SERIES:
      return action.series
    default:
      return state
  }
}

export default combineReducers({
  messages,
  series,
})
