import React from 'react'
import { Formik } from 'formik'
import { get } from 'lodash'
import Panel from '../../Panel'
import RunValidationTrigger from './RunValidationTrigger'
import MediaField from '../../MediaChooser/MediaField'
import LocationInput from '../../LocationInput'
import FieldContainer from '../../FieldContainer'
import JSColor from './JSColor'
import { clearExportData, validateLocation } from './utils'
import RichTextEditor from '../../../../../../shared/RichTextEditor'
import { componentOrNothing } from '../../../../../../services/componentOr'

const allowsBrandLogoImage = (gridPosition) => {
  return gridPosition === 'full'
}

const EditCalloutBlock = (props) => {
  const {config, gridPosition, newBlock, onSave, onDelete, onCancel} = props

  return (
    <JSColor jsColorClassName="jscolor-callout">
      <Panel title="Callout">
        <Formik
          initialValues={transformInitialConfig(config)}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values) => onSave(clearExportData(values))}
          render={({
            values,
            errors,
            handleChange,
            setFieldValue,
            validateForm,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <MediaField
                label="Background Image"
                mediaId={values.backgroundImageId}
                error={errors.backgroundImageId}
                exportedFileName={get(values, 'exportData.backgroundImage')}
                onChange={(backgroundImageId) =>
                  setFieldValue('backgroundImageId', backgroundImageId)
                }
              />
              {componentOrNothing(
                allowsBrandLogoImage(gridPosition),
                <MediaField
                  label="Brand Logo Image"
                  mediaId={values.brandLogoImageId}
                  error={errors.brandLogoImageId}
                  exportedFileName={get(values, 'exportData.brandLogoImage')}
                  onChange={(brandLogoImageId) =>
                    setFieldValue('brandLogoImageId', brandLogoImageId)
                  }
                />,
              )}
              <div className="df-row align-flex-start">
                <div className="img-upload-preview">
                  <div
                    id="backgroundColorPreview"
                    className="primary-color-preview"
                    style={{ backgroundColor: values.backgroundColor }}
                  >
                    Aa
                  </div>
                </div>
                <FieldContainer
                  containerClasses="flex-1"
                  error={errors.backgroundColor}
                  testId="background-color"
                >
                  <label htmlFor="backgroundColor">Background Color</label>
                  <div className="edit-callout__background-input">
                    <input
                      type="text"
                      id="backgroundColor"
                      data-testid="backgroundColorField"
                      className="jscolor-callout"
                      data-jscolor="{styleElement: 'backgroundColorPreview'}"
                      // defaultValue instead of value because we're not using onChange
                      defaultValue={values.backgroundColor}
                      // onChange does not get value set by jscolor picker, onBlur does
                      onBlur={() => {
                        if (event.target.value) {
                          setFieldValue('backgroundColor', event.target.value)
                        }
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue('backgroundColor', '000000')
                        document
                          .getElementById('backgroundColor')
                          .jscolor.fromString('000000')
                      }}
                      className="far fa-trash-alt"
                    />
                  </div>
                </FieldContainer>
              </div>
              <FieldContainer error={errors.title}>
                <label htmlFor="title">Title</label>
                <textarea
                  id="title"
                  data-testid="titleField"
                  className="edit-callout__textarea"
                  value={values.title}
                  onChange={handleChange}
                />
              </FieldContainer>
              <div>Subtitle</div>
              <div className="l-margin-bottom-sm page_editor_wysiwyg">
                <RichTextEditor
                  value={values.subtitle || ''}
                  onChange={content => setFieldValue('subtitle', content)}
                />
              </div>
              <div className="grid-x grid-margin-x df-md-wrap form-arrow-centered">
                <div className="cell small-12 large-6">
                  <FieldContainer
                    error={errors.buttonTitle}
                    testId="button-title"
                  >
                    <label htmlFor="buttonTitle">Button Title</label>
                    <input
                      type="text"
                      name="buttonTitle"
                      data-testid="buttonTitleField"
                      value={values.buttonTitle}
                      onChange={handleChange}
                    />
                  </FieldContainer>
                </div>
                <div className="cell small-12 large-6">
                  <LocationInput
                    testidPrefix="buttonLocation"
                    locationValues={values.buttonLocation}
                    errors={errors.buttonLocation}
                    setLocationFieldValue={(field, value) =>
                      setFieldValue(`buttonLocation.${field}`, value)
                    }
                    exportedPage={values.exportData ? values.exportData['buttonLocation.pageId'] : null}
                  />
                  {values.buttonLocation.type != "Jump Link" && (
                    <div
                      className="df-row margin-bottom-1"
                      onClick={() =>
                        setFieldValue('buttonNewWindow', !values.buttonNewWindow)
                      }
                    >
                      <label className="container-check-box">
                        <input
                          type="checkbox"
                          name="buttonNewWindow"
                          checked={values.buttonNewWindow || false}
                          onClick={(event) => event.stopPropagation()}
                          onChange={() => {
                            /* noop */
                          }}
                        />
                        <span className="checkmark-small" />
                        <span>Open in New Tab</span>
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className="grid-x grid-margin-x df-md-wrap form-arrow-centered">
                <div className="cell small-12 large-6">
                  <FieldContainer
                    error={errors.secondaryLinkTitle}
                    testId="secondary-link-title"
                  >
                    <label htmlFor="secondaryLinkTitle">
                      Secondary Link Title
                    </label>
                    <input
                      type="text"
                      name="secondaryLinkTitle"
                      data-testid="secondaryLinkTitleField"
                      value={values.secondaryLinkTitle}
                      onChange={handleChange}
                    />
                  </FieldContainer>
                </div>
                <div className="cell small-12 large-6">
                  <LocationInput
                    testidPrefix="secondaryLinkLocation"
                    locationValues={values.secondaryLinkLocation}
                    errors={errors.secondaryLinkLocation}
                    setLocationFieldValue={(field, value) =>
                      setFieldValue(`secondaryLinkLocation.${field}`, value)
                    }
                    exportedPage={values.exportData ? values.exportData['secondaryLinkLocation.pageId'] : null}
                  />
                  {values.secondaryLinkLocation.type != "Jump Link" && (
                    <div
                      className="df-row margin-bottom-1"
                      onClick={() =>
                        setFieldValue(
                          'secondaryLinkNewWindow',
                          !values.secondaryLinkNewWindow,
                        )
                      }
                    >
                      <label className="container-check-box">
                        <input
                          type="checkbox"
                          name="secondaryLinkNewWindow"
                          checked={values.secondaryLinkNewWindow || false}
                          onClick={(event) => event.stopPropagation()}
                          onChange={() => {
                            /* noop */
                          }}
                        />
                        <span className="checkmark-small" />
                        <span>Open in New Tab</span>
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  data-testid="saveButton"
                  onClick={handleSubmit}
                  className="bg-green__btn l-margin-right-sm"
                >
                  Save Block
                </button>
                <button
                  type="submit"
                  onClick={onCancel}
                  className="bg-gray__btn l-margin-right-sm"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  title="Delete Block"
                  onClick={onDelete}
                  className="page-editor-edit-form__delete-icon"
                >
                  <i className="far fa-trash-alt" />
                </button>
              </div>
              {newBlock ? null : (
                <RunValidationTrigger validateForm={validateForm} />
              )}
            </form>
          )}
        />
      </Panel>
    </JSColor>
  )
}

const transformInitialConfig = (config) => ({
  ...config,
  buttonLocation: config.buttonLocation || {
    type: config.link ? 'URL' : null,
    url: config.link || null,
  },
  secondaryLinkLocation: config.secondaryLinkLocation || {
    type: config.secondaryLink ? 'URL' : null,
    url: config.secondaryLink || null,
  },
})

const validate = (values) => {
  const { buttonLocation, secondaryLinkLocation } = values

  let errors = {}

  if (!values.title) {
    errors.title = 'Title is required'
  }
  if (!values.backgroundColor) {
    errors.backgroundColor = 'Background color is required'
  }

  if (values.buttonTitle || buttonLocation.type) {
    if (!values.buttonTitle) {
      errors.buttonTitle = 'Button title is required'
    }

    let buttonErrors = validateLocation(buttonLocation)
    if(buttonErrors) errors.buttonLocation = buttonErrors
  }

  if (values.secondaryLinkTitle || secondaryLinkLocation.type) {
    if (!values.secondaryLinkTitle) {
      errors.secondaryLinkTitle = 'Secondary link title is required'
    }

    let buttonErrors = validateLocation(secondaryLinkLocation)
    if(buttonErrors) errors.secondaryLinkLocation = buttonErrors
  }

  return Object.keys(errors).length == 0 ? null : errors
}

export default EditCalloutBlock
