import generateUuid from 'uuid/v4'
import { get, has, includes } from 'lodash'
import EditText from '../components/Block/Edit/Text'
import EditAnchoredText from '../components/Block/Edit/AnchoredText'
import EditImage from '../components/Block/Edit/Image'
import EditImageAndText from '../components/Block/Edit/ImageAndText'
import EditFormEmbed from '../components/Block/Edit/FormEmbed'
import EditCustomCode from '../components/Block/Edit/CustomCode'
import EditEventList from '../components/Block/Edit/EventList'
import EditEventDetails from '../components/Block/Edit/EventDetails'
import EditCard from '../components/Block/Edit/Card'
import EditMap from '../components/Block/Edit/Map'
import EditFAQ from '../components/Block/Edit/FAQ'
import EditCallout from '../components/Block/Edit/Callout'
import EditSocialIcon from '../components/Block/Edit/SocialIcon'
import EditSocialCallout from '../components/Block/Edit/SocialCallout'
import EditVideo from '../components/Block/Edit/Video'
import EditFeedSummary from '../components/Block/Edit/FeedSummary'
import EditAudioPlayer from '../components/Block/Edit/AudioPlayer'
import EmptyBlock from '../components/Block/View/Empty'
import UnknownBlock from '../components/Block/View/Unknown'
import IframeBlock from '../components/Block/View/Iframe'
import gridPosition from '../components/Section/gridPosition'

let isAdmin = typeof currentUserIsAdmin !== 'undefined' && !!currentUserIsAdmin

export const BLOCK_TYPE_EMPTY = 'empty'
const IMMOVABLE_BLOCK_TYPES = [
  'anchoredText',
  'eventList',
  'faq',
  'form',
  'text',
]

const blockTypeConfigMap = {
  empty: {
    viewComponent: EmptyBlock,
    editable: false,
    promptForBlockType: true,
    allowedGridPositions: [],
  },
  text: {
    displayName: 'Text',
    iconClass: 'block-icon--text',
    editComponent: EditText,
    defaultConfig: () => ({}),
    allowedGridPositions: ['right'],
  },
  anchoredText: {
    displayName: 'Anchored Text',
    iconClass: 'block-icon--anchored-text',
    editComponent: EditAnchoredText,
    defaultConfig: () => ({
      items: [
        {
          uuid: generateUuid(),
          title: '',
          text: '',
        },
      ],
    }),
    allowedGridPositions: ['full'],
  },
  image: {
    displayName: 'Image',
    iconClass: 'block-icon--image',
    editComponent: EditImage,
    defaultConfig: () => ({
      imageId: null,
      brandLogoId: null,
    }),
    allowedGridPositions: ['full', 'left', 'right', 'grid'],
  },
  imageAndText: {
    displayName: 'Image + Text',
    iconClass: 'block-icon--image-and-text',
    editComponent: EditImageAndText,
    defaultConfig: () => ({
      imageId: null,
      title: '',
      subtitle: '',
    }),
    allowedGridPositions: ['quarter'],
  },
  video: {
    displayName: 'Video',
    iconClass: 'block-icon--video',
    editComponent: EditVideo,
    defaultConfig: () => ({
      title: '',
      videoSource: 'vimeo',
      videoId: '',
      thumbnailImageId: null,
    }),
    allowedGridPositions: ['full', 'left', 'right', 'grid'],
  },
  callout: {
    displayName: 'Callout',
    iconClass: 'block-icon--callout',
    editComponent: EditCallout,
    defaultConfig: () => ({
      backgroundImageId: null,
      brandLogoImageId: null,
      backgroundColor: '000000',
      title: '',
      subtitle: '',
      buttonTitle: '',
      buttonLocation: {
        type: null,
        pageId: null,
        url: null,
      },
      buttonNewWindow: false,
      secondaryLinkTitle: '',
      secondaryLinkLocation: {
        type: null,
        pageId: null,
        url: null,
      },
      secondaryLinkNewWindow: false,
    }),
    allowedGridPositions: ['full', 'left', 'right'],
  },
  card: {
    displayName: 'Card',
    iconClass: 'block-icon--card',
    editComponent: EditCard,
    defaultConfig: () => ({
      cardType: null,
      cardableId: null,
      title: '',
      subtitle: '',
      featuredImageId: null,
      cardUrl: null,
      cardNewWindow: false,
      hideBrandIcon: false,
      brandIconId: null,
      hideCtaButton: false,
      ctaButtonTitle: '',
      ctaLocation: {
        type: null,
        pageId: null,
        url: null,
      },
      ctaNewWindow: false,
      footerType: '',
      textType: '',
    }),
    allowedGridPositions: ['left', 'right', 'grid'],
  },
  form: {
    viewComponent: IframeBlock,
    displayName: 'Form',
    iconClass: 'block-icon--form',
    editComponent: EditFormEmbed,
    defaultConfig: () => ({
      code: '',
    }),
    allowedGridPositions: ['right'],
    hidden: !isAdmin,
  },
  map: {
    displayName: 'Map',
    iconClass: 'block-icon--map',
    editComponent: EditMap,
    defaultConfig: () => ({
      address: '',
      label: '',
      phoneNumber: '',
      zoomLevel: 13,
    }),
    allowedGridPositions: ['full', 'left', 'right'],
  },
  socialCallout: {
    displayName: 'Social Callout',
    iconClass: 'block-icon--social-callout',
    editComponent: EditSocialCallout,
    defaultConfig: () => ({
      backgroundImageId: null,
      backgroundColor: '000000',
      title: '',
      facebookURL: '',
      instagramURL: '',
      spotifyURL: '',
      twitterURL: '',
      youtubeURL: '',
    }),
    allowedGridPositions: ['full', 'left', 'right'],
  },
  socialIcon: {
    displayName: 'Social Icon',
    iconClass: 'block-icon--social-icon',
    editComponent: EditSocialIcon,
    defaultConfig: () => ({
      socialIcon: '',
      link: '',
      backgroundColor: '',
    }),
    allowedGridPositions: ['grid'],
  },
  eventList: {
    displayName: 'Event List',
    iconClass: 'block-icon--event-list',
    editComponent: EditEventList,
    defaultConfig: () => ({
      includeViewAllLink: false,
      noEventsMessage: '',
      numEvents: 5,
      showMessage: false,
      title: '',
    }),
    allowedGridPositions: ['full'],
  },
  eventDetails: {
    displayName: 'Event Details',
    iconClass: 'block-icon--event-details',
    editComponent: EditEventDetails,
    defaultConfig: () => ({
      eventId: null,
    }),
    allowedGridPositions: ['left', 'right'],
  },
  faq: {
    displayName: 'FAQ',
    iconClass: 'block-icon--faq',
    viewComponent: IframeBlock, // layout issue in Editor when not in iframe
    editComponent: EditFAQ,
    defaultConfig: () => ({
      sectionTitle: '',
      items: [
        {
          uuid: generateUuid(),
          title: '',
          text: '',
        },
      ],
    }),
    allowedGridPositions: ['full'],
  },
  audioPlayer: {
    viewComponent: IframeBlock,
    displayName: 'Audio Player',
    iconClass: 'block-icon--audio-player',
    editComponent: EditAudioPlayer,
    defaultConfig: () => ({
      tracks: [
        {
          uuid: generateUuid(),
          sourceType: null,
          url: null,
          mediaId: null,
          name: '',
          minutes: '',
          seconds: '',
        },
      ],
    }),
    allowedGridPositions: ['left', 'right'],
  },
  customCode: {
    displayName: 'Custom Code',
    iconClass: 'block-icon--custom-code',
    editComponent: EditCustomCode,
    defaultConfig: () => ({
      code: '',
    }),
    allowedGridPositions: ['full', 'left', 'right'],
    hidden: !isAdmin,
  },
  feedSummary: {
    displayName: 'Feed Summary',
    iconClass: 'block-icon--feed-summary',
    editComponent: EditFeedSummary,
    defaultConfig: () => ({
      style: 'list',
      maxEntries: 12,
      showViewAll: true,
      hideCategories: false,
    }),
    allowedGridPositions: ['full', 'left', 'right', 'quarter'],
  },
}

const unknownBlockTypeConfig = {
  viewComponent: UnknownBlock,
  editable: false,
}

export const blockTypesForSelection = (position) =>
  Object.keys(blockTypeConfigMap)
    .filter((type) => {
      return !get(blockTypeConfigMap, [type, 'hidden'], false)
    })
    .filter((type) => {
      const allowedGridPositions = get(blockTypeConfigMap, [
        type,
        'allowedGridPositions',
      ])
      return includes(allowedGridPositions, position)
    })

export const isBlockTypeAllowedInGridPosition = ({ type, position }) => {
  return (
    !type ||
    type == 'empty' ||
    includes(blockTypeConfigMap[type].allowedGridPositions, position)
  )
}

export const validBlockType = (type) => has(blockTypeConfigMap, type)

export const blockTypeConfig = (type) =>
  get(blockTypeConfigMap, type, unknownBlockTypeConfig)

export const newEmptyBlockConfig = (height) => ({
  type: BLOCK_TYPE_EMPTY,
  uuid: generateUuid(),
  height: height,
})

export const getBlockHeight = (blockLength, sectionHeight) =>
  blockLength > 2
    ? 'half-medium'
    : blockLength == 2 && sectionHeight == 'medium'
    ? 'doubled-half-medium'
    : sectionHeight

// Blocks created prior to the block-movement feature will always have height blank.
// Block height is used to determine if one block can move into another block space.
export const getBlockHeightFromHtmlIfMissing = (...blocks) => {
  blocks.forEach((block) => {
    if (!block.height && block.uuid) {
      block.height = $(`[data-block-uuid="${block.uuid}"]`).attr('data-block-height')
    }
  })
}

export const validGridBlockLocation = (target, source) => {
  if (isBlockTypeImmovable(source.type)) {
    return {
      success: false,
      message: 'This Block is not movable.' }
  }

  if (isBlockTypeImmovable(target.type)) {
    return {
      success: false,
      message: 'The Block where you are trying to move is not movable.',
    }
  }

  const validSourceMove = isBlockTypeAllowedInGridPosition({
    type: source.type,
    position: target.gridPosition
  })
  const validTargetMove = isBlockTypeAllowedInGridPosition({
    type: target.type,
    position: source.gridPosition
  })
  const validMove = validSourceMove && validTargetMove

  return {
    success: validMove,
    message: validMove ? 'Success' : 'Source or Target is not compatible to move.'
  }
}

export const isBlockTypeImmovable = (blockType) =>
  IMMOVABLE_BLOCK_TYPES.indexOf(blockType) >= 0

export const newBlockConfig = (type) => ({
  type,
  uuid: generateUuid(),
  ...blockTypeConfigMap[type].defaultConfig(),
})

export const swapBlocks = (sourceBlocks, targetBlocks, a, b) => {
  const swapBlock = sourceBlocks[a]
  const swapPosition = swapBlock.gridPosition

  swapBlock.gridPosition = targetBlocks[b].gridPositions
  targetBlocks[b].gridPositions = swapPosition

  sourceBlocks[a] = targetBlocks[b]
  targetBlocks[b] = swapBlock
}
