import React, { useState, useRef } from 'react'
import PageSelectInput from './SelectInput/Page'
import FieldContainer from './FieldContainer'
import ContextNotification from '../../../../shared/ContextNotification'

const LocationInput = ({
  testidPrefix,
  locationValues,
  errors,
  setLocationFieldValue,
  urlPlaceholder,
  exportedPage,
  allowJumpLinks = true
}) => {
  const errorsWithDefault = errors || {}

  return (
    <div>
      <FieldContainer
        error={errorsWithDefault.type}
        fieldClasses="invisible-select"
        testId={`${testidPrefix}TypeField`}
      >
        <label>Link</label>
        <select
          data-testid={`${testidPrefix}TypeField`}
          value={locationValues.type || ''}
          onChange={(event) =>
            setLocationFieldValue('type', event.target.value)
          }
          className="select2-admin"
        >
          <option value="">(select)</option>
          <option value="URL">URL</option>
          <option value="Page">Page</option>
          {allowJumpLinks && <option value="Jump Link">Section on this Page</option>}
        </select>
      </FieldContainer>
      {renderLocationInput({
        testidPrefix,
        locationValues,
        errors: errorsWithDefault,
        setLocationFieldValue,
        urlPlaceholder,
        exportedPage
      })}
    </div>
  )
}

const renderLocationInput = ({
  testidPrefix,
  locationValues,
  errors,
  setLocationFieldValue,
  urlPlaceholder,
  exportedPage
}) => {
  switch (locationValues.type) {
    case 'URL': {
      return renderUrlInput({
        testidPrefix,
        locationValues,
        errors,
        setLocationFieldValue,
        urlPlaceholder,
      })
    }
    case 'Page': {
      return renderPageInput({
        testidPrefix,
        locationValues,
        errors,
        setLocationFieldValue,
        exportedPage
      })
    }
    case 'Jump Link': {
      return renderJumpLinkInput({
        locationValues,
        errors,
        setLocationFieldValue,
      })
    }
    default: {
      return null
    }
  }
}

const renderUrlInput = ({
  testidPrefix,
  locationValues,
  errors,
  setLocationFieldValue,
  urlPlaceholder,
}) => {
  const placeholderError =
    locationValues.url === '/' || locationValues.url === '#'
  const errorToShow = errors.url || placeholderError
  return (
    <FieldContainer
      error={errorToShow}
      fieldClasses="js-location-url"
      testId={`${testidPrefix}UrlField`}
    >
      <label htmlFor="locationUrl">URL</label>
      <input
        type="text"
        id="locationUrl"
        data-testid={`${testidPrefix}UrlField`}
        value={locationValues.url || ''}
        onChange={(event) =>
          setLocationFieldValue('url', event.target.value.trim())
        }
        placeholder={urlPlaceholder || 'https://www.example.com'}
      />
    </FieldContainer>
  )
}

const renderPageInput = ({
  testidPrefix,
  locationValues,
  errors,
  setLocationFieldValue,
  exportedPage
}) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const inputRef = useRef()

  const handlePaste = (pasteEvent) => {
    const sanitizedJumpLink = sanitizeJumpLink(pasteEvent.clipboardData.getData("text"))

    if(sanitizedJumpLink) {
      pasteEvent.preventDefault()
      setLocationFieldValue('pageSectionId', sanitizedJumpLink)
    }else{
      pasteEvent.preventDefault()
      setErrorMessage("Invalid jump link pasted")
    }
  }

  return (
    <React.Fragment>
      <PageSelectInput
        fieldName="Page"
        data-testid={`${testidPrefix}PageField`}
        value={locationValues.pageId || ''}
        error={errors.pageId}
        onChange={(event) => setLocationFieldValue('pageId', event.target.value)}
        exportedName={exportedPage ? exportedPage.name : null}
      />

      <FieldContainer error={errors.pageSectionId}>
        <label>Jump Link</label>
        <input
          type="text"
          value={locationValues.pageSectionId || ''}
          onChange={(event) => setLocationFieldValue('pageSectionId', event.target.value.trim())}
          onPaste={handlePaste}
          ref={inputRef}
        />
        {errorMessage && (
          <ContextNotification
            target={inputRef.current}
            message={errorMessage}
            onComplete={() => setErrorMessage(null)}
            duration={2000}
          />
        )}
      </FieldContainer>

      {locationValues.pageId && (
        <a href={`/admin/pages/${locationValues.pageId}/edit`} target="_blank" className="text-tiny" style={{display: "block", marginTop: "-1rem", marginBottom: "1rem"}}>Choose a section</a>
      )}
    </React.Fragment>
  )
}

const renderJumpLinkInput = ({
  locationValues,
  errors,
  setLocationFieldValue,
}) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const inputRef = useRef()

  const handlePaste = (pasteEvent) => {
    const sanitizedJumpLink = sanitizeJumpLink(pasteEvent.clipboardData.getData("text"))

    if(sanitizedJumpLink) {
      pasteEvent.preventDefault()
      setLocationFieldValue('jumpLink', sanitizedJumpLink)
    }else{
      pasteEvent.preventDefault()
      setErrorMessage("Invalid jump link pasted")
    }
  }

  return (
    <FieldContainer
      error={errors.jumpLink}
      fieldClasses="js-location-jump-link"
    >
      <label>Jump Link</label>
      <input
        type="text"
        value={locationValues.jumpLink || ''}
        onChange={(e) => setLocationFieldValue('jumpLink', e.target.value.trim())}
        onPaste={handlePaste}
        ref={inputRef}
      />
      {errorMessage && (
        <ContextNotification
          target={inputRef.current}
          message={errorMessage}
          onComplete={() => setErrorMessage(null)}
          duration={2000}
        />
      )}
    </FieldContainer>
  )
}

const sanitizeJumpLink = (unsanitizedJumpLink) => {
  const matches = unsanitizedJumpLink.match(/(#[bs]-.+$)/)
  return matches ? matches[0].trim() : ""
}

export default LocationInput
