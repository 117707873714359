import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import Modal from 'react-modal'
import MediaChooser from './index'
import { loadMediaItem } from '../../../../store/media/actions'
import FieldContainer from '../FieldContainer'

class MediaField extends Component {
  state = { isChoosingMedia: false }

  componentDidMount() {
    const { mediaId } = this.props

    if (mediaId) {
      this.props.loadMediaItem({ mediaType: 'image', id: mediaId })
    }
  }

  openMediaChooser = () => this.setState({ isChoosingMedia: true })
  closeMediaChooser = () => this.setState({ isChoosingMedia: false })

  handleChoose = ({ mediaId }) => {
    const { onChange } = this.props

    onChange(mediaId)

    this.setState({ isChoosingMedia: false })
  }

  handleDelete = () => this.props.onChange(null)

  render() {
    const {
      mediaId,
      exportedFileName,
      label,
      required,
      error,
      defaultFilename,
      defaultImageUrl,
    } = this.props
    const { isChoosingMedia } = this.state

    const mediaItem =
      this.props.media && this.props.media.find((m) => m.id === Number(mediaId))
    const { id, url, filename, status } = mediaItem || {}

    const backgroundImageUrl = (url || defaultImageUrl || '')
      .replace('(', '\\(')
      .replace(')', '\\)')
    const imageStyle = {}
    if (backgroundImageUrl) {
      imageStyle.backgroundImage = `url(${backgroundImageUrl})`
    }

    const filenameToShow =
      (!!mediaId && mediaId != "" && !mediaItem && `deleted media item (#${mediaId})`) ||
      (filename &&
        `${filename}${status !== 'published' ? ` (${status})` : ''}`) ||
      (defaultFilename && `(default: ${defaultFilename})`) ||
      (exportedFileName && `(not set; previously ${exportedFileName})`) ||
      '(not set)'

    let errorToShow = error

    if (!errorToShow) {
      if(mediaItem !== undefined && mediaItem.status !== 'published') {
        errorToShow = `Media is ${mediaItem.status}`
      }else if(!!mediaId && mediaId != "" && mediaItem === undefined) {
        errorToShow = "Media item no longer exists"
      }
    }

    return (
      <div className="df-row align-flex-start">
        <div className="img-upload-preview">
          <div className="image-preview" style={imageStyle} />
        </div>
        <FieldContainer containerClasses="flex-1" error={errorToShow}>
          <label className={required ? 'required' : ''}>{label}</label>
          <div className="media-selector-control__input-layout">
            <Modal
              style={{
                content: {
                  background: 'transparent',
                  border: 'none',
                },
              }}
              isOpen={isChoosingMedia}
            >
              <MediaChooser
                currentMediaId={id}
                onChoose={this.handleChoose}
                onCancel={this.closeMediaChooser}
              />
            </Modal>
            <button
              type="button"
              onClick={this.openMediaChooser}
              className="media-selector-control__filename"
            >
              {filenameToShow}
            </button>
            {mediaId ? (
              <button
                type="button"
                onClick={this.handleDelete}
                className="media-selector-control__delete-button"
              >
                <i className="far fa-trash-alt" />
              </button>
            ) : null}
          </div>
        </FieldContainer>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({ media: get(state, 'media.image.media') })

const mapDispatchToProps = { loadMediaItem }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaField)
