export const ADD_FOOTER = 'ADD_FOOTER'
export const TOGGLE_FOOTER_VISIBILITY = 'TOGGLE_FOOTER_VISIBILITY'
export const UPDATE_FOOTER = 'UPDATE_FOOTER'
export const REMOVE_FOOTER = 'REMOVE_FOOTER'

export const addFooter = () => ({
  type: ADD_FOOTER,
})

export const updateFooter = (footer) => ({
  type: UPDATE_FOOTER,
  footer,
})

export const toggleFooterVisibility = (footer) => ({
  type: TOGGLE_FOOTER_VISIBILITY,
  footer,
})

export const removeFooter = () => ({
  type: REMOVE_FOOTER,
})
