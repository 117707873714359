import { Controller } from "stimulus"
import flatpickr from 'flatpickr'

export default class extends Controller {
  connect() {
    this.flatpickr = flatpickr(this.element, this.flatpickrOptions)
  }

  updateFlatpickr() {
    if(this.flatpickr) this.flatpickr.destroy()
    this.flatpickr = flatpickr(this.element, this.flatpickrOptions)
  }

  get flatpickrOptions() {
    return {
      altInput: true,
      altFormat: this.timeFormat,
      enableTime: this.enableTime,
      defaultDate: this.element.value
    }
  }

  get timeFormat() {
    return this.enableTime ? "m/d/Y h:i K" : "m/d/Y"
  }

  get enableTime() {
    return this.data.get("enableTime") == "true"
  }

  set enableTime(enabled) {
    this.data.set("enableTime", enabled ? "true" : "false")
    this.updateFlatpickr()
  }
}