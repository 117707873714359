export const SAVING = 'SAVING'
export const REQUEST_PAGE = 'REQUEST_PAGE'
export const STORE_PAGE = 'STORE_PAGE'
export const STORE_PUBLISHED_REVISION = 'STORE_PUBLISHED_REVISION'
export const SET_ERROR = 'SET_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const SET_PAGE_VALIDATION_ERRORS = 'SET_PAGE_VALIDATION_ERRORS'
export const CLEAR_PAGE_VALIDATION_ERRORS = 'CLEAR_PAGE_VALIDATION_ERRORS'
export const DISCARD_CHANGES = 'DISCARD_CHANGES'
export const OPEN_SCHEDULE_VERSION_MODAL = 'OPEN_SCHEDULE_VERSION_MODAL'
export const CLOSE_SCHEDULE_VERSION_MODAL = 'CLOSE_SCHEDULE_VERSION_MODAL'
export const OPEN_SCHEDULE_ARCHIVE_MODAL = 'OPEN_SCHEDULE_ARCHIVE_MODAL'
export const CLOSE_SCHEDULE_ARCHIVE_MODAL = 'CLOSE_SCHEDULE_ARCHIVE_MODAL'
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS'
export const SET_ALERT = 'SET_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'
export const STORE_REVISIONS = 'STORE_REVISIONS'
export const STORE_VERSIONS = 'STORE_VERSIONS'
export const STORE_VERSION = 'STORE_VERSION'
export const UPDATE_VERSION = 'UPDATE_VERSION'
export const REMOVE_VERSION = 'REMOVE_VERSION'

import { submitTo } from '../../utils'
import { loadEventDetailsEvents } from '../utils'
import { setEditing } from './editing/actions'
import { loadMedia, loadMediaTags } from './media/actions'
import { loadPages } from './pages/actions'
import { loadEnvironments } from './environments/actions'
import { updateCardPreview } from './editing/cardPreview/actions'
import { get } from 'lodash'

function withConfirmation(message) {
  return function(dispatch) {
    return new Promise((resolve, reject) => {
      if (confirm(message)) {
        resolve()
      } else {
        reject()
      }
    })
  }
}

export function openScheduleVersionModal() {
  return {
    type: OPEN_SCHEDULE_VERSION_MODAL,
  }
}

export function closeScheduleVersionModal() {
  return {
    type: CLOSE_SCHEDULE_VERSION_MODAL,
  }
}

export function openScheduleArchiveModal() {
  return {
    type: OPEN_SCHEDULE_ARCHIVE_MODAL,
  }
}

export function closeScheduleArchiveModal() {
  return {
    type: CLOSE_SCHEDULE_ARCHIVE_MODAL,
  }
}

export function setError(error) {
  const cleared = !!(error && error.validation)
  return {
    type: SET_ERROR,
    error,
    cleared,
  }
}

export function setPageValidationErrors(error) {
  return {
    type: SET_PAGE_VALIDATION_ERRORS,
    error,
  }
}

export function clearPageValidationErrors() {
  return {
    type: CLEAR_PAGE_VALIDATION_ERRORS,
  }
}

export function clearError() {
  return {
    type: CLEAR_ERROR,
  }
}

function discardChanges() {
  return {
    type: DISCARD_CHANGES,
  }
}

function saving(status = true) {
  return {
    type: SAVING,
    status,
  }
}

function requestPage(id) {
  return {
    type: REQUEST_PAGE,
    id: id,
  }
}

function storePage(page) {
  return {
    type: STORE_PAGE,
    page,
  }
}

export function confirmChangesCanBeDiscarded(customMessage = null) {
  return function(dispatch, getState) {
    const state = getState()
    const hasChanges = state.editing && state.editing.changed
    const message =
      customMessage ||
      'You have unsaved changes. Are you sure you want to discard them?'

    return new Promise((resolve, reject) => {
      if (hasChanges) {
        if (confirm(message)) {
          dispatch(discardChanges())
          dispatch(setAlert('Changes discarded.', 'notice'))
          resolve()
        } else {
          reject()
        }
      } else {
        resolve()
      }
    })
  }
}

export function fetchPage(id) {
  return function(dispatch) {
    dispatch(requestPage(id))

    return fetch(`/api/v1/pages/${id}`)
      .then((response) => response.json())
      .then((json) => {
        if (json.error) {
          dispatch(setAlert('Unable to load page.'))
          dispatch(setError(json))
        } else {
          dispatch(loadMedia({ mediaType: 'image', numPerPage: 20 }))
          dispatch(loadMediaTags())
          dispatch(loadPages())
          dispatch(loadEnvironments())
          dispatch(storePage(json))
          dispatch(loadPageRevisions(json))
          dispatch(loadPageVersions(json))
          dispatch(updateCardPreview())
          loadEventDetailsEvents(id).then(events => dispatch({type: "SET_EVENT_DETAILS_EVENTS", events}))
        }
      })
  }
}

export function schedulePageToArchive(page, archiveTime) {
  return function(dispatch) {
    return dispatch(updatePage(page, { will_archive_at: archiveTime })).then(
      (json) => {
        if (json.error) {
          dispatch(setAlert('The page was not scheduled to be archived.'))
        } else {
          dispatch(setAlert('The page was scheduled to be archived.', 'notice'))
          dispatch(closeScheduleArchiveModal())
        }
      },
    )
  }
}

export function updatePage(page, updates) {
  return function(dispatch) {
    dispatch(saving())
    dispatch(clearPageValidationErrors())

    return fetch(`${page.api_path}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updates),
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(saving(false))

        if (json.error) {
          dispatch(setAlert(json.description))
          dispatch(setError(json))
        } else {
          dispatch(setAlert('Page saved.', 'notice'))
          dispatch(storePage(json))
        }

        return json
      })
  }
}

export function movePageToDrafts(page) {
  return function(dispatch) {
    return dispatch(confirmChangesCanBeDiscarded()).then(() => {
      dispatch(saving())

      return fetch(`${page.api_path}/move_to_drafts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          dispatch(saving(false))

          if (json.error) {
            dispatch(setAlert('The page was not moved to drafts.'))
            dispatch(setError(json))
          } else {
            const { page, version } = json
            dispatch(setAlert('Page moved to drafts.', 'notice'))
            dispatch(storePage(page))
            if(version) dispatch(storeVersion(version))
          }
        })
    })
  }
}

export function publishPage(page) {
  return function(dispatch) {
    return dispatch(confirmChangesCanBeDiscarded()).then(() => {
      dispatch(saving())

      return fetch(`${page.api_path}/publish`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          dispatch(saving(false))

          if (json.error) {
            dispatch(setAlert('The page was not published.'))
            dispatch(setError(json))
          } else {
            dispatch(setAlert('Page published.', 'notice'))
            dispatch(storePage(json))
          }
        })
    })
  }
}

export function duplicatePage(page) {
  return function(dispatch) {
    return dispatch(confirmChangesCanBeDiscarded()).then(() => {
      dispatch(saving())

      return fetch(`${page.api_path}/duplicate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          dispatch(saving(false))

          if (json.error) {
            dispatch(setAlert('The page was not duplicated.'))
            dispatch(setError(json))
          } else {
            dispatch(setAlert('Page duplicated.', 'notice'))
            window.location.href = json.path + '/edit'
          }
        })
    })
  }
}

export function archivePage(page) {
  return function(dispatch) {
    return dispatch(confirmChangesCanBeDiscarded()).then(() => {
      dispatch(saving())

      return fetch(`${page.api_path}/archive`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          dispatch(saving(false))

          if (json.error) {
            dispatch(setAlert('The page was not archived.'))
            dispatch(setError(json))
          } else {
            dispatch(storePage(json))
            dispatch(closeScheduleArchiveModal())
            dispatch(setAlert('Page archived.', 'notice'))
          }
        })
    })
  }
}

export function deletePage(page, force = false) {
  return function(dispatch) {
    dispatch(saving())

    return fetch(`${page.api_path}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ force_delete: force }),
    })
      .then((response) => {
        if (response.status == 204) {
          return null
        } else {
          return response.json()
        }
      })
      .then((json) => {
        dispatch(saving(false))

        if (json && json.error) {
          dispatch(setAlert('The page was not deleted.'))
          dispatch(setError(json))
        } else {
          dispatch(setAlert('Page deleted.', 'notice'))
          window.location.href = '/admin/pages'
        }
      })
  }
}

export function saveCurrentVersion() {
  return function(dispatch, getState) {
    const state = getState()
    const { original: version, changes } = state.editing

    return dispatch(saveVersion(version, changes))
  }
}

export function saveVersionSettings(version, changes) {
  return function(dispatch) {
    return dispatch(confirmChangesCanBeDiscarded()).then(() => {
      return dispatch(saveVersion(version, changes))
    })
  }
}

export function scheduleVersionToPublish(version, changes) {
  return function(dispatch) {
    return dispatch(confirmChangesCanBeDiscarded()).then(() => {
      return dispatch(saveVersion(version, changes))
    })
  }
}

function saveVersion(version, changes) {
  return function(dispatch) {
    dispatch(saving())

    return fetch(`${version.path}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(changes),
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(saving(false))

        if (json.error) {
          dispatch(setError(json))
          if (!get(json, 'validation.will_publish_at')) {
            dispatch(closeScheduleVersionModal())
          } else {
            dispatch(setAlert('The version was not saved.'))
          }
        } else {
          dispatch(storeVersion(json))
          dispatch(closeScheduleVersionModal())
          dispatch(setAlert('Version saved.', 'notice'))
        }
      })
  }
}

export function publishVersion(version) {
  return function(dispatch) {
    return dispatch(confirmChangesCanBeDiscarded()).then(() => {
      dispatch(saving())

      return fetch(`${version.path}/publish`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          dispatch(saving(false))

          if (json.error) {
            dispatch(setAlert('The version was not published.'))
            dispatch(setError(json))
          } else {
            dispatch(storePublishedRevision(json))
            dispatch(removeVersion(version))
            dispatch(closeScheduleVersionModal())
            dispatch(setAlert('Version published.', 'notice'))
          }
        })
    })
  }
}

export function deleteVersion(version) {
  return function(dispatch, getState) {
    const state = getState()
    const editing = state.editing
    const latest_revision = state.page.latest_revision
    const other_version = state.versions.find(other => other.id != version.id)

    return dispatch(
      withConfirmation(
        'Are you sure you want to delete this version? This cannot be undone.',
      ),
    ).then(() => {
      dispatch(saving())

      return fetch(`${version.path}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          dispatch(saving(false))

          if (json.error) {
            dispatch(setAlert('The version was not deleted.'))
            dispatch(setError(json))
          } else {
            dispatch(removeVersion(version))
            dispatch(setAlert('Version deleted.', 'notice'))

            if(editing && editing.type == "version" && editing.id == version.id) {
              dispatch(setEditing(other_version || latest_revision))
            }
          }
        })
    })
  }
}

export function saveAsVersion(revision, askToDiscardChanges = false) {
  return function(dispatch) {
    const discardPromise = askToDiscardChanges
      ? dispatch(confirmChangesCanBeDiscarded())
      : Promise.resolve()

    return discardPromise.then(() => {
      dispatch(saving())

      return fetch(`${revision.path}/save_as_version`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(revision),
      })
        .then((response) => response.json())
        .then((json) => {
          dispatch(saving(false))

          if (json.error) {
            dispatch(setAlert('The revision was not saved as a new version.'))
            dispatch(setError(json))
          } else {
            dispatch(storeVersion(json))
            dispatch(setAlert('Saved as new version.', 'notice'))
          }
        })
    })
  }
}

export function duplicateVersion(version) {
  return function(dispatch) {
    return dispatch(confirmChangesCanBeDiscarded()).then(() => {
      dispatch(saving())

      return fetch(`${version.path}/duplicate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          dispatch(saving(false))

          if (json.error) {
            dispatch(setAlert('The version was not duplicated.'))
            dispatch(setError(json))
          } else {
            dispatch(storeVersion(json))
            dispatch(setAlert(`${version.name} was duplicated.`, 'notice'))
          }
        })
    })
  }
}

export function saveAndPublishRevision() {
  return function(dispatch, getState) {
    dispatch(saving())

    const state = getState()
    const { page } = state
    const { changes } = state.editing

    return fetch(`${page.api_path}/revisions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(changes),
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(saving(false))

        if (json.error) {
          dispatch(setAlert('The changes were not saved or published.'))
          dispatch(setError(json))
        } else {
          dispatch(storePublishedRevision(json))
          dispatch(setAlert('Saved and published.', 'notice'))
        }
      })
  }
}

function storePublishedRevision(revision) {
  return {
    type: STORE_PUBLISHED_REVISION,
    revision,
  }
}

export function previewVersion(version) {
  return function(dispatch, getState) {
    const state = getState()
    const { page, editing } = state
    const { changes } = editing
    const url = `${page.path}/preview?version_id=${version.id}`

    submitTo(url, {
      changes: JSON.stringify(changes),
    })
  }
}

export function previewRevision(revision) {
  return function(dispatch, getState) {
    const state = getState()
    const { page, editing } = state
    const { changes } = editing
    const url = `${page.path}/preview?revision_id=${revision.id}`

    submitTo(url, {
      changes: JSON.stringify(changes),
    })
  }
}

export function setPermissions(permissions) {
  return {
    type: UPDATE_PERMISSIONS,
    permissions,
  }
}

export function setAlert(message, level = 'alert') {
  return {
    type: SET_ALERT,
    message,
    level,
  }
}

export function clearAlert() {
  return {
    type: CLEAR_ALERT,
  }
}

export function loadPageRevisions(page) {
  return function(dispatch, getState) {
    return fetch(`${page.api_path}/revisions`)
      .then((response) => response.json())
      .then((json) => {
        if (json.error) {
          dispatch(setAlert('Error while loading revisions'))
        } else {
          dispatch(storeRevisions(json))
        }
      })
  }
}

export function storeRevisions(revisions) {
  return {
    type: STORE_REVISIONS,
    revisions
  }
}

export function loadPageVersions(page) {
  return function(dispatch, getState) {
    return fetch(`${page.api_path}/versions`)
      .then((response) => response.json())
      .then((json) => {
        if (json.error) {
          dispatch(setAlert('Error while loading versions'))
        } else {
          dispatch(storeVersions(json))
        }
      })
  }
}

export function storeVersions(versions) {
  return {
    type: STORE_VERSIONS,
    versions
  }
}

export function storeVersion(version) {
  return {
    type: STORE_VERSION,
    version,
  }
}

export function removeVersion(version) {
  return {
    type: REMOVE_VERSION,
    version
  }
}