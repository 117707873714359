import { merge } from 'lodash'

import {
  REQUEST_PAGE,
  STORE_PAGE,
  STORE_PUBLISHED_REVISION,
  STORE_VERSIONS,
  STORE_VERSION,
  DISCARD_CHANGES,
} from '../actions'

import { SET_EDITING, UPDATE_COMPONENT, UPDATE_VERSION } from './actions'

import {
  ADD_SECTION,
  REMOVE_SECTION,
  DUPLICATE_SECTION,
  UPDATE_SECTION_SETTINGS,
  SWAP_COLUMNS,
  UPDATE_BLOCK,
  DELETE_BLOCK,
  SET_BLOCK_TYPE,
  TOGGLE_SECTION_VISIBILITY,
  TOGGLE_SECTION_LOCKED,
  MOVE_SECTION_UP,
  MOVE_SECTION_DOWN,
} from './sections/actions'

import { UPDATE_HEADER } from './header/actions'

import {
  ADD_FOOTER,
  TOGGLE_FOOTER_VISIBILITY,
  UPDATE_FOOTER,
  REMOVE_FOOTER,
} from './footer/actions'

import { UPDATE_CARD_PREVIEW } from './cardPreview/actions'

import header from './header/reducers'
import footer from './footer/reducers'
import sections from './sections/reducers'
import cardPreview from './cardPreview/reducers'

const cleanState = {
  type: null,
  id: null,
  changed: false,
  original: {},
  changes: {},
  cardPreview: {},
}

function stateFrom(versionOrRevision, currentState) {
  if (versionOrRevision) {
    let editing = { ...versionOrRevision }
    editing.page_component.content = {
      sections: [],
      header: {},
      footer: {},
      ...editing.page_component.content,
    }

    const original = { ...editing }
    const changes = { ...editing }

    return {
      ...cleanState,
      original,
      changes,
      type: editing.type,
      id: editing.id,
      changed: false,
      cardPreview: currentState && currentState.cardPreview,
    }
  } else {
    return null
  }
}

export default function(state = null, action) {
  switch (action.type) {
    case STORE_PAGE:
      if (action.page.is_archived) {
        return null
      } else {
        return action.page.live_revision
          ? stateFrom(action.page.live_revision, state)
          : state
      }
    case STORE_VERSIONS:
      if(state) {
        return state
      }else if(action.versions.length) {
        return stateFrom(action.versions[0], state)
      }
    case STORE_VERSION:
      return state ? stateFrom(action.version, state) : state
    case STORE_PUBLISHED_REVISION:
      return stateFrom(action.revision, state)
    case SET_EDITING: {
      const { editing, fallback } = action

      return stateFrom(editing || fallback, state)
    }
    case DISCARD_CHANGES: {
      return {
        ...state,
        changes: { ...state.original },
        changed: false,
      }
    }
    case UPDATE_COMPONENT:
      const { component, field, value } = action
      const updatedComponent = {
        ...(state.changes[component] || {}),
        [field]: value,
      }
      const changesWithComponent = {
        ...state.changes,
        [component]: updatedComponent,
      }

      return {
        ...state,
        changes: changesWithComponent,
        changed: true,
      }
    case UPDATE_VERSION: {
      const { field, value } = action

      return {
        ...state,
        changed: true,
        changes: {
          ...state.changes,
          [field]: value,
        },
      }
    }
    case ADD_SECTION:
    case REMOVE_SECTION:
    case DUPLICATE_SECTION:
    case UPDATE_SECTION_SETTINGS:
    case SWAP_COLUMNS:
    case UPDATE_BLOCK:
    case DELETE_BLOCK:
    case SET_BLOCK_TYPE:
    case TOGGLE_SECTION_VISIBILITY:
    case TOGGLE_SECTION_LOCKED:
    case MOVE_SECTION_UP:
    case MOVE_SECTION_DOWN: {
      let content = {
        sections: [],
        ...(state.changes.page_component.content || {}),
      }

      return {
        ...state,
        changed: true,
        changes: {
          ...state.changes,
          page_component: {
            ...state.changes.page_component,
            content: {
              ...content,
              sections: sections(content.sections, action),
            },
          },
        },
      }
    }
    case ADD_FOOTER:
    case TOGGLE_FOOTER_VISIBILITY:
    case UPDATE_FOOTER:
    case REMOVE_FOOTER: {
      let content = {
        footer: {},
        ...(state.changes.page_component.content || {}),
      }

      return {
        ...state,
        changed: true,
        changes: {
          ...state.changes,
          page_component: {
            ...state.changes.page_component,
            content: {
              ...content,
              footer: footer(content.footer, action),
            },
          },
        },
      }
    }
    case UPDATE_HEADER: {
      let content = {
        header: {},
        ...(state.changes.page_component.content || {}),
      }

      return {
        ...state,
        changed: true,
        changes: {
          ...state.changes,
          page_component: {
            ...state.changes.page_component,
            content: {
              ...content,
              header: header(content.header, action),
            },
          },
        },
      }
    }
    case UPDATE_CARD_PREVIEW: {
      return {
        ...state,
        cardPreview: cardPreview(state.cardPreview, action),
      }
    }
    default:
      return state
  }
}
