import Swal from 'sweetalert2'
import Rails from '@rails/ujs'

const handleSwal = function(event) {
  if (shouldShowSwalPopup(this)) {
    Rails.stopEverything(event)
    showConfirmationDialog(this)
  }
}

const shouldShowSwalPopup = (element) => {
  return !!element.dataset.confirmSwal
}

const showConfirmationDialog = (element) => {
  var message = element.getAttribute('data-confirm-swal')
  var text = element.getAttribute('data-text')

  Swal.fire({
    title: message || 'Are you sure?',
    text: text || '',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  }).then(result => {
    confirmed(element, result)
  })
}

const confirmed = (element, result) => {
  if (result.value) {
    element.removeAttribute('data-confirm-swal')
    element.click()
  }
}

export default handleSwal