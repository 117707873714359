import api from '../../api'

export const UPDATE_CARD_PREVIEW = 'UPDATE_CARD_PREVIEW'

export function updateCardPreview() {
  return function(dispatch, getState) {
    const state = getState()
    const { page, editing } = state

    if (!editing) return

    const { changes } = editing
    const url =
      editing.type == 'version'
        ? `${page.path}/card_preview?version_id=${changes.id}`
        : `${page.path}/card_preview?revision_id=${changes.id}`

    api
      .post(url, {
        changes: JSON.stringify(changes),
      })
      .then((response) => {
        dispatch({
          type: UPDATE_CARD_PREVIEW,
          html: response.data,
        })
      })
  }
}
