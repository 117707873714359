import "core-js"
import Rails from '@rails/ujs'
import * as Stimulus from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import handleSwal from '../javascript/services/swal'
import clamp from '../javascript/services/clamp'
import { delegateListener } from '../javascript/utils'

import '../stylesheets/foundation_and_overrides'
import '../stylesheets/frontend/application.scss'
import 'select2/dist/css/select2.min.css'

import '../javascript/customElements/trix-video-attachment.js'


  import '/home/app/app/webpacker/images/down_arrow.png';

  import '/home/app/app/webpacker/images/internal_server_error.png';

  import '/home/app/app/webpacker/images/not_found.png';

  import '/home/app/app/webpacker/images/npcc_logo_light.png';

  import '/home/app/app/webpacker/images/npcc_logo_long.png';

  import '/home/app/app/webpacker/images/placeholder.png';

  import '/home/app/app/webpacker/images/placeholder_16by9.png';

  import '/home/app/app/webpacker/images/plus.png';

  import '/home/app/app/webpacker/images/form-arrow-right.svg';

  import '/home/app/app/webpacker/images/green_dropdown_arrow.svg';

  import '/home/app/app/webpacker/images/npcc_logo_dark.svg';

  import '/home/app/app/webpacker/images/text_overlay_test.jpg';

  import '/home/app/app/webpacker/images/internal_server_error.jpeg';

  import '/home/app/app/webpacker/images/not_found.jpeg';


// Hook the event before the other rails events so it works togeter
// with `method: :delete`.
// See: https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/start.coffee#L69
//
// We have to manually call `Rails.fire(document, 'rails:attachBindings')` to trigger
// the Rails event. According to one of the developers, it's meant for testing so
// this is a hack. It could definitely break when upgrading `@rails/ujs` versions.
// See: https://github.com/rails/rails/issues/28992
document.addEventListener('rails:attachBindings', () => {
  Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleSwal)
  Rails.delegate(document, 'input[data-confirm-swal]', 'click', handleSwal)
})

Rails.fire(document, 'rails:attachBindings')
Rails.start()

const stimulus = Stimulus.Application.start()
const context = require.context("../javascript/stimulus/controllers", true, /\.js$/)
stimulus.load(definitionsFromContext(context))

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll(".clamp")).forEach(element => {
    const clampSize = element.dataset.clamp
    clamp(element, { clamp: clampSize, useNativeClamp: true })
  })
})

delegateListener(document, "change", ".embedded-form", (e) => {
  const target = e.target
  const fieldName = target.name
  const formId = target.form.id

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    "event": "symplexi.form.changed",
    "formId": (formId || "").replace(/[^0-9]/gi, ""),
    "fieldName": (fieldName || "").replace(/field\d+-/, "")
  })
})

delegateListener(document, "submit", ".embedded-form", (e) => {
  const target = e.target
  const id = target.id
  
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    "event": "symplexi.form.submitted",
    "formId": (id || "").replace(/[^0-9]/gi, ""),
  })
})
