import { DirectUpload } from 'activestorage'
import axios from 'axios'
import { get } from 'lodash'

const MAX_WIDTH = 2880

const ALLOWED_FILE_TYPES = [
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'audio/mp3',
  'audio/mpeg',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
]

const SCALEABLE_FILE_TYPES = ['image/jpeg', 'image/png']

export class MediaUploader {
  constructor(file) {
    this.file = file
    this.directUploadUrl = '/rails/active_storage/direct_uploads'
    this.mediaUploadUrl = '/api/v1/media'
  }

  call() {
    return new Promise((resolve, reject) => {
      this.validateFileTypes(this.file)
        .then(this.scaleFileIfScalable.bind(this))
        .then(this.sanitizeFileName.bind(this))
        .then(this.upload.bind(this))
        .then(resolve)
        .catch(reject)
    })
  }

  validateFileTypes(file) {
    return new Promise((resolve, reject) => {
      if (ALLOWED_FILE_TYPES.includes(file.type)) {
        resolve(file)
      } else {
        reject('File is not a valid file format')
      }
    })
  }

  scaleFileIfScalable(file) {
    return new Promise((resolve, reject) => {
      if (!SCALEABLE_FILE_TYPES.includes(file.type)) {
        resolve(file)
        return
      }

      const reader = new FileReader()

      reader.onload = (e) => {
        const image = new Image()

        image.onload = () => {
          if (image.width < MAX_WIDTH) {
            resolve(file)
            return
          }

          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          canvas.width = MAX_WIDTH
          canvas.height = image.height * (MAX_WIDTH / image.width)
          context.drawImage(image, 0, 0, canvas.width, canvas.height)
          canvas.toBlob(
            (blob) => {
              const scaledFile = new File(
                [blob],
                file.name.replace(/png$/, 'jpg'),
                {
                  type: 'image/jpeg',
                  lastModified: Date.now(),
                },
              )

              resolve(scaledFile)
            },
            'image/jpeg',
            1,
          )
        }

        image.onerror = (error) => {
          reject(error)
        }

        image.src = e.target.result
      }

      reader.onerror = (error) => {
        reject(error)
      }

      reader.readAsDataURL(file)
    })
  }

  sanitizeFileName(file) {
    return new Promise((resolve) => {
      const sanitizedFileName = file.name.replace('(', '-').replace(')', '-')
      const blob = file.slice(0, file.size, file.type)
      const renamedFile = new File([blob], sanitizedFileName, {
        type: file.type,
      })

      resolve(renamedFile)
    })
  }

  upload(file) {
    return new Promise((resolve, reject) => {
      const upload = new DirectUpload(file, this.directUploadUrl)

      upload.create((error, blob) => {
        if (error) {
          // eslint-disable-next-line no-console
          console.log(error)
          reject(error)
          return
        }

        axios
          .post(this.mediaUploadUrl, {
            media: {
              file: blob.signed_id,
            },
          })
          .then(resolve)
          .catch((error) => {
            const message = get(error, 'response.data.errors[0]')
            if (message) {
              reject(message)
            } else {
              // eslint-disable-next-line no-console
              console.log({ error })
              reject(error)
            }
          })
      })
    })
  }
}
