import React, { Component } from 'react'
import { connect } from 'react-redux'
import { find } from 'lodash'
import ExternalPaginator from '../ExternalPaginator'
import PaginationControls from '../PaginationControls'
import { loadMedia } from '../../../../store/media/actions'

const ITEMS_PER_PAGE = 15

class Grid extends Component {
  componentDidMount() {
    const { loadMedia, pageNum } = this.props
    loadMedia({
      mediaType: 'image',
      numPerPage: ITEMS_PER_PAGE,
      pageNum,
      searchText: '',
      sortProperty: 'updated_at',
      sortAscending: false,
    })
  }

  render() {
    const {
      numPages,
      currentPageNum,
      pageIds,
      loadMedia,
      media,
      selectedMediaId,
      onSelect,
    } = this.props
    const pageMedia = pageIds.map((id) => find(media, { id }))

    return (
      <ExternalPaginator
        numPages={numPages}
        currentPageNum={currentPageNum}
        loadPage={(pageNum) => loadMedia({ pageNum })}
        render={(paginationProps) => (
          <div>
            <div className="media-chooser__grid">
              {pageMedia.map((mediaItem) =>
                renderMediaItem({ mediaItem, selectedMediaId, onSelect }),
              )}
            </div>
            <PaginationControls
              numPages={numPages}
              currentPageNum={currentPageNum}
              {...paginationProps}
            />
          </div>
        )}
      />
    )
  }
}

const renderMediaItem = ({ mediaItem, selectedMediaId, onSelect }) => {
  const isSelected = mediaItem.id === selectedMediaId
  const className = `media-chooser__grid-cell ${
    isSelected ? 'media-chooser__grid-cell--selected' : ''
  }`
  const url = (mediaItem.url || '').replace('(', '\\(').replace(')', '\\)')

  return (
    <button
      key={mediaItem.id}
      onClick={() => onSelect(mediaItem.id)}
      className={className}
    >
      <div
        className="media-chooser__grid-thumbnail"
        data-testid={`media-item-${mediaItem.id}`}
      >
        <img src={url} />
      </div>
    </button>
  )
}

const mapStateToProps = (state) => {
  const { numPages, currentPageNum, pageIds = [], media = [] } =
    state.media.image || {}
  return { numPages, currentPageNum, pageIds, media }
}

const mapDispatchToProps = { loadMedia }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Grid)
