import React from 'react'
import { Formik } from 'formik'
import { range } from 'lodash'
import Panel from '../../Panel'
import EnvironmentSelectInput from '../../SelectInput/Environment'
import FieldContainer from '../../FieldContainer'
import RichTextEditor from '../../../../../../shared/RichTextEditor'

const EditEventListBlock = ({ config, onSave, onDelete, onCancel }) => (
  <Panel title="Event List">
    <Formik
      initialValues={config}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => {
        const processedValues = {
          ...values,
          numEvents: Number(values.numEvents),
        }
        onSave(processedValues)
      }}
      render={({
        values,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <FieldContainer
            containerClasses="flex-1"
            error={errors.environmentIds}
            testId="environmentIds"
          >
            <div className="material-textfield invisible-select page-editor-select2-container">
              <label htmlFor="environmentId">Show Events from</label>
              <EnvironmentSelectInput
                placeholder="All Environments"
                value={values.environmentIds}
                onChange={(event) =>
                  handleEnvironmentsChange({ event, setFieldValue })
                }
              />
            </div>
          </FieldContainer>
          <FieldContainer
            containerClasses="flex-1"
            error={errors.title}
            testId="title"
          >
            <div className="material-textfield">
              <label htmlFor="environmentId">Title</label>
              <input
                type="text"
                data-testid="title"
                value={values.title}
                onChange={(e) => {
                  setFieldValue('title', e.target.value)
                }}
              />
            </div>
          </FieldContainer>
          <div className="material-textfield invisible-select">
            <label htmlFor="numEvents">Number of Upcoming Events</label>
            <select
              name="numEvents"
              value={values.numEvents}
              onChange={handleChange}
            >
              {numEventsOptions().map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>
          </div>
          <div
            className="df-row l-margin-bottom-sm"
            onClick={() =>
              setFieldValue('includeViewAllLink', !values.includeViewAllLink)
            }
          >
            <label className="container-check-box margin-bottom-0">
              <input
                type="checkbox"
                name="includeViewAllLink"
                checked={values.includeViewAllLink}
                onClick={(event) => event.stopPropagation()}
                onChange={() => {
                  /* noop */
                }}
              />
              <span className="checkmark-small" />
              <span>Include "View All" Link</span>
            </label>
          </div>
          <div
            className="df-row l-margin-bottom-sm"
            onClick={() => setFieldValue('showMessage', !values.showMessage)}
          >
            <label className="container-check-box margin-bottom-0">
              <input
                type="checkbox"
                name="showMessage"
                checked={values.showMessage}
                onClick={(event) => event.stopPropagation()}
                onChange={() => {
                  /* noop */
                }}
              />
              <span className="checkmark-small" />
              <span>Show message if no events are found</span>
            </label>
          </div>
          {values.showMessage && (
            <div className="material-textfield">
              <label htmlFor="noEventsMessage">No Events Message</label>
              <RichTextEditor
                value={values.noEventsMessage || ''}
                onChange={content => setFieldValue('noEventsMessage', content)}
              />
            </div>
          )}
          <div className="l-margin-top-sm">
            <button
              type="submit"
              data-testid="saveButton"
              onClick={handleSubmit}
              className="bg-green__btn l-margin-right-sm"
            >
              Save Block
            </button>
            <button
              type="submit"
              onClick={onCancel}
              className="bg-gray__btn l-margin-right-sm"
            >
              Cancel
            </button>
            <button
              type="button"
              title="Delete Block"
              onClick={onDelete}
              className="page-editor-edit-form__delete-icon"
            >
              <i className="far fa-trash-alt" />
            </button>
          </div>
        </form>
      )}
    />
  </Panel>
)

const numEventsOptions = () => {
  const lowestNumber = 1
  const highestNumber = 10
  return range(lowestNumber, highestNumber + 1)
}

const handleEnvironmentsChange = ({ event, setFieldValue }) => {
  const optionsArray = [...event.target.options]
  const selectedIds = optionsArray
    .filter((o) => o.selected)
    .map((o) => Number(o.value))
  setFieldValue('environmentIds', selectedIds)
}

const validate = (values) => {
  const errors = {}
  if (!values.title) {
    errors.title = 'Title is required'
  }

  return errors
}

export default EditEventListBlock
