import React from 'react'
import { Formik, FieldArray } from 'formik'
import Panel from '../../Panel'
import { blockTypeConfig } from '../../../config/blockTypes'
import RunValidationTrigger from './RunValidationTrigger'
import FieldContainer from '../../FieldContainer'
import RichTextEditor from '../../../../../../shared/RichTextEditor'

const EditFAQBlock = ({ config, newBlock, onSave, onDelete, onCancel }) => (
  <Panel title="FAQ">
    <Formik
      initialValues={config}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => onSave(values)}
      render={({
        values,
        errors,
        setFieldValue,
        handleChange,
        validateForm,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="edit-faq__section-title">
            <div className="edit-faq__section-title-title">
              FAQ Section Title
            </div>
            <FieldContainer error={errors.sectionTitle}>
              <label htmlFor="sectionTitle">Large Title</label>
              <input
                type="text"
                id="sectionTitle"
                data-testid="sectionTitle"
                value={values.sectionTitle}
                onChange={handleChange}
              />
            </FieldContainer>
          </div>

          <FieldArray
            name="items"
            validateOnChange={false}
            render={(arrayHelpers) => (
              <div>
                {values.items.map((item, index) => (
                  <FaqItem
                    key={index}
                    item={item}
                    index={index}
                    errors={(errors && errors['items'] && errors['items'][index]) || {}}
                    isOnlyItem={values.items.length == 1}
                    isLastItem={index == values.items.length - 1}
                    onDelete={() => arrayHelpers.remove(index)}
                    onChange={(field, value) => setFieldValue(`items.${index}.${field}`, value)}
                    onMoveUp={() => arrayHelpers.move(index, index - 1)}
                    onMoveDown={() => arrayHelpers.move(index, index + 1)}
                  />
                ))}

                <button
                  title="Add Another"
                  type="button"
                  data-testid="addButton"
                  className="interstitial__btn"
                  onClick={() => arrayHelpers.push(createNewItem())}
                >
                  + Add Another
                </button>
              </div>
            )}
          />

          <div>
            <button
              type="submit"
              data-testid="saveButton"
              onClick={handleSubmit}
              className="bg-green__btn l-margin-right-sm"
            >
              Save Block
            </button>

            <button
              type="submit"
              onClick={onCancel}
              className="bg-gray__btn l-margin-right-sm"
            >
              Cancel
            </button>

            <button
              type="button"
              title="Delete Block"
              onClick={onDelete}
              className="page-editor-edit-form__delete-icon"
            >
              <i className="far fa-trash-alt" />
            </button>
          </div>

          {newBlock ? null : (
            <RunValidationTrigger validateForm={validateForm} />
          )}
        </form>
      )}
    />
  </Panel>
)

const FaqItem = (props) => {
  const { item, index, errors, isOnlyItem, isLastItem, onDelete, onChange, onMoveUp, onMoveDown } = props

  return (
    <div className="edit-faq__item">
      <div className="edit-faq__item-header">
        <div>FAQ Item</div>

        <div>
          {!index == 0 && (
            <button type="button" title="Move Up" onClick={onMoveUp} style={{padding: "0.5rem"}}>
              <i className="fas fa-caret-up" />
            </button>
          )}

          {!isLastItem && (
            <button type="button" title="Move Down" onClick={onMoveDown} style={{padding: "0.5rem"}}>
              <i className="fas fa-caret-down" />
            </button>
          )}

          {!isOnlyItem && (
            <button
              type="button"
              title="Delete"
              onClick={onDelete}
              style={{padding: "0.5rem"}}
            >
              <i className="far fa-trash-alt" />
            </button>
          )}
        </div>
      </div>

      <FieldContainer error={errors.title}>
        <label>Question</label>

        <input
          type="text"
          value={item.title}
          onChange={(e) => onChange('title', e.target.value)}
        />
      </FieldContainer>

      <FieldContainer error={errors.text}>
        <div className="page_editor_wysiwyg">
          <RichTextEditor
            value={item.text || ''}
            onChange={content => onChange('text', content)}
          />
        </div>
      </FieldContainer>
    </div>
  )
}

const createNewItem = () => blockTypeConfig('faq').defaultConfig().items[0]

const validate = (values) => {
  let errors = {}

  if (!values.sectionTitle) {
    errors['sectionTitle'] = 'Title for FAQ section is required'
  }

  const allItemErrors = values.items.map((item, index) => {
    let itemErrors = {}
    const oneBasedIndex = index + 1

    if (!item.title) {
      itemErrors['title'] = `Title for item ${oneBasedIndex} is required`
    }

    if (!item.text) {
      itemErrors['text'] = `Text for item ${oneBasedIndex} is required`
    }

    return Object.keys(itemErrors).length == 0 ? null : itemErrors
  })

  if(allItemErrors.filter((item) => !!item).length > 0) {
    errors['items'] = allItemErrors
  }

  return errors
}

export default EditFAQBlock
