import React from 'react'

const ErrorList = ({ errors }) => {
  const fieldsWithErrors = Object.keys(errors).filter(
    (field) => typeof errors[field] === 'string',
  )
  if (fieldsWithErrors.length === 0) {
    return null
  }

  const errorLabel = fieldsWithErrors.length === 1 ? 'error' : 'errors'

  return (
    <div className="alert-box callout alert margin-bottom-1">
      The form contains {fieldsWithErrors.length} {errorLabel}.
      <ul data-testid="errorList">
        {fieldsWithErrors.map((field) => (
          <li key={field}>{errors[field]}</li>
        ))}
      </ul>
    </div>
  )
}

export default ErrorList
