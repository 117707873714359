import React from 'react'
import { Formik } from 'formik'
import { get } from 'lodash'
import MediaField from '../../MediaChooser/MediaField'
import Panel from '../../Panel'
import RunValidationTrigger from './RunValidationTrigger'
import FieldContainer from '../../FieldContainer'
import RichTextEditor from '../../../../../../shared/RichTextEditor'
import { clearExportData } from './utils'

const EditImageAndTextBlock = ({
  config,
  newBlock,
  onSave,
  onDelete,
  onCancel,
}) => (
  <Panel title="Image and Text">
    <Formik
      initialValues={config}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => onSave(clearExportData(values))}
      render={({
        values,
        errors,
        handleChange,
        setFieldValue,
        validateForm,
        handleSubmit,
      }) => (
        <form>
          <div>Image Details</div>
          <MediaField
            label="Image"
            mediaId={values.imageId}
            error={errors.imageId}
            exportedFileName={get(values, 'exportData.image')}
            onChange={(imageId) => setFieldValue('imageId', imageId)}
          />
          <FieldContainer error={errors.title}>
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              value={values.title}
              onChange={handleChange}
            />
          </FieldContainer>
          <FieldContainer error={errors.subtitle}>
            <label htmlFor="subtitle">Subtitle</label>
            <RichTextEditor
              value={values.subtitle || ''}
              onChange={content => setFieldValue('subtitle', content)}
            />
          </FieldContainer>
          <div>
            <button
              type="submit"
              data-testid="saveButton"
              onClick={handleSubmit}
              className="bg-green__btn l-margin-right-sm"
            >
              Save Block
            </button>
            <button
              type="submit"
              onClick={onCancel}
              className="bg-gray__btn l-margin-right-sm"
            >
              Cancel
            </button>
            <button
              type="button"
              title="Delete Block"
              onClick={onDelete}
              className="page-editor-edit-form__delete-icon"
            >
              <i className="far fa-trash-alt" />
            </button>
          </div>
          {newBlock ? null : (
            <RunValidationTrigger validateForm={validateForm} />
          )}
        </form>
      )}
    />
  </Panel>
)

const validate = (values) => {
  const errors = {}
  if (!values.imageId) {
    errors.imageId = 'Image is required'
  }
  if (!values.title) {
    errors.title = 'Title is required'
  }
  if (!values.subtitle) {
    errors.subtitle = 'Subtitle is required'
  }
  return errors
}

export default EditImageAndTextBlock
