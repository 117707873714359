import api from '../api'

export const STORE_EVENTS = 'STORE_EVENTS'

export const loadEvents = ({ includeEventId } = {}) => (dispatch) => {
  let url = '/admin/events'
  if (includeEventId) {
    url += `?include_event_id=${includeEventId}`
  }

  return api
    .get(url, { headers: { Accept: 'application/json' } })
    .then(({ data: events }) => {
      dispatch({
        type: STORE_EVENTS,
        events,
      })
    })
}
