export const swap = (myArray, indexA, indexB) =>
  ([myArray[indexB], myArray[indexA]] = [myArray[indexA], myArray[indexB]])

export const submitTo = (path, params, method = 'post', submitter = null) => {
  if (method !== 'get' && method !== 'post') {
    method = 'post'
    params['_method'] = method
  }

  const csrfParam = document.querySelector("meta[name=csrf-param]")
  const csrfToken = document.querySelector("meta[name=csrf-token]")

  let form = document.createElement('form')
  form.setAttribute('method', method)
  form.setAttribute('action', path)
  form.setAttribute('target', '_blank')

  if(submitter) {
    params[submitter.name] = submitter.value
  }

  params = {...params, ...getCSRFSettings()}

  for (let [key, value] of Object.entries(params)) {
    let hiddenField = document.createElement('input')
    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('name', key)
    hiddenField.setAttribute('value', value)
    form.appendChild(hiddenField)
  }

  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}

export const remove = (object, keys) => {
  const clonedObject = { ...object }
  keys.forEach((key) => delete clonedObject[key])
  return clonedObject
}

export const getCSRFToken = () => {
  const metaWithToken = document.querySelector("meta[name='csrf-token']")
  return metaWithToken ? metaWithToken.content : null
}

export const getCSRFSettings = () => {
  const csrfParam = document.querySelector("meta[name=csrf-param]")
  const csrfToken = document.querySelector("meta[name=csrf-token]")
  let csrfSettings = {}

  if(csrfParam && csrfToken) {
    csrfSettings[csrfParam.content] = csrfToken.content
  }

  return csrfSettings
}

export const copyToClipboard = (string) => {
  let copyTarget = document.createElement('input')
  copyTarget.type = 'text'
  copyTarget.value = string

  document.body.appendChild(copyTarget)
  copyTarget.select()
  document.execCommand('copy')
  copyTarget.parentNode.removeChild(copyTarget)
}

export const interpolate = (duration, options = {}) => {
  const noop = () => {}
  const startTime = +new Date()
  const onTick = options['onTick'] || noop
  const onComplete = options['onComplete'] || noop

  function tick() {
    const now = +new Date()
    const elapsed = now - startTime
    const t = Math.min(elapsed / duration, 1.0)

    onTick(t)

    if (elapsed >= duration) {
      onComplete()
    } else {
      requestAnimationFrame(tick)
    }
  }

  tick()
}

export const dig = (object, path, fallback = null) => {
  if(!object) return fallback
    
  const pathParts = path.split(".")
  let nextObject = object

  for(let part of pathParts) {
    nextObject = nextObject[part]

    if(!nextObject) {
      return fallback
    }
  }

  return nextObject
}

export const whenReady = () => {
  return new Promise((resolve, reject) => {
    if(document.readyState != "loading") {
      resolve()
    }else{
      document.addEventListener("DOMContentLoaded", () => resolve())
    }
  })
}

export const emptyNode = (element) => {
  while(element.firstChild) {
    element.firstChild.remove()
  }
}

export const replaceNodeHTML = (element, html) => {
  const range = document.createRange()
  range.setStart(element, 0)
  emptyNode(element)
  element.appendChild(range.createContextualFragment(html))
}

export const formifyObject = (object, basePrefix = "") => {
  let formified = {}
  let queue = Object.keys(object).map(key => ({value: object[key], key: key, prefix: basePrefix}))

  while(queue.length) {
    const { value, key, prefix } = queue.shift()
    const prefixedKey = prefix + `[${key}]`

    if(value instanceof Array) {
      queue = queue.concat(value.map((arrayValue, arrayIndex) => ({value: arrayValue, key: arrayIndex, prefix: prefixedKey})))
    }else if(value instanceof Object) {
      queue = queue.concat(Object.keys(value).map(objectKey => ({value: value[objectKey], key: objectKey, prefix: prefixedKey})))
    }else{
      if(value) {
        formified[prefixedKey] = value
      }
    }
  }

  return formified
}

export const delegateListener = (rootElement, eventName, selector, callback) => {
  rootElement.addEventListener(eventName, (delegatedEvent) => {
    let target = delegatedEvent.target
    while(target && target.matches) {
      if(target.matches(selector)) {
        callback.apply(target, [delegatedEvent, target])
        return
      }

      target = target.parentNode
    }
  })
}

export const fadeOut = (element, ms) => {
  let tracker = {
    element,
    start: performance.now(),
    duration: ms
  }
  
  const updateTracker = () => {
    const now = performance.now()
    const elapsed = now - tracker.start
    const t = elapsed / tracker.duration
    const opacity = 1 - t
    const dy = -25 * t

    element.style.opacity = opacity
    element.style.transform = `translate(0, ${dy}%)`

    if(elapsed < tracker.duration) {
      requestAnimationFrame(updateTracker)
    }else{
      element.parentNode.removeChild(element)
    }
  }

  updateTracker()
}


export const flash = (message, type = "notice") => {
  const flashElement = document.createElement("div");
  flashElement.classList.add("flash", `flash--${type}`)
  flashElement.textContent = message
  document.body.appendChild(flashElement)

  setTimeout(() => {
    fadeOut(flashElement, 500)
  }, 3000)
}

export const offsetFromParent = (element) => {
  const bounds = element.getBoundingClientRect()
  const parentBounds = element.parentNode.getBoundingClientRect()
  
  return {
    left: bounds.left - parentBounds.left,
    top: bounds.top - parentBounds.top
  }
}

export const closeContainingDetailsModal = (element) => {
  let current = element

  while(current) {
    if(current.matches(".details-modal")) {
      current.open = false
      return
    }

    current = current.parentNode
  }
}

export const sluggify = (string) => {
  return (string || '').toLowerCase()
                       .replace(/ /g, "-")
                       .replace(/\-+/g, "-")
                       .replace(/[^a-z0-9\-]/g, '')
}
