import { range } from 'lodash'
import generateUuid from 'uuid/v4'
import { getBlockHeight, newEmptyBlockConfig } from './blockTypes'

export const SECTION_HEIGHT = {
  tall: 'tall',
  medium: 'medium',
  short: 'short',
}

// prettier-ignore
export const layouts = [
  {
    height: SECTION_HEIGHT.medium,
    columns: [
      { blocks: 1 },
    ],
  },

  {
    height: SECTION_HEIGHT.medium,
    columns: [
      { blocks: 1 },
      { blocks: 1 },
    ],
  },

  {
    height: SECTION_HEIGHT.medium,
    columns: [
      { blocks: 1 },
      { blocks: 4 },
    ],
  },

  {
    height: SECTION_HEIGHT.medium,
    columns: [
      { blocks: 4 },
      { blocks: 1 },
    ],
  },

  {
    height: SECTION_HEIGHT.medium,
    columns: [
      { blocks: 1 },
      { blocks: 2 },
    ],
  },

  {
    height: SECTION_HEIGHT.medium,
    columns: [
      { blocks: 2 },
      { blocks: 1 },
    ],
  },

  {
    height: SECTION_HEIGHT.medium,
    columns: [
      { blocks: 4 },
      { blocks: 2 },
    ],
  },

  {
    height: SECTION_HEIGHT.medium,
    columns: [
      { blocks: 2 },
      { blocks: 4 },
    ],
  },

  {
    height: SECTION_HEIGHT.medium,
    columns: [
      { blocks: 8 },
    ],
  },

  {
    height: SECTION_HEIGHT.medium,
    columns: [
      { blocks: 2 },
      { blocks: 2 },
    ],
  },

  {
    height: SECTION_HEIGHT.short,
    columns: [
      { blocks: 2 },
      { blocks: 2 },
    ],
  },
]

export const newSectionFromLayout = (layout) => ({
  uuid: generateUuid(),
  sectionTitle: '',
  linkTitle: '',
  linkUrl: '',
  height: layout.height,
  columns: layout.columns.map((column) => ({
    height: layout.height,
    uuid: generateUuid(),
    blocks: range(column.blocks).map(() =>
      newEmptyBlockConfig(getBlockHeight(column.blocks, layout.height))
    )
  })),
})
