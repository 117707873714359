import React, { Component } from 'react'
import moment from 'moment'
import Select2 from 'react-select2-wrapper'
import FieldContainer from '../FieldContainer'
import { connect } from 'react-redux'
import { loadEvents } from '../../../../store/events/actions'

moment.updateLocale('en', {
  meridiem: function(hour) {
    if (hour < 12) {
      return 'a.m.'
    } else {
      return 'p.m.'
    }
  },
})

class EventSelectInput extends Component {
  state = { loaded: false }

  componentDidMount() {
    const includeEventId = this.props.value
    this.props.loadEvents({ includeEventId }).then(() => {
      this.setState({ loaded: true })
    })
  }

  render() {
    if (!this.state.loaded) {
      return <p>Loading…</p>
    }

    const {
      onChange,
      value,
      error,
      events,
      exportedName,
      fieldName,
    } = this.props
    const emptyValue = exportedName
      ? `(select an event; previously ${exportedName})`
      : '(select an event)'

    const selectedEvent = events.find(
      (testEvent) => Number(value) === testEvent.id,
    )

    let errorToShow = error
    if (
      !errorToShow &&
      (selectedEvent !== undefined && selectedEvent.status.toLowerCase() !== 'published')
    ) {
      errorToShow = `Event is ${selectedEvent.status}`
    }

    return (
      <FieldContainer
        error={errorToShow}
        fieldClasses="invisible-select page-editor-select2-container"
      >
        <label htmlFor="cardableId">{fieldName}</label>
        <Select2
          onChange={onChange}
          defaultValue={value}
          data={[
            { id: '', text: emptyValue },
            ...events.map((event) => ({
              id: event.id,
              text: `${event.title} - ${event.formatted_date} ${event.status !== 'published' ? ` (${event.status})` : ''}`,
            })),
          ]}
        />
      </FieldContainer>
    )
  }
}

const mapStateToProps = (state) => ({ events: state.events })
const mapDispatchToProps = { loadEvents }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventSelectInput)
