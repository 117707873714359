import React from 'react'

class ColorPreview extends React.Component {
  render() {
    const backgroundColor = `#${this.props.color || 'FFFFFF'}`
    return (
      <div className="img-upload-preview">
        <div
          id="backgroundColorPreview"
          className="primary-color-preview"
          style={{
            backgroundColor,
            color: fontColor(backgroundColor),
          }}
        >
          {this.props.color ? 'Aa' : 'N/A'}
        </div>
      </div>
    )
  }
}

const fontColor = (color) => (isLight(color) ? '#000000' : '#FFFFFF')

const isLight = (color) => {
  const rgb = hexToRgb(color)
  // https://stackoverflow.com/questions/1855884/determine-font-color-based-on-background-color
  return rgb && 0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b > 255 / 2
}

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export default ColorPreview
