import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select2 from 'react-select2-wrapper'
import { loadMessageSeries } from '../../../../store/messages/actions'
import FieldContainer from '../FieldContainer'

class MessageSeriesSelectInput extends Component {
  state = { loaded: false }

  componentDidMount() {
    const includeSeriesId = this.props.value
    this.props.loadMessageSeries({ includeSeriesId }).then(() => {
      this.setState({ loaded: true })
    })
  }

  render() {
    if (!this.state.loaded) {
      return <p>Loading…</p>
    }

    const { onChange, value, error, messageSeries, fieldName } = this.props

    const selectedSeries = messageSeries.find(
      (testSeries) => Number(value) === testSeries.id,
    )

    let errorToShow = error
    if (
      !errorToShow &&
      (selectedSeries !== undefined && selectedSeries.status !== 'published')
    ) {
      errorToShow = `Series is ${selectedSeries.status}`
    }

    return (
      <FieldContainer
        error={errorToShow}
        fieldClasses="invisible-select page-editor-select2-container"
      >
        <label htmlFor="cardableId">{fieldName}</label>
        <Select2
          onChange={onChange}
          defaultValue={value}
          data={[
            { id: '', text: '(select a series)' },
            ...messageSeries.map((series) => ({
              id: series.id,
              text: `${series.title} - ${series.first_date}${
                series.status !== 'published' ? ` (${series.status})` : ''
              }`,
            })),
          ]}
        />
      </FieldContainer>
    )
  }
}

const mapStateToProps = (state) => ({ messageSeries: state.messages.series })
const mapDispatchToProps = { loadMessageSeries }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessageSeriesSelectInput)
