import React from 'react'
import { Formik, FieldArray } from 'formik'
import Panel from '../../Panel'
import { blockTypeConfig } from '../../../config/blockTypes'
import RunValidationTrigger from './RunValidationTrigger'
import FieldContainer from '../../FieldContainer'
import RichTextEditor from '../../../../../../shared/RichTextEditor'

const createNewItem = () => blockTypeConfig('anchoredText').defaultConfig().items[0]

const EditAnchoredTextBlock = ({
  config,
  newBlock,
  onSave,
  onDelete,
  onCancel,
}) => (
  <Panel title="Anchored Text">
    <Formik
      initialValues={config}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => onSave(values)}
      render={({
        values,
        errors,
        setFieldValue,
        validateForm,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <FieldArray
            name="items"
            validateOnChange={false}
            render={(arrayHelpers) => (
              <div>
                {values.items.map((item, index) => (
                  <AnchoredTextItem
                    key={index}
                    item={item}
                    index={index}
                    errors={errors}
                    isLastItem={index == values.items.length - 1}
                    onChange={(field, value) => setFieldValue(`items.${index}.${field}`, value)}
                    onDelete={() => arrayHelpers.remove(index)}
                    onMoveUp={() => arrayHelpers.move(index, index - 1)}
                    onMoveDown={() => arrayHelpers.move(index, index + 1)}
                  />
                ))}

                <button
                  title="Add Another"
                  type="button"
                  data-testid="addButton"
                  className="interstitial__btn"
                  onClick={() => arrayHelpers.push(createNewItem())}
                >
                  + Add Another
                </button>
              </div>
            )}
          />

          <div>
            <button
              type="submit"
              data-testid="saveButton"
              onClick={handleSubmit}
              className="bg-green__btn l-margin-right-sm"
            >
              Save Block
            </button>
            <button
              type="submit"
              onClick={onCancel}
              className="bg-gray__btn l-margin-right-sm"
            >
              Cancel
            </button>
            <button
              type="button"
              title="Delete Block"
              onClick={onDelete}
              className="page-editor-edit-form__delete-icon"
            >
              <i className="far fa-trash-alt" />
            </button>
          </div>
          {newBlock ? null : (
            <RunValidationTrigger validateForm={validateForm} />
          )}
        </form>
      )}
    />
  </Panel>
)

const AnchoredTextItem = (props) => {
  const { item, index, errors, isLastItem, onChange, onDelete, onMoveUp, onMoveDown } = props
  const isOnlyItem = index == 0 && isLastItem

  return (
    <div className="edit-anchored-text-item">
      <div className="edit-anchored-text-item-header">
        <div>Anchored Text Item</div>
        <div>
          {!index == 0 && (
            <button type="button" title="Move Up" onClick={onMoveUp} style={{padding: "0.5rem"}}>
              <i className="fas fa-caret-up" />
            </button>
          )}

          {!isLastItem && (
            <button type="button" title="Move Down" onClick={onMoveDown} style={{padding: "0.5rem"}}>
              <i className="fas fa-caret-down" />
            </button>
          )}

          {!isOnlyItem ? (
            <button
              type="button"
              data-testid={`delete-${item.uuid}`}
              title="Delete"
              onClick={onDelete}
              style={{padding: "0.5rem"}}
            >
              <i className="far fa-trash-alt" />
            </button>
          ) : null}
        </div>
      </div>
      <FieldContainer
        error={errors[`items.${index}.title`]}
        testId={`item-${index + 1}-title`}
      >
        <label htmlFor={`largeTitle-${item.uuid}`}>
          Large Title
        </label>

        <input
          type="text"
          id={`largeTitle-${item.uuid}`}
          data-testid={`title-${item.uuid}`}
          value={item.title}
          onChange={(e) => onChange('title', e.target.value)}
        />
      </FieldContainer>

      <FieldContainer
        error={errors[`items.${index}.text`]}
        testId={`item-${index + 1}-text`}
      >
        <div className="page_editor_wysiwyg">
          <RichTextEditor
            value={item.text || ''}
            onChange={content => onChange('text', content)}
          />
        </div>
      </FieldContainer>
    </div>
  )
}

const validate = (values) => {
  const errors = {}
  values.items.forEach((item, index) => {
    const oneBasedIndex = index + 1
    if (!item.title) {
      errors[
        `items.${index}.title`
      ] = `Title for item ${oneBasedIndex} is required`
    }
    if (!item.text) {
      errors[
        `items.${index}.text`
      ] = `Text for item ${oneBasedIndex} is required`
    }
  })
  return errors
}

export default EditAnchoredTextBlock
