import React from 'react'
import { Formik } from 'formik'
import Panel from '../../Panel'
import FieldContainer from '../../FieldContainer'
import RunValidationTrigger from './RunValidationTrigger'
import JSColor from './JSColor'
import urlRegex from '../../urlRegex'
import ColorPreview from './ColorPreview'

const EditSocialIconBlock = ({
  config,
  newBlock,
  onSave,
  onDelete,
  onCancel,
}) => (
  <JSColor jsColorClassName="jscolor-social-icon">
    <Panel title="Social Icon">
      <Formik
        initialValues={config}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => onSave(values)}
        render={({
          values,
          errors,
          handleChange,
          setFieldValue,
          validateForm,
          handleSubmit,
        }) => {
          // Callback function for the jscolor component
          window.colorPickerChanged = (jscolor) => {
            setFieldValue('backgroundColor', jscolor.toString())
          }

          return (
            <form onSubmit={handleSubmit}>
              <FieldContainer
                fieldClasses="invisible-select"
                error={errors.socialIcon}
              >
                <label htmlFor="socialIcon">Social Icon</label>
                <select
                  name="socialIcon"
                  className="edit-social-icon__select"
                  data-testid="socialIconField"
                  value={values.socialIcon || ''}
                  onChange={handleChange}
                >
                  <option value="">&nbsp;</option>
                  <option value="facebook">Facebook</option>
                  <option value="instagram">Instagram</option>
                  <option value="spotify">Spotify</option>
                  <option value="twitter">Twitter</option>
                  <option value="youtube">YouTube</option>
                </select>
              </FieldContainer>
              <FieldContainer error={errors.link}>
                <label htmlFor="link">URL</label>
                <input
                  type="text"
                  id="link"
                  data-testid="linkField"
                  value={values.link}
                  onChange={handleChange}
                  placeholder="https://www.example.com"
                />
              </FieldContainer>
              <div className="df-row align-flex-start">
                <ColorPreview color={values.backgroundColor} />
                <div className="material-textfield">
                  <label htmlFor="backgroundColor">Background Color</label>
                  <div className="edit-social-icon__background-input">
                    <input
                      type="text"
                      id="backgroundColor"
                      data-testid="backgroundColorField"
                      className="jscolor-social-icon"
                      data-jscolor={`{onFineChange:'colorPickerChanged(this)', value:'${
                        values.backgroundColor
                      }', valueElement: null, styleElement: null}`}
                      value={values.backgroundColor}
                      onChange={handleChange}
                      placeholder={'N/A'}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue('backgroundColor', '')
                      }}
                      className="far fa-trash-alt"
                    />
                  </div>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  data-testid="saveButton"
                  onClick={handleSubmit}
                  className="bg-green__btn l-margin-right-sm"
                >
                  Save Block
                </button>
                <button
                  type="submit"
                  onClick={onCancel}
                  className="bg-gray__btn l-margin-right-sm"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  title="Delete Block"
                  onClick={onDelete}
                  className="page-editor-edit-form__delete-icon"
                >
                  <i className="far fa-trash-alt" />
                </button>
              </div>
              {newBlock ? null : (
                <RunValidationTrigger validateForm={validateForm} />
              )}
            </form>
          )
        }}
      />
    </Panel>
  </JSColor>
)

const validate = (values) => {
  const errors = {}
  if (!values.socialIcon) {
    errors.socialIcon = 'Social icon is required'
  }
  if (!values.link) {
    errors.link = 'URL required'
  }
  if (values.link && !values.link.match(urlRegex)) {
    errors.link = 'URL is not valid'
  }
  return errors
}

export default EditSocialIconBlock
