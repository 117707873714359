import $ from 'jquery'
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver'
import 'tinymce/plugins/link'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/code'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/plugins/image'
import 'tinymce/plugins/autoresize'
import 'tinymce/icons/default'
import 'tinymce/skins/ui/oxide/skin.min.css'
import { whenReady } from '../utils'
import { setGlobal, getGlobal, fetchGlobal } from '../symplexi'

tinymce.PluginManager.add('stylebuttons', function(editor, url) {
  ['pre', 'p', 'code', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].forEach(function(name) {
    editor.ui.registry.addButton('style-' + name, {
      tooltip: 'Toggle ' + name,
      text: name.toUpperCase(),
      onAction: function() {
        editor.execCommand('mceToggleFormat', false, name)
      },
      onPostRender: function() {
        var self = this
        var setup = function() {
          editor.formatter.formatChanged(name, function(state) {
            self.active(state)
          })
        }
        editor.formatter ? setup() : editor.on('init', setup)
      }
    })
  })
})

let toolbar = 'bold italic underline | link blockquote | numlist bullist | style-h1 style-h2 style-h3 style-code | image media |'
let plugins = 'link lists stylebuttons paste image media autoresize'

setGlobal("tinymceConfig", {
  toolbar,
  plugins,
  menubar: false,
  statusbar: true,
  anchor_top: false,
  anchor_bottom: false,
  min_height: 100,
  max_height: 400,
  paste_auto_cleanup_on_paste: true,
  skin: false,
  content_css: false,
  body_class: "wysiwyg-content",
  link_class_list: [
    { title: 'None', value: '' },
    { title: 'Button', value: 'bg-primary__btn' },
    { title: 'Large Button', value: 'bg-primary__btn lg-btn uppercase' }
  ]
})

setGlobal("sendTinyMCEReadySignal", () => {
  document.dispatchEvent(new CustomEvent("symplexi:tinymce-ready", { bubbles: true }))
})

export const tinymceReady = fetchGlobal("tinymceReady", () => {
  return new Promise((resolve, reject) => {
    document.addEventListener("symplexi:tinymce-ready", (e) => {
      resolve()
    })
  })
})

tinymceReady.then(() => {
  tinymce.init({
    selector: '.tinymce',
    ...getGlobal("tinymceConfig")
  })
})
