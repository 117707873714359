import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select2 from 'react-select2-wrapper'
import { loadMessages } from '../../../../store/messages/actions'
import FieldContainer from '../FieldContainer'

class MessageSelectInput extends Component {
  state = { loaded: false }

  componentDidMount() {
    const includeMessageId = this.props.value
    this.props.loadMessages({ includeMessageId }).then(() => {
      this.setState({ loaded: true })
    })
  }

  render() {
    if (!this.state.loaded) {
      return <p>Loading…</p>
    }

    const { onChange, value, error, messages, fieldName } = this.props

    const selectedMessage = messages.find(
      (testMessage) => Number(value) === testMessage.id,
    )

    let errorToShow = error
    if (!errorToShow) {
      if(selectedMessage !== undefined && selectedMessage.status !== 'published') {
        errorToShow = `Message is ${selectedMessage.status}`
      }else if(!!value && selectedMessage === undefined) {
        errorToShow = `Message #${value} no longer exists.`
      }
    }

    return (
      <FieldContainer
        error={errorToShow}
        fieldClasses="invisible-select page-editor-select2-container"
      >
        <label htmlFor="cardableId">{fieldName}</label>
        <Select2
          onChange={onChange}
          defaultValue={value}
          data={[
            { id: '', text: '(select a message)' },
            ...messages.map((message) => ({
              id: message.id,
              text: `${message.title} - ${message.first_date}${
                message.status !== 'published' ? ` (${message.status})` : ''
              }`,
            })),
          ]}
        />
      </FieldContainer>
    )
  }
}

const mapStateToProps = (state) => ({ messages: state.messages.messages })
const mapDispatchToProps = { loadMessages }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessageSelectInput)
