import { loadGoogleMapsApi } from '../../../../../../../services/googleMaps.js'

const geocode = ({ address }) => {
  return new Promise((resolve, reject) => {
    loadGoogleMapsApi()
      .then(maps => {
        const g = new maps.Geocoder()
        g.geocode({ address }, (results, status) => {
          if(status == "OK") {
            resolve(results[0].geometry.location)
          }else{
            reject("Address lookup failed because: " + status)
          }
        })
      })
      .catch(error => reject(error))
  })
}

export default geocode
