import { Controller } from "stimulus"
import { sluggify } from "../../utils"

export default class extends Controller {
  initialize() {
    this.autoslugInput = this.data.get("input")
  }

  connect() {
    if(this.autoslugInput) {
      const input = document.querySelector(`#${this.autoslugInput}`)

      if(input) {
        input.addEventListener("change", e => {
          this.element.value = sluggify(e.target.value)
        })
      }
    }
  }
}
