import React, { Component } from 'react'
import { connect } from 'react-redux'
import { find } from 'lodash'
import moment from 'moment'
import ExternalPaginator from '../ExternalPaginator'
import PaginationControls from '../PaginationControls'
import { loadMedia } from '../../../../store/media/actions'

const ITEMS_PER_PAGE = 10

class List extends Component {
  handleSortPropertyChange = (newSortProperty) => {
    const { sortProperty, sortAscending, loadMedia } = this.props

    let newSortAscending
    if (sortProperty === newSortProperty) {
      newSortAscending = !sortAscending
    } else {
      newSortAscending = false
    }

    loadMedia({
      pageNum: 1,
      sortProperty: newSortProperty,
      sortAscending: newSortAscending,
    })
  }

  componentDidMount() {
    const { loadMedia } = this.props
    loadMedia({
      mediaType: 'image',
      numPerPage: ITEMS_PER_PAGE,
      searchText: '',
      pageNum: 1,
      sortProperty: 'updated_at',
      sortAscending: false,
    })
  }

  render() {
    const {
      numPages,
      currentPageNum,
      sortProperty,
      sortAscending,
      pageIds,
      loadMedia,
      media,
      selectedMediaId,
      onSelect,
    } = this.props

    const pageMedia = pageIds.map((id) => find(media, { id }))

    return (
      <ExternalPaginator
        numPages={numPages}
        currentPageNum={currentPageNum}
        loadPage={(pageNum) => loadMedia({ pageNum })}
        render={(paginationProps) => (
          <div>
            <ListDisplay
              media={pageMedia}
              selectedMediaId={selectedMediaId}
              sortProperty={sortProperty}
              sortAscending={sortAscending}
              onSortPropertyChange={this.handleSortPropertyChange}
              onSelect={onSelect}
            />
            <PaginationControls
              numPages={numPages}
              currentPageNum={currentPageNum}
              {...paginationProps}
            />
          </div>
        )}
      />
    )
  }
}

const ListDisplay = ({
  media,
  selectedMediaId,
  onSelect,
  sortProperty,
  sortAscending,
  onSortPropertyChange,
}) => (
  <div className="media-chooser__list">
    <table className="table media-chooser__list-table">
      <thead>
        <tr className="admin-table-header">
          <th />
          <th>
            <button
              onClick={() =>
                onSortPropertyChange('active_storage_blobs.filename')
              }
              className={`table-header ${
                sortProperty === 'active_storage_blobs.filename'
                  ? 'current'
                  : ''
              } ${sortAscending ? 'asc' : 'desc'}`}
            >
              Title
            </button>
          </th>
          <th>
            <button
              onClick={() => onSortPropertyChange('alt_text')}
              className={`table-header ${
                sortProperty === 'alt_text' ? 'current' : ''
              } ${sortAscending ? 'asc' : 'desc'}`}
            >
              Alt Text
            </button>
          </th>
          <th>
            <button
              onClick={() => onSortPropertyChange('tags')}
              className={`table-header ${
                sortProperty === 'tags' ? 'current' : ''
              } ${sortAscending ? 'asc' : 'desc'}`}
            >
              Tags
            </button>
          </th>
          <th>
            <button
              onClick={() =>
                onSortPropertyChange('active_storage_blobs.content_type')
              }
              className={`table-header ${
                sortProperty === 'active_storage_blobs.content_type'
                  ? 'current'
                  : ''
              } ${sortAscending ? 'asc' : 'desc'}`}
            >
              File Type
            </button>
          </th>
          <th>
            <button
              onClick={() => onSortPropertyChange('updated_at')}
              className={`table-header ${
                sortProperty === 'updated_at' ? 'current' : ''
              } ${sortAscending ? 'asc' : 'desc'}`}
            >
              Time
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        {media.map((mediaItem) =>
          renderMediaItem({ mediaItem, selectedMediaId, onSelect }),
        )}
      </tbody>
    </table>
  </div>
)

const renderMediaItem = ({ mediaItem, selectedMediaId, onSelect }) => {
  const isSelected = mediaItem.id === selectedMediaId
  const className = `admin-table-body media-chooser__list-row ${
    isSelected ? 'media-chooser__list-row--selected' : ''
  }`

  let formattedDate = ''
  if (mediaItem.last_activity_at) {
    formattedDate = moment(mediaItem.last_activity_at).format(
      'MM/DD/YY [at] h:mm a',
    )
  }

  const url = (mediaItem.url || '').replace('(', '\\(').replace(')', '\\)')

  return (
    <tr
      key={mediaItem.id}
      data-testid={`list-media-item-${mediaItem.id}`}
      className={className}
      role="button"
      onClick={() => onSelect(mediaItem.id)}
    >
      <td>
        <div className="media-chooser__list-thumbnail-container">
          <div className="media-chooser__list-thumbnail">
            <img src={url} />
          </div>
        </div>
      </td>
      <td>{mediaItem.filename}</td>
      <td>{mediaItem.alt_text}</td>
      <td>{(mediaItem.tags || []).join(', ')}</td>
      <td>{mediaItem.file_type}</td>
      <td>{formattedDate}</td>
    </tr>
  )
}

const mapStateToProps = (state) => {
  const {
    numPages,
    currentPageNum,
    sortProperty,
    sortAscending,
    pageIds = [],
    media = [],
  } = state.media.image || {}
  return {
    numPages,
    currentPageNum,
    sortProperty,
    sortAscending,
    pageIds,
    media,
  }
}

const mapDispatchToProps = { loadMedia }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(List)
