let readyActions = []
let loadActions = []
let plugins = {}
let globals = {}

const current = () => ({ plugins, globals })

export const setGlobal = (name, value) => {
  globals[name] = value
}

export const getGlobal = (name) => {
  return globals[name]
}

export const fetchGlobal = (name, fallback = undefined) => {
  if(globals[name]) {
    return globals[name]
  }

  if(fallback) {
    setGlobal(name, fallback())
    return getGlobal(name)
  }

  return undefined
}

export const updateGlobal = (name, updateFunction) => {
  const currentValue = getGlobal(name)
  const updatedValue = updateFunction(currentValue)
  setGlobal(name, updatedValue)
}

export const addPlugin = (name, plugin) => {
  plugins[name] = plugin
}

export const addReadyAction = (callback) => {
  readyActions.push(callback)
}

export const addLoadAction = (callback) => {
  loadActions.push(callback)
}

export const run = (callback) => {
  callback.call(window, current())
}

const runReadyActions = () => {
  readyActions.forEach(callback => {
    callback.call(window, current())
  })
}

const runLoadActions = () => {
  loadActions.forEach(callback => {
    callback.call(window, current())
  })
}

document.addEventListener("DOMContentLoaded", runReadyActions)
window.addEventListener("load", runLoadActions)

window.symplexi = {
  getGlobal,
  setGlobal,
  updateGlobal
}