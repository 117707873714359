import { Controller } from "stimulus"
import { delegateListener } from "../../utils"

export default class extends Controller {
  static targets = ["listViewSettingsToggle", "contentForm"]

  connect() {
    delegateListener(this.element, "click", "#clear-schedule-button", () => {
      const field = this.element.querySelector("#entry-will-publish-at")
      const flatpickr = field._flatpickr
      flatpickr.clear()
    })
  }

  get hasUnsavedChanges() {
    return this.data.get("hasUnsavedChanges") == "true"
  }

  set hasUnsavedChanges(value) {
    this.data.set("hasUnsavedChanges", !!value ? "true" : "false")
  }

  toggleListViewSettings(event) {
    event.preventDefault()
    this.listViewSettingsToggleTarget.open = !this.listViewSettingsToggleTarget.open
  }
}
