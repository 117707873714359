import React, { Component } from 'react'
import { Formik } from 'formik'
import moment from 'moment'
import Select2 from 'react-select2-wrapper'
import { connect } from 'react-redux'
import { updateMediaItem } from '../../../../store/media/actions'
import ErrorList from '../ErrorList'
import TagInput from '../../../../../shared/TagInput'

const ACTIVITY = {
  create: {
    key: 'create',
    label: 'Created',
  },
  update: {
    key: 'update',
    label: 'Updated',
  },
}

export class MediaMetadata extends Component {
  handleEdit = () => this.props.onChangeEditing(true)

  handleCancelEdit = () => this.props.onChangeEditing(false)

  handleSave = () => this.props.onChangeEditing(false)

  render() {
    const { mediaItem, isEditing } = this.props

    if (!mediaItem) {
      return null
    } else if (isEditing) {
      return this.renderEditForm()
    } else {
      return this.renderDisplay()
    }
  }

  renderDisplay = () => {
    const { mediaItem } = this.props

    const { filename, alt_text, tags } = mediaItem

    return (
      <div>
        <div className="l-margin-bottom-md">{filename}</div>
        {alt_text ? <div className="l-margin-bottom-md">"{alt_text}"</div> : ''}
        <div className="l-margin-bottom-md">{(tags || []).join(', ')}</div>
        {this.renderReadOnlyFields({ mediaItem })}
        <button
          type="button"
          data-testid="editButton"
          onClick={this.handleEdit}
          className="bg-primary__btn l-margin-right-sm"
        >
          Edit
        </button>
      </div>
    )
  }

  renderReadOnlyFields = ({ mediaItem }) => {
    const {
      last_activity_type,
      last_activity_at,
      last_activity_user,
    } = mediaItem
    const { label } = ACTIVITY[last_activity_type] || {}

    const activityLabel =
      label && last_activity_user ? `${label} by ${last_activity_user}` : null
    const formattedDate = last_activity_at
      ? moment(last_activity_at).format('MM/DD/YY [at] h:mm a')
      : null

    return (
      <div className="media-chooser__form-field">
        <div className="l-margin-bottom-md">Image</div>
        <div className="l-margin-bottom-md">{activityLabel}</div>
        <div className="l-margin-bottom-md">{formattedDate}</div>
      </div>
    )
  }

  stopPropagationThen = (next) => (event) => {
    event.preventDefault()
    event.stopPropagation()
    return next()
  }

  renderEditForm = () => {
    const { mediaItem, mediaTags, updateMediaItem } = this.props

    return (
      <Formik
        initialValues={mediaItem}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(mediaItem) =>
          updateMediaItem({
            mediaType: 'image',
            mediaItem,
          }).then(this.handleSave)
        }
        render={({
          values,
          errors,
          handleChange,
          setFieldValue,
          handleSubmit,
        }) => (
          <form onSubmit={this.stopPropagationThen(handleSubmit)}>
            <ErrorList errors={errors} />
            <div className="media-chooser__form-field">
              <label
                htmlFor="filename"
                className="media-chooser__form-field-label"
              >
                Name
              </label>
              <input
                type="text"
                id="filename"
                data-testid="filenameField"
                value={values.filename}
                onChange={handleChange}
                className="media-chooser__form-field-input"
              />
            </div>
            <div className="media-chooser__form-field">
              <label
                htmlFor="alt_text"
                className="media-chooser__form-field-label"
              >
                Alt Text
              </label>
              <input
                type="text"
                id="alt_text"
                data-testid="altTextField"
                value={values.alt_text || ''}
                onChange={handleChange}
                className="media-chooser__form-field-input"
              />
            </div>

            <div className="media-chooser__form-field l-margin-bottom-sm">
              <label className="media-chooser__form-field-label">Tags</label>
              <TagInput
                options={mediaTags}
                values={values.tags}
                onChange={value => setFieldValue('tags', value)}
              />
            </div>

            {this.renderReadOnlyFields({ mediaItem })}

            <button
              type="submit"
              data-testid="saveButton"
              className="bg-green__btn l-margin-right-sm"
            >
              Save
            </button>
            <button
              type="button"
              data-testid="cancelButton"
              onClick={this.handleCancelEdit}
              className="bg-white__btn l-margin-right-sm"
            >
              Cancel
            </button>
          </form>
        )}
      />
    )
  }
}

const handleTagsChange = ({ event, setFieldValue }) => {
  const optionsArray = [...event.target.options]
  const selectedValues = optionsArray
    .filter((o) => o.selected)
    .map((o) => o.value)
  setFieldValue('tags', selectedValues)
}

const validate = (values) => {
  const errors = {}
  if (!values.filename) {
    errors.filename = 'Name is required'
  }
  return errors
}

const mapDispatchToProps = { updateMediaItem }

export default connect(
  null,
  mapDispatchToProps,
)(MediaMetadata)
