import { Controller } from 'stimulus'
import { getGlobal, setGlobal } from '../../symplexi'
import { whenReady } from '../../utils'

const loadYoutubeApi = () => {
  let whenYoutubeLoads = getGlobal('whenYoutubeLoads')

  if (whenYoutubeLoads) {
    return whenYoutubeLoads
  }

  whenYoutubeLoads = new Promise(function(resolve, reject) {
    const youtubeScript = document.createElement('script')
    youtubeScript.async = true
    youtubeScript.defer = true
    youtubeScript.src = 'https://www.youtube.com/iframe_api'

    window.onYouTubeIframeAPIReady = () => {
      resolve()
    }

    whenReady().then(() => {
      document.body.appendChild(youtubeScript)
    })
  })

  setGlobal('whenYoutubeLoads', whenYoutubeLoads)

  return whenYoutubeLoads
}

const loadVimeoApi = () => {
  let whenVimeoLoads = getGlobal('whenVimeoLoads')

  if (whenVimeoLoads) {
    return whenVimeoLoads
  }

  whenVimeoLoads = new Promise(function(resolve, reject) {
    const vimeoScript = document.createElement('script')
    vimeoScript.src = 'https://player.vimeo.com/api/player.js'
    vimeoScript.async = true
    vimeoScript.defer = true
    vimeoScript.onload = function() {
      resolve()
    }

    whenReady().then(() => {
      document.body.appendChild(vimeoScript)
    })
  })

  setGlobal('whenVimeoLoads', whenVimeoLoads)

  return whenVimeoLoads
}

export default class extends Controller {
  static targets = ['block', 'cover', 'player', 'chat']

  initialize() {
    this.onPlay = () => {}
  }

  connect() {
    const source = this.data.get('source').toLowerCase()

    switch (source) {
      case 'youtube':
        this.youtubeSetup()
        break
      case 'vimeo':
        this.vimeoSetup()
        break
    }
  }

  youtubeLoaded() {
    this.youtubeLoadedPromise = this.youtubeLoadedPromise || loadYoutubeApi()
    return this.youtubeLoadedPromise
  }

  vimeoLoaded() {
    this.vimeoLoadedPromise = this.vimeoLoadedPromise || loadVimeoApi()
    return this.vimeoLoadedPromise
  }

  youtubeSetup() {
    const videoId = this.data.get('id')

    this.youtubeLoaded().then(() => {
      const player = new YT.Player(this.playerTarget, { videoId: videoId })

      player.addEventListener('onStateChange', (event) => {
        switch (event.data) {
          case YT.PlayerState.ENDED:
            this.blockTarget.classList.remove('video-embed--playing')
            break
          case YT.PlayerState.PLAYING:
            this.blockTarget.classList.add('video-embed--playing')
            break
        }
      })

      this.onPlay = () => {
        player.playVideo()
      }

      if (this.hasChatTarget) {
        const chatIframe = document.createElement('iframe')
        chatIframe.src = `https://www.youtube.com/live_chat?v=${videoId}&embed_domain=${window.location.hostname}`
        this.chatTarget.appendChild(chatIframe)
      }
    })
  }

  vimeoSetup() {
    this.vimeoLoaded().then(() => {
      const player = new Vimeo.Player(this.playerTarget, {
        id: this.data.get('id')
      })

      player.on('ended', () => {
        this.blockTarget.classList.remove('video-embed--playing')
      })

      this.onPlay = () => {
        player.play()
      }
    })
  }

  play() {
    this.blockTarget.classList.add('video-embed--playing')
    this.onPlay()
  }
}
