import { connect } from 'react-redux'
import { find, get } from 'lodash'
import { CARD_TYPE } from '../Card'

const CardableLoader = ({
  render,
  cardType,
  cardableId: id,
  pages,
  events,
  messages,
  messageSeries,
}) => {
  const cardableFunctionByCardType = {
    [CARD_TYPE.PAGE.key]: () => findById(pages, id),
    [CARD_TYPE.EVENT_OCCURRENCE.key]: () => findById(events, id),
    [CARD_TYPE.MESSAGE.key]: () => findById(messages, id),
    [CARD_TYPE.MESSAGE_SERIES.key]: () => findById(messageSeries, id),
    [CARD_TYPE.MOST_RECENT_MESSAGE.key]: () => messages[0] || {},
  }
  const cardableFunction = get(cardableFunctionByCardType, cardType, () => ({}))
  const cardable = cardableFunction()
  return render({ cardable })
}

const findById = (collection, id) => find(collection, ['id', Number(id)]) || {}

const mapStateToProps = (state) => ({
  pages: state.pages,
  events: state.events,
  messages: state.messages.messages,
  messageSeries: state.messages.series,
})

export default connect(mapStateToProps)(CardableLoader)
