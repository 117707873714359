import { STORE_EVENTS } from './actions'

const initialData = []

function events(state = initialData, action) {
  switch (action.type) {
    case STORE_EVENTS:
      return action.events
    default:
      return state
  }
}

export default events
