import api from '../api'

export const STORE_PAGES = 'STORE_PAGES'

export const loadPages = ({ includePageId, withBlocks } = {}) => (dispatch) => {
  let url = '/api/v1/pages'
  let params = new URLSearchParams()

  if(includePageId) {
    params.append('include_page_id', includePageId)
  }

  if(withBlocks && withBlocks.length) {
    withBlocks.forEach(block => params.append("with_block[]", block))
  }

  url += `?${params}`

  return api
    .get(url, { headers: { Accept: 'application/json' } })
    .then(({ data: pages }) => {
      dispatch({
        type: STORE_PAGES,
        pages,
      })
    })
}
