import { Controller } from "stimulus"

const MIN_WIDTH = 32

const autoexpand = (input) => {
  input.style.width = "inherit"
  input.style.width = `${input.scrollWidth}px`
}

export default class extends Controller {
  connect() {
    setTimeout(() => autoexpand(this.element), 10)
    this.element.addEventListener("input", e => autoexpand(e.target))
  }
}
