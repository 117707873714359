import { Controller } from "stimulus"

const sluggify = (string) => {
  return string.toLowerCase()
               .replace(/ /g, '-')
               .replace(/\-+/g, '-')
               .replace(/[^a-z0-9\-]/g, '')
}

export default class extends Controller {
  static targets = ["title", "slug", "type", "page", "url", "occurrenceTemplate", "occurrenceList", "occurrence"]

  updateTypeFields(event) {
    const type = this.typeTarget.value
    this.data.set('type', type)
  }

  updateSlug(event) {
    const title = this.titleTarget.value
    this.slugTarget.value = sluggify(title)
  }

  addOccurrence() {
    const occurrenceHTML = this.occurrenceTemplateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.occurrenceListTarget.insertAdjacentHTML('beforeend', occurrenceHTML)
  }
}