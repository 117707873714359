import { Controller } from "stimulus"
import { Attachment } from "trix"
import { delegateListener, getCSRFSettings } from "../../utils"

const codeEditorTemplate = document.createElement("details")
codeEditorTemplate.classList.add("details-modal", "details-modal--with-overlay", "details-modal--detached")
codeEditorTemplate.innerHTML = `
  <summary></summary>
  <div class="details-modal__content details-modal__content--detached">
    <h2>Code Attachment</h2>
    <textarea data-target="trix-code-attachment.editorInput"></textarea>
    
    <div class="margin-top-1">
      <button type="button" class="bg-primary__btn" data-action="trix-code-attachment#attachCode">Save Attachment</button>
    </div>
  </div>
`

export default class extends Controller {
  static targets = ["field", "editorInput"]

  connect() {
    this.trix = this.fieldTarget.editor
    this.codeEditor.open = false

    delegateListener(this.fieldTarget, "dblclick", `.attachment[data-trix-content-type="application/vnd.symplexi.code.html"]`, (e, target) => {
      this.editCodeAttachment(target)
    })
  }

  newCodeAttachment(event) {
    this.editorInputTarget.value = ""
    this.codeEditor.open = true
  }

  editCodeAttachment(attachment) {
    const attachmentData = JSON.parse(attachment.dataset.trixAttachment)
    this.editorInputTarget.value = attachmentData.content.trim()
    this.codeEditor.open = true
  }

  attachCode() {
    this.createCodeAttachment(this.editorInputTarget.value.trim())
    this.codeEditor.open = false
  }

  createCodeAttachment(content) {
    this.insertCodeAttachment(content)
  }

  insertCodeAttachment(code) {
    const attachment = new Attachment({
      contentType: "application/vnd.symplexi.code.html",
      content: code
    })

    this.trix.insertAttachment(attachment)
  }

  get codeEditor() {
    if(this.renderedCodeEditor) {
      return this.renderedCodeEditor
    }

    this.renderedCodeEditor = this.element.appendChild(codeEditorTemplate)
    return this.renderedCodeEditor
  }
}
