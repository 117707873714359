import React from 'react'
import { Formik } from 'formik'
import Panel from '../../Panel'
import RunValidationTrigger from './RunValidationTrigger'
import FieldContainer from '../../FieldContainer'

const EditCustomCode = ({ config, newBlock, onSave, onDelete, onCancel }) => (
  <Panel title="Custom Code" className="edit-custom-code-form">
    <Formik
      initialValues={config}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => onSave(values)}
      render={({
        values,
        errors,
        handleChange,
        validateForm,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <FieldContainer error={errors.code}>
            <label htmlFor="code">Code Snippet</label>
            <textarea
              name="code"
              data-testid="codeField"
              className="edit-custom-code-form__textarea"
              value={values.code}
              onChange={handleChange}
            />
          </FieldContainer>
          <div>
            <button
              type="submit"
              data-testid="saveButton"
              onClick={handleSubmit}
              className="bg-green__btn l-margin-right-sm"
            >
              Save Block
            </button>
            <button
              type="submit"
              onClick={onCancel}
              className="bg-gray__btn l-margin-right-sm"
            >
              Cancel
            </button>
            <button
              type="button"
              title="Delete Block"
              onClick={onDelete}
              className="page-editor-edit-form__delete-icon"
            >
              <i className="far fa-trash-alt" />
            </button>
          </div>
          {newBlock ? null : (
            <RunValidationTrigger validateForm={validateForm} />
          )}
        </form>
      )}
    />
  </Panel>
)

const validate = (values) => {
  const errors = {}
  if (!values.code) {
    errors.code = 'Code Snippet is required'
  }
  return errors
}

export default EditCustomCode
