import React from 'react'
import { Formik } from 'formik'
import { range } from 'lodash'
import geocode from './geocoder.js'
import Panel from '../../../Panel'
import RunValidationTrigger from '../RunValidationTrigger'
import FieldContainer from '../../../FieldContainer'

const EditMapBlock = ({ config, newBlock, onSave, onDelete, onCancel }) => {
  return (
    <Panel title="Map">
      <Formik
        initialValues={config}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, { setFieldError, setSubmitting }) =>
          attemptSubmit({ values, setFieldError, setSubmitting, onSave })
        }
        render={({
          values,
          errors,
          handleChange,
          validateForm,
          handleSubmit,
        }) => (
          <form>
            <FieldContainer error={errors.address}>
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                data-testid="addressField"
                value={values.address}
                onChange={handleChange}
              />
            </FieldContainer>
            <FieldContainer error={errors.label}>
              <label htmlFor="label">Label</label>
              <input
                type="text"
                id="label"
                data-testid="labelField"
                value={values.label}
                onChange={handleChange}
              />
            </FieldContainer>
            <div className="material-textfield">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                data-testid="phoneNumberField"
                value={values.phoneNumber}
                onChange={handleChange}
              />
            </div>
            <div className="material-textfield invisible-select">
              <label htmlFor="zoomLevel">Zoom Level</label>
              <select
                name="zoomLevel"
                data-testid="zoomLevel"
                value={values.zoomLevel}
                onChange={handleChange}
              >
                {zoomLevelOptions().map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <button
                type="submit"
                data-testid="saveButton"
                onClick={handleSubmit}
                className="bg-green__btn l-margin-right-sm"
              >
                Save Block
              </button>
              <button
                type="submit"
                onClick={onCancel}
                className="bg-gray__btn l-margin-right-sm"
              >
                Cancel
              </button>
              <button
                type="button"
                title="Delete Block"
                onClick={onDelete}
                className="page-editor-edit-form__delete-icon"
              >
                <i className="far fa-trash-alt" />
              </button>
            </div>
            {newBlock ? null : (
              <RunValidationTrigger validateForm={validateForm} />
            )}
          </form>
        )}
      />
    </Panel>
  )
}

const validate = (values) => {
  const errors = {}
  if (!values.address) {
    errors.address = 'Address is required'
  }
  if (!values.label) {
    errors.label = 'Label is required'
  }
  return errors
}

const attemptSubmit = ({ values, setFieldError, setSubmitting, onSave }) => {
  const { address } = values
  geocode({ address })
    .then((latLng) => {
      const processedValues = {
        ...values,
        zoomLevel: Number(values.zoomLevel),
        latLng,
      }
      onSave(processedValues)
    })
    .catch((error) => {
      setFieldError('address', `Error looking up address: ${error}`)
      setSubmitting(false)
    })
}

const zoomLevelOptions = () => {
  const lowestNumber = 1
  const highestNumber = 20
  return range(lowestNumber, highestNumber + 1)
}

export default EditMapBlock
