import React from 'react'
import { Formik } from 'formik'
import { get } from 'lodash'
import MediaField from '../../MediaChooser/MediaField'
import Panel from '../../Panel'
import RunValidationTrigger from './RunValidationTrigger'
import { clearExportData } from './utils'

const EditImageBlock = ({
  config,
  gridPosition,
  newBlock,
  onSave,
  onDelete,
  onCancel,
}) => (
  <Panel title="Image">
    <Formik
      initialValues={config}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => onSave(clearExportData(values))}
      render={({
        values,
        errors,
        setFieldValue,
        validateForm,
        handleSubmit,
      }) => (
        <form>
          <div>Image Details</div>
          <MediaField
            label="Image"
            mediaId={values.imageId}
            error={errors.imageId}
            exportedFileName={get(values, 'exportData.image')}
            onChange={(imageId) => setFieldValue('imageId', imageId)}
          />
          {!['quarter', 'grid'].includes(gridPosition) && (
            <MediaField
              label="Brand Logo"
              mediaId={values.brandLogoId}
              exportedFileName={get(values, 'exportData.brandLogo')}
              onChange={(brandLogoId) =>
                setFieldValue('brandLogoId', brandLogoId)
              }
            />
          )}
          <div>
            <button
              type="submit"
              data-testid="saveButton"
              onClick={handleSubmit}
              className="bg-green__btn l-margin-right-sm"
            >
              Save Block
            </button>
            <button
              type="submit"
              onClick={onCancel}
              className="bg-gray__btn l-margin-right-sm"
            >
              Cancel
            </button>
            <button
              type="button"
              title="Delete Block"
              onClick={onDelete}
              className="page-editor-edit-form__delete-icon"
            >
              <i className="far fa-trash-alt" />
            </button>
          </div>
          {newBlock ? null : (
            <RunValidationTrigger validateForm={validateForm} />
          )}
        </form>
      )}
    />
  </Panel>
)

const validate = (values) => {
  const errors = {}
  if (!values.imageId) {
    errors.imageId = 'Image is required'
  }
  return errors
}

export default EditImageBlock
