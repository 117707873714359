import api from '../api'
import { compact, get } from 'lodash'

export const STORE_MEDIA_PAGE = 'STORE_MEDIA_PAGE'
export const STORE_MEDIA_ITEMS = 'STORE_MEDIA_ITEMS'
export const STORE_MEDIA_TAGS = 'STORE_MEDIA_TAGS'

export const loadMedia = (args) => (dispatch, getState) => {
  const state = getState().media

  const mediaType = args.mediaType || state.mediaType

  const mediaTypeState = state[mediaType] || {}

  const numPerPage = args.numPerPage || mediaTypeState.numPerPage
  const pageNum = args.pageNum || mediaTypeState.currentPageNum || 1
  const searchText = get(args, 'searchText', mediaTypeState.searchText)
  const sortProperty = args.sortProperty || mediaTypeState.sortProperty
  const sortAscending = get(args, 'sortAscending', mediaTypeState.sortAscending)

  if (!mediaType) {
    throw new Error('media type must be specified for loadMedia')
  }

  const queryArgs = compact([
    `type=${mediaType}`,
    ...(pageNum && numPerPage ? [`page=${pageNum}`, `per=${numPerPage}`] : []),
    searchText && `q=${encodeURIComponent(searchText)}`,
    sortProperty && `sort=${sortProperty}`,
    typeof sortAscending !== 'undefined' &&
      `direction=${sortAscending ? 'asc' : 'desc'}`,
  ])
  const url = `/admin/media?${queryArgs.join('&')}`
  return api
    .get(url, { headers: { Accept: 'application/json' } })
    .then(({ data }) => {
      const { num_pages, page_media } = data
      dispatch({
        type: STORE_MEDIA_PAGE,
        mediaType,
        currentPageNum: pageNum,
        sortProperty,
        sortAscending,
        searchText,
        numPerPage,
        numPages: num_pages,
        pageMedia: page_media,
      })
    })
}

export const loadMediaItem = ({ mediaType, id }) => (dispatch) => {
  if (!mediaType) {
    throw new Error('media type must be specified for loadMediaItem')
  }

  const url = `/admin/media/${id}`
  return api
    .get(url, { headers: { Accept: 'application/json' } })
    .then(({ data: mediaItem }) => {
      dispatch({
        type: STORE_MEDIA_ITEMS,
        mediaType,
        mediaItems: [mediaItem],
      })
    })
}

export const updateMediaItem = ({ mediaType, mediaItem }) => (dispatch) => {
  const url = `/api/v1/media/${mediaItem.id}`
  return api
    .patch(
      url,
      { media: mediaItem },
      { headers: { Accept: 'application/json' } },
    )
    .then(({ data: mediaItem }) => {
      dispatch({
        type: STORE_MEDIA_ITEMS,
        mediaType,
        mediaItems: [mediaItem],
      })
    })
}

export const loadMediaTags = () => {
  return (dispatch) => {
    fetch("/api/v1/media/tags")
      .then(response => response.json())
      .then(json => {
        const lowerCaseTags = json.map(tag => tag.toLowerCase())
        const uniqueTags = [...new Set(lowerCaseTags)]
        dispatch({ type: STORE_MEDIA_TAGS, tags: uniqueTags })
      })
      .catch(error => {
        console.error("Unable to load media tags")
      })
  }
}
