import React from 'react'
import { componentOrNothing } from '../../../../../../services/componentOr'

const ErroredBlockView = (props) => (
  <div className="page-section__content-block content-block--error">
    <p>
      <b>{props.title}</b>
    </p>
    {componentOrNothing(props.message, <p>{props.message}</p>)}
  </div>
)

export default ErroredBlockView
