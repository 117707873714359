import { remove } from '../../../../../../utils'

export const clearExportData = (values) => remove(values, ['exportData'])

export const validateLocation = (location) => {
  let errors = {}

  if (!location || !location.type) {
    errors || (errors = {})
    errors.type = 'Type is required'
  }

  if (location.type === 'URL' && !location.url && !location.ctaRelativeUrl) {
    errors || (errors = {})
    errors.url = 'URL is required'
  }

  if (location.type === 'Page' && !location.pageId) {
    errors || (errors = {})
    errors.pageId = 'Page is required'
  }

  if (location.type === 'Page' && location.pageSectionId && !location.pageSectionId.match(/^(#[bs]-.+$)/)) {
    errors || (errors = {})
    errors.pageSectionId = 'Invalid jump link'
  }

  if(location.type === 'Jump Link' && (!location.jumpLink || !location.jumpLink.match(/^(#[bs]-.+$)/))) {
    errors || (errors = {})
    errors.jumpLink = "Invalid jump link"
  }

  return Object.keys(errors).length == 0 ? null : errors
}