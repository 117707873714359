import {
  ADD_FOOTER,
  TOGGLE_FOOTER_VISIBILITY,
  UPDATE_FOOTER,
  REMOVE_FOOTER,
} from './actions'

const initialData = {}

function footer(state = initialData, action) {
  switch (action.type) {
    // case STORE_PAGE:
    //   return action.page.footer || {}
    case ADD_FOOTER: {
      return { ...state, message: '', visible: true }
    }
    case TOGGLE_FOOTER_VISIBILITY: {
      const visible = typeof state.visible === 'undefined' || state.visible
      return { ...state, visible: !visible }
    }
    case UPDATE_FOOTER:
      return action.footer
    case REMOVE_FOOTER:
      return {}
    default:
      return state
  }
}

export default footer
