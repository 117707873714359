import React, { useState } from 'react'

const TagInput = (props) => {
  const { values, onChange } = props
  const options = props.options || []
  const [input, setInput] = useState("")
  const [selected, setSelected] = useState(undefined)
  const [focussed, setFocussed] = useState(false)

  const unselectedOptions = options.map(option => option.toLowerCase())
                                   .filter(option => !values.includes(option))
  const uniqueOptions = [... new Set(unselectedOptions)]
  const optionsToShow = uniqueOptions.filter(tag => tag.startsWith(input))

  const addTag = (value) => {
    if(values.includes(value)) {
      return
    }

    let updatedValues = [...values]
    updatedValues.push(value)
    onChange(updatedValues)
    setInput("")
    setSelected(undefined)
  }

  const removeTag = (value) => {
    let updatedValues = [...values]
    updatedValues.splice(updatedValues.indexOf(value), 1)
    onChange(updatedValues)
  }

  const handleKeyDown = (e) => {
    switch(e.key) {
      case "Enter":
      case "Tab":
      case ",":
      case " ": {
        e.preventDefault()
        if(!input) return
        selected ? addTag(selected) : addTag(input)
        break;
      }
      case "ArrowUp": {
        const selectedIndex = optionsToShow.indexOf(selected)
        if(selected) {
          selectedIndex == 0 ? setSelected(undefined) : setSelected(optionsToShow[selectedIndex - 1])
        }
        break;
      }
      case "ArrowDown": {
        const selectedIndex = optionsToShow.indexOf(selected)
        if(selected && selectedIndex < optionsToShow.length - 1) {
          setSelected(optionsToShow[selectedIndex + 1])
        }else{
          setSelected(optionsToShow[0])
        }
        break;
      }
      case "Escape": {
        e.preventDefault()
        setInput("")
        setSelected(undefined)
        break;
      }
    }
  }

  const handleChange = (e) => {
    const newInput = e.target.value.toLowerCase()
    setInput(newInput)
    if(selected && !selected.startsWith(newInput)) {
      setSelected(undefined)
    }
  }

  const handleFocus = (e) => {
    setFocussed(true)
  }

  const handleBlur = (e) => {
    setTimeout(() => {
      setFocussed(false)
    }, 100)
  }

  return (
    <div className="tag-input">
      <input
        type="text"
        className="tag-input__input"
        value={input}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      {
        input && focussed && (
          <TagOptions
            tags={optionsToShow}
            selected={selected}
            filter={input}
            onChoose={addTag}
            onSelect={setSelected}
          />
        )
      }

      <div className="tag-input__tags">
        {
          values.map(value => (
            <Tag key={value} value={value} onRemove={() => removeTag(value)} />
          ))
        }
      </div>
    </div>
  )
}

const TagOptions = (props) => {
  const { tags, filter, selected, onSelect, onChoose } = props

  const optionClassName = (option) => {
    let className = 'tag-input__option'
    if(option == selected) className += ' tag-input__option--selected'
    return className
  }

  return (
    <div className="tag-input__options">
      {
        tags.length > 0 && tags.map(option => (
          <div
            key={option}
            className={optionClassName(option)}
            onMouseOver={() => onSelect(option)}
            onClick={() => onChoose(option)}
          >
            {option}
          </div>
        ))
      }

      {
        filter && tags.length == 0 && (
          <div
            className="tag-input__option"
            onClick={() => onChoose(filter)}
          >
            New tag <strong>{filter}</strong>
          </div>
        )
      }
    </div>
  )
}

const Tag = (props) => {
  const { value, onRemove } = props

  return (
    <span className="tag-input__tag">
      <button type="button" onClick={onRemove}>&times;</button>
      {value}
    </span>
  )
}

export default TagInput
