export const withPermission = (
  permissions,
  key,
  callback = undefined,
  fallback = undefined,
) => {
  permissions = permissions || {}

  let hasPermission = (() => {
    if (!(key in permissions)) {
      if (!('all' in permissions)) {
        return false
      } else {
        return permissions['all']
      }
    } else {
      return !!permissions[key]
    }
  })()

  if (hasPermission && callback) {
    return callback()
  } else if (!hasPermission && fallback) {
    return fallback()
  } else {
    return hasPermission
  }
}

export const loadEventDetailsEvents = (pageId) => {
  return fetch(`/api/v1/pages/${pageId}/event_details_events`)
           .then(response => response.json())
}