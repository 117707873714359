import api from '../api'

export const STORE_ENVIRONMENTS = 'STORE_ENVIRONMENTS'

export const loadEnvironments = () => (dispatch) => {
  return api
    .get('/admin/environments', { headers: { Accept: 'application/json' } })
    .then(({ data: environments }) => {
      dispatch({
        type: STORE_ENVIRONMENTS,
        environments,
      })
    })
}
