import {
  ADD_SECTION,
  REMOVE_SECTION,
  DUPLICATE_SECTION,
  UPDATE_SECTION_SETTINGS,
  SWAP_COLUMNS,
  UPDATE_BLOCK,
  DELETE_BLOCK,
  SET_BLOCK_TYPE,
  TOGGLE_SECTION_VISIBILITY,
  TOGGLE_SECTION_LOCKED,
  MOVE_SECTION_UP,
  MOVE_SECTION_DOWN,
} from './actions'
import { newSectionFromLayout } from '../../../components/pageEditor/config/layouts'
import { BLOCK_TYPE_EMPTY, newBlockConfig } from '../../../components/pageEditor/config/blockTypes'
import { swap } from '../../../../utils'
import { sectionAllowsLink } from '../../../components/pageEditor/utils'
import generateUuid from 'uuid/v4'

const initialData = []

function sections(state = initialData, action) {
  switch (action.type) {
    case ADD_SECTION: {
      const { index, layout } = action
      const sections = [...state]
      const newSection = newSectionFromLayout(layout)
      sections.splice(index, 0, newSection)
      return sections
    }
    case REMOVE_SECTION:
      return state.filter((section) => section.uuid !== action.uuid)
    case DUPLICATE_SECTION: {
      const sections = [...state]
      const index = sections.findIndex(it => it.uuid == action.uuid)
      const section = sections[index]
      const duplicate = {
        ...section,
        uuid: generateUuid(),
        columns: section.columns.map(column => ({
          ...column,
          uuid: generateUuid(),
          blocks: column.blocks.map(block => ({
            ...block,
            uuid: generateUuid(),
            items: block.items?.map(item => ({
              ...item,
              uuid: generateUuid()
            }))
          }))
        }))
      }
      sections.splice(index, 0, duplicate)
      return sections
    }
    case UPDATE_SECTION_SETTINGS: {
      const { updatedSection } = action
      return state.map((section) => {
        if (section.uuid === updatedSection.uuid) {
          return {
            ...section,
            ...updatedSection,
          }
        }
        return section
      })
    }
    case SWAP_COLUMNS: {
      return state.map((section) => {
        if (section.uuid === action.uuid) {
          return {
            ...section,
            columns: section.columns.slice().reverse(),
          }
        }
        return section
      })
    }
    case UPDATE_BLOCK: {
      return newSectionsWithReplacedBlock({
        sections: state,
        newBlock: action.updatedBlock,
      })
    }
    case DELETE_BLOCK: {
      return newSectionsWithReplacedBlock({
        sections: state,
        newBlock: {
          uuid: action.uuid,
          type: BLOCK_TYPE_EMPTY
        },
      })
    }
    case SET_BLOCK_TYPE: {
      const { uuid, blockType } = action
      return clearInvalidSectionLinks(
        newSectionsWithReplacedBlock({
          sections: state,
          newBlock: {
            ...newBlockConfig(blockType),
            uuid,
          },
        }),
      )
    }
    case TOGGLE_SECTION_VISIBILITY: {
      return state.map((section) => {
        if (section.uuid === action.uuid) {
          const visible =
            typeof section.visible === 'undefined' || section.visible
          return {
            ...section,
            visible: !visible,
          }
        }
        return section
      })
    }
    case TOGGLE_SECTION_LOCKED: {
      return state.map((section) => {
        if (section.uuid === action.uuid) {
          const locked = action.locked === undefined ? !section.locked : action.locked
          return {
            ...section,
            locked: locked,
          }
        }
        return section
      })
    }
    case MOVE_SECTION_UP: {
      const sections = [...state]
      const i = sections.findIndex((section) => section.uuid === action.uuid)
      if (i > 0) {
        swap(sections, i, i - 1)
      }
      return sections
    }
    case MOVE_SECTION_DOWN: {
      const sections = [...state]
      const i = sections.findIndex((section) => section.uuid === action.uuid)
      if (i !== -1 && i < sections.length - 1) {
        swap(sections, i, i + 1)
      }
      return sections
    }
    default:
      return state
  }
}

const newSectionsWithReplacedBlock = ({ sections, newBlock }) =>
  sections.map((section) => ({
    ...section,
    columns: section.columns.map((column) => ({
      ...column,
      blocks: column.blocks.map((block) => {
        if (block.uuid === newBlock.uuid) {
          newBlock.height = block.height
          return newBlock
        }
        return block
      }),
    })),
  }))

const clearInvalidSectionLinks = (sections) => {
  return sections.map((section) => {
    const clearSectionLink = sectionAllowsLink(section)
      ? {}
      : {
          linkUrl: '',
          linkTitle: '',
          openInNewWindow: false,
        }
    return {
      ...section,
      ...clearSectionLink,
    }
  })
}

export default sections
