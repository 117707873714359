import { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { loadMedia } from '../../../../../store/media/actions'

class MediaLoader extends Component {
  componentDidMount() {
    const { mediaType } = this.props
    this.props.loadMedia({ mediaType })
  }

  render() {
    const { render, mediaType, media, ...otherProps } = this.props
    return render({
      ...otherProps,
      media: get(media, [mediaType, 'media'], []),
    })
  }
}

const mapStateToProps = (state) => {
  return { media: state.media }
}

const mapDispatchToProps = { loadMedia }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaLoader)
