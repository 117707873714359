import React from 'react'
import { Formik } from 'formik'
import Panel from '../../Panel'
import RunValidationTrigger from './RunValidationTrigger'
import FieldContainer from '../../FieldContainer'
import RichTextEditor from '../../../../../../shared/RichTextEditor'

const EditTextBlock = ({ config, newBlock, onSave, onDelete, onCancel }) => (
  <Panel title="Text">
    <Formik
      initialValues={config}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => onSave(values)}
      render={({
        values,
        errors,
        setFieldValue,
        validateForm,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <FieldContainer error={errors.text}>
            <div className="page_editor_wysiwyg">
              <RichTextEditor
                value={values.text || ''}
                onChange={content => setFieldValue('text', content)}
              />
            </div>
          </FieldContainer>
          <div className="l-margin-top-sm">
            <button
              type="submit"
              onClick={handleSubmit}
              className="bg-green__btn l-margin-right-sm"
            >
              Save Block
            </button>
            <button
              type="submit"
              onClick={onCancel}
              className="bg-gray__btn l-margin-right-sm"
            >
              Cancel
            </button>
            <button
              type="button"
              title="Delete Block"
              onClick={onDelete}
              className="page-editor-edit-form__delete-icon"
            >
              <i className="far fa-trash-alt" />
            </button>
          </div>
          {newBlock ? null : (
            <RunValidationTrigger validateForm={validateForm} />
          )}
        </form>
      )}
    />
  </Panel>
)

const handleWysiwygChange = (setFieldValue) => (event) =>
  setFieldValue('text', event.target.getContent())

const validate = (values) => {
  const errors = {}
  if (!values.text) {
    errors.text = 'Text is required'
  }
  return errors
}

export default EditTextBlock
