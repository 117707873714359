import { Component } from 'react'

export default class RunValidationTrigger extends Component {
  componentDidMount() {
    this.props.validateForm()
  }

  render() {
    return null
  }
}
