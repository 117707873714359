import React, { Component } from 'react'
import MediaMetadata from './MediaMetadata'

class SelectionSidebar extends Component {
  state = {
    isEditing: false,
  }

  handleChangeEditing = (isEditing) => this.setState({ isEditing })

  render() {
    const { mediaItem, mediaTags, onChoose } = this.props
    const { isEditing } = this.state

    const buttonColorClass = isEditing ? 'bg-disabled__btn' : 'bg-primary__btn'
    const buttonClasses = `${buttonColorClass} media-chooser__insert-selection-button lg-btn capitalize l-margin-right-sm`

    return (
      <div className="media-chooser__selection-column">
        <MediaMetadata
          mediaItem={mediaItem}
          mediaTags={mediaTags}
          isEditing={isEditing}
          onChangeEditing={this.handleChangeEditing}
        />
        <button
          type="submit"
          data-testid="insertButton"
          disabled={isEditing}
          onClick={onChoose}
          className={buttonClasses}
        >
          Insert Selection
        </button>
      </div>
    )
  }
}

export default SelectionSidebar
