import React, { useState, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { getGlobal } from '../symplexi'
import { tinymceReady } from '../admin/tinymce'

const RichTextEditor = (props) => {
  const {value, onChange} = props
  const [config, setConfig] = useState(undefined)

  useEffect(() => {
    if(!config) {
      tinymceReady.then(() => {
        setConfig(getGlobal("tinymceConfig"))
      })
    }
  }, [config])

  if(!config) {
    return (
      <div className="callout info">Configuring Rich Text Editor...</div>
    )
  }

  return (
    <Editor
      init={config}
      value={value}
      onEditorChange={onChange}
    />
  )
}

export default RichTextEditor