import React from 'react'
import { range } from 'lodash'

const PaginationControls = ({
  numPages,
  currentPageNum,
  goToPrevious,
  goToNext,
  goToPage,
}) => {
  if (currentPageNum < 1 || numPages < 1) return null;
  if (numPages == 1) return renderPageLink({ pageNum: 1, currentPageNum: 1, goToPage });

  return (
    <div className="admin-custom-kaminari">
      <nav className="pagination" role="navigation" aria-label="pager">
        {currentPageNum !== 1 && renderPreviousPageLinks({ goToPrevious, goToPage })}
        {(currentPageNum > 6) ? ellipsis() : renderPageLink({ pageNum: 1, currentPageNum, goToPage }) }
        {renderPagingLinks({ numPages, currentPageNum, goToPage })}
        {(currentPageNum < (numPages - 5)) ? ellipsis() : renderPageLink({ pageNum: numPages, currentPageNum, goToPage }) }
        {currentPageNum !== numPages && renderNextPageLinks({ numPages, goToNext, goToPage })}
      </nav>
    </div>
  )
}

const ellipsis = () => (<span class="page gap primary-text">…</span>)

const renderPreviousPageLinks = ({ goToPrevious, goToPage }) => (
  <>
  <span className="first">
    <a href="#" className="primary-link" onClick={() => goToPage(1)}>
      « First
    </a>
  </span>
  <span className="prev">
    <a href="#" className="primary-link" onClick={goToPrevious}>
      ‹ Prev
    </a>
  </span>
  </>
)

const renderNextPageLinks = ({ numPages, goToNext, goToPage }) => (
  <>
  <span className="next">
    <a href="#" data-testid="nextPageButton" className="primary-link" onClick={goToNext}>
      Next ›
    </a>
  </span>
  <span className="last">
    <a href="#" className="primary-link" onClick={() => goToPage(numPages)}>
      Last »
    </a>
  </span>
  </>
)

const renderPagingLinks = ({ numPages, currentPageNum, goToPage }) => {
  var startPage = Math.max(2, currentPageNum - 4),
      endPage = Math.min(numPages, currentPageNum + 5);

  return (
    <>
    {range(startPage, endPage).map((pageNum) => renderPageLink({ pageNum, currentPageNum, goToPage }))}
    </>
  )
}

const renderPageLink = ({ pageNum, currentPageNum, goToPage }) => (
  <span className="fa-stack fa-md" key={pageNum}>
    {(pageNum === currentPageNum ? activePageLink : inactivePageLink)({ pageNum, goToPage })}
  </span>
)

const activePageLink = ({ pageNum, goToPage }) => (
  <a href="#" onClick={() => goToPage(pageNum)}>
    <i className="fa fa-circle fa-stack-2x paginate-bg" />
    <i className="fa fa-circle fa-stack-2x primary-text" />
    <span className="pagination-number pos-center primary-font text-light">
      {pageNum}
    </span>
  </a>
)

const inactivePageLink = ({ pageNum, goToPage }) => (
  <a href="#" onClick={() => goToPage(pageNum)}>
    <i className="fa fa-circle fa-stack-2x paginate-bg" />
    <i className="fa fa-circle fa-stack-2x primary-text primary-text-mix" />
    <span className="pagination-number pos-center primary-font primary-text">
      {pageNum}
    </span>
  </a>
)

export default PaginationControls
