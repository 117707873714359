import React, { Component } from 'react'
import Grid from './Grid'
import List from './List'
import Panel from '../Panel'
import MediaUploadBox from '../../../../../mediaUploader/MediaUploadBox'
import SelectionSidebar from './SelectionSidebar'
import { find } from 'lodash'
import { connect } from 'react-redux'
import { loadMedia } from '../../../../store/media/actions'

const LAYOUT = {
  GRID: {
    key: 'GRID',
    title: 'Grid',
    iconClass: 'fas fa-th-large',
    buttonTestId: 'viewAsGridButton',
    component: Grid,
  },
  LIST: {
    key: 'LIST',
    title: 'List',
    iconClass: 'fas fa-list',
    buttonTestId: 'viewAsListButton',
    component: List,
  },
}

export class MediaChooser extends Component {
  state = {
    selectedLayout: LAYOUT.GRID.key,
    selectedMediaId: this.props.currentMediaId,
    searchText: '',
  }

  componentDidUpdate(prevProps) {
    const { searchText } = this.props

    if (searchText !== prevProps.searchText) {
      this.setState({ searchText })
    }
  }

  handleSelectMedia = (selectedMediaId) => this.setState({ selectedMediaId })

  handleSelectLayout = (selectedLayout) => this.setState({ selectedLayout })

  handleChangeSearchText = (event) =>
    this.setState({ searchText: event.target.value })

  handleKeyPress = (event) => {
    if (event.key !== 'Enter') {
      return
    }

    const { searchText } = this.state
    const { loadMedia } = this.props

    loadMedia({ searchText, pageNum: 1 })
  }

  handleChoose = () => {
    const { media, onChoose } = this.props
    const { selectedMediaId } = this.state

    const mediaItem = media.find(
      (mediaItem) => mediaItem.id === selectedMediaId,
    )

    onChoose({
      mediaId: selectedMediaId,
      filename: mediaItem.filename,
      imageUrl: mediaItem.url,
      publicId: mediaItem.public_id
    })
  }

  handleUpload = () => {
    const { loadMedia } = this.props
    const searchText = ''
    this.setState({ searchText })
    loadMedia({
      searchText,
      pageNum: 1,
      sortProperty: 'updated_at',
      sortAscending: false,
    })
  }

  render() {
    const { media, mediaTags, onCancel } = this.props
    const { selectedMediaId } = this.state

    const mediaItem = media.find(
      (mediaItem) => mediaItem.id === selectedMediaId,
    )

    return (
      <Panel
        title="Insert Media"
        className="media-chooser"
        constrainWidth={false}
        onCancel={onCancel}
      >
        <div className="media-chooser__layout">
          {this.renderChooserPanel()}
          <SelectionSidebar
            mediaItem={mediaItem}
            mediaTags={mediaTags}
            onChoose={this.handleChoose}
          />
        </div>
      </Panel>
    )
  }

  renderChooserPanel() {
    const { media, pageIds } = this.props
    const { selectedLayout, selectedMediaId, searchText } = this.state

    const layoutConfig = LAYOUT[selectedLayout]
    const Layout = layoutConfig.component

    const pageMedia = pageIds.map((id) => find(media, { id }))

    const filteredMedia = search({ media: pageMedia, searchText })

    return (
      <div className="media-chooser__grid-list-column">
        <MediaUploadBox
          userName="placeholder_username"
          onUpload={this.handleUpload}
        />
        {this.renderChooserControls()}
        <Layout
          media={filteredMedia}
          selectedMediaId={selectedMediaId}
          onSelect={this.handleSelectMedia}
        />
      </div>
    )
  }

  renderChooserControls() {
    const layouts = Object.keys(LAYOUT)
    const { selectedLayout, searchText } = this.state

    return (
      <div className="media-chooser__controls l-margin-bottom-lg">
        {layouts.map((key) => {
          const layout = LAYOUT[key]
          const isSelected = key === selectedLayout
          const className = `media-chooser__layout-button l-margin-right-sm ${
            isSelected ? 'media-chooser__layout-button--selected' : ''
          }`

          return (
            <button
              type="button"
              key={key}
              data-testid={layout.buttonTestId}
              title={layout.title}
              onClick={() => this.handleSelectLayout(key)}
              className={className}
            >
              <i className={layout.iconClass} />
            </button>
          )
        })}
        <input
          type="text"
          data-testid="searchInput"
          placeholder="Search Media"
          value={searchText}
          onChange={this.handleChangeSearchText}
          onKeyPress={this.handleKeyPress}
          className="media-chooser__search-field"
        />
      </div>
    )
  }
}

const search = ({ media, searchText }) =>
  media.filter(({ filename, alt_text, tags, last_activity_user }) =>
    [
      caseInsensitiveMatch({ searchText, searchIn: filename }),
      caseInsensitiveMatch({ searchText, searchIn: alt_text }),
      caseInsensitiveMatch({ searchText, searchIn: last_activity_user }),
      (tags || []).some((tag) =>
        caseInsensitiveMatch({ searchText, searchIn: tag }),
      ),
    ].some((match) => match === true),
  )

const caseInsensitiveMatch = ({ searchText, searchIn }) =>
  (searchIn || '').toLowerCase().includes((searchText || '').toLowerCase())

const mapStateToProps = (state) => {
  const { totalPages, pageNum, searchText, pageIds = [], media = [] } = state.media.image || {}
  const mediaTags = state.mediaTags
  return { totalPages, pageNum, searchText, pageIds, media, mediaTags }
}

const mapDispatchToProps = { loadMedia }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaChooser)
