import React from 'react'
import { Formik, FieldArray } from 'formik'
import MediaLoader from './MediaLoader'
import Panel from '../../Panel'
import RunValidationTrigger from './RunValidationTrigger'
import FieldContainer from '../../FieldContainer'
import { blockTypeConfig } from '../../../config/blockTypes'

export const SOURCE_TYPE = {
  LINK: {
    key: 'LINK',
    label: 'Link',
  },
  MEDIA: {
    key: 'MEDIA',
    label: 'Media Library',
  },
}

const EditAudioPlayerBlock = (props) => (
  <MediaLoader
    {...props}
    mediaType="audio"
    render={({ config, newBlock, onSave, onDelete, onCancel, media }) => (
      <Panel title="Audio Player">
        <Formik
          initialValues={{
            ...config,
            tracks:
              config.tracks.length === 0 ? [createNewTrack()] : config.tracks,
          }}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values) => {
            const formattedValues = {
              ...values,
              tracks: values.tracks.map((track) => ({
                ...track,
                minutes: Number(track.minutes),
                seconds: Number(track.seconds),
              })),
            }
            onSave(formattedValues)
          }}
          render={({
            values,
            errors,
            setFieldValue,
            validateForm,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <FieldArray
                name="tracks"
                validateOnChange={false}
                render={(arrayHelpers) => (
                  <div>
                    {values.tracks.map((track, index) =>
                      renderTrack({
                        track,
                        index,
                        values,
                        errors,
                        media,
                        arrayHelpers,
                        setFieldValue,
                      }),
                    )}
                    <div>
                      <button
                        title="Add Another"
                        type="button"
                        data-testid="addButton"
                        className="interstitial__btn"
                        onClick={() => arrayHelpers.push(createNewTrack())}
                      >
                        + Add Another
                      </button>
                    </div>
                  </div>
                )}
              />
              <div>
                <button
                  type="submit"
                  data-testid="saveButton"
                  onClick={handleSubmit}
                  className="bg-green__btn l-margin-right-sm"
                >
                  Save Block
                </button>
                <button
                  type="submit"
                  onClick={onCancel}
                  className="bg-gray__btn l-margin-right-sm"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  title="Delete Block"
                  onClick={onDelete}
                  className="page-editor-edit-form__delete-icon"
                >
                  <i className="far fa-trash-alt" />
                </button>
              </div>
              {newBlock ? null : (
                <RunValidationTrigger validateForm={validateForm} />
              )}
            </form>
          )}
        />
      </Panel>
    )}
  />
)

const renderTrack = ({
  track,
  index,
  values,
  errors,
  media,
  arrayHelpers,
  setFieldValue,
}) => (
  <div key={track.uuid}>
    <div className="edit-audio-player-form__header">
      <div>Audio Track</div>
      <div>
        {values.tracks.length > 1 && (
          <button
            type="button"
            data-testid={`delete-${track.uuid}`}
            title="Delete"
            onClick={() => arrayHelpers.remove(index)}
          >
            <i className="far fa-trash-alt" />
          </button>
        )}
      </div>
    </div>
    <FieldContainer
      error={errors[`tracks.${index}.sourceType`]}
      fieldClasses="invisible-select"
    >
      <label htmlFor="sourceType">Source</label>
      <select
        id="sourceType"
        value={track.sourceType || ''}
        onChange={(event) =>
          handleSourceChange({
            event,
            setFieldValue,
            index,
          })
        }
      >
        <option value="">(choose a source type)</option>
        {Object.keys(SOURCE_TYPE).map((sourceTypeKey) => (
          <option key={sourceTypeKey} value={sourceTypeKey}>
            {SOURCE_TYPE[sourceTypeKey].label}
          </option>
        ))}
      </select>
    </FieldContainer>
    {renderSourceInput({
      index,
      sourceType: track.sourceType,
      url: track.url,
      mediaId: track.mediaId,
      media,
      errors,
      setFieldValue,
    })}
    <FieldContainer error={errors[`tracks.${index}.name`]}>
      <label htmlFor="name">Track Name</label>
      <input
        type="text"
        id="name"
        value={track.name || ''}
        onChange={(event) =>
          setFieldValue(`tracks.${index}.name`, event.target.value)
        }
      />
    </FieldContainer>
    <FieldContainer error={errors[`tracks.${index}.seconds`]}>
      <label>Length</label>
      <div>
        <input
          type="text"
          value={track.minutes}
          placeholder="m"
          className="edit-audio-player-form__time-field"
          onChange={(event) =>
            setFieldValue(`tracks.${index}.minutes`, event.target.value)
          }
        />
        :
        <input
          type="text"
          value={formatSeconds(track.seconds)}
          placeholder="ss"
          className="edit-audio-player-form__time-field"
          onChange={(event) =>
            setFieldValue(`tracks.${index}.seconds`, event.target.value)
          }
        />
      </div>
    </FieldContainer>
  </div>
)

const renderSourceInput = ({
  index,
  sourceType,
  url,
  mediaId,
  media,
  errors,
  setFieldValue,
}) => {
  const sourceTypeConfig = SOURCE_TYPE[sourceType]
  if (!sourceTypeConfig) {
    return null
  }

  switch (sourceType) {
    case SOURCE_TYPE.LINK.key: {
      return (
        <FieldContainer error={errors[`tracks.${index}.url`]}>
          <label htmlFor="url">Link</label>
          <input
            type="text"
            id="url"
            placeholder="https://www.example.com/01-song.mp3"
            value={url || ''}
            onChange={(event) =>
              setFieldValue(`tracks.${index}.url`, event.target.value)
            }
          />
        </FieldContainer>
      )
    }
    case SOURCE_TYPE.MEDIA.key: {
      const mediaToInclude = media.filter(
        (media) => !media.archived || Number(mediaId) === media.id,
      )

      const selectedMedia = media.find(
        (testMedia) => Number(mediaId) === testMedia.id,
      )

      let errorToShow = errors[`tracks.${index}.mediaId`]
      if (
        !errorToShow &&
        (selectedMedia !== undefined && selectedMedia.status !== 'published')
      ) {
        errorToShow = `Media is ${selectedMedia.status}`
      }

      return (
        <FieldContainer error={errorToShow} fieldClasses="invisible-select">
          <label htmlFor="mediaId">Media</label>
          <select
            id="mediaId"
            value={mediaId || ''}
            onChange={(event) => {
              setFieldValue(`tracks.${index}.mediaId`, event.target.value)
            }}
          >
            <option value="">(select an audio file)</option>
            {mediaToInclude.map((mediaItem) => (
              <option key={mediaItem.id} value={mediaItem.id}>
                {mediaItem.filename}
                {mediaItem.archived ? ' (archived)' : ''}
              </option>
            ))}
          </select>
        </FieldContainer>
      )
    }
    default: {
      return null
    }
  }
}

const createNewTrack = () =>
  blockTypeConfig('audioPlayer').defaultConfig().tracks[0]

const handleSourceChange = ({ event, setFieldValue, index }) => {
  const sourceType = event.target.value
  setFieldValue(`tracks.${index}.sourceType`, sourceType)
  setFieldValue('url', null)
  setFieldValue('mediaId', null)
}

const validate = (values) => {
  const errors = {}
  values.tracks.forEach((track, index) => {
    const oneBasedIndex = index + 1
    switch (track.sourceType) {
      case SOURCE_TYPE.LINK.key: {
        if (!track.url) {
          errors[
            `tracks.${index}.url`
          ] = `Link for track ${oneBasedIndex} is required`
        }
        break
      }
      case SOURCE_TYPE.MEDIA.key: {
        if (!track.mediaId) {
          errors[
            `tracks.${index}.mediaId`
          ] = `Media for track ${oneBasedIndex} is required`
        }
        break
      }
      default: {
        errors[
          `tracks.${index}.sourceType`
        ] = `Source type for track ${oneBasedIndex} is required`
        break
      }
    }

    if (!track.name) {
      errors[
        `tracks.${index}.name`
      ] = `Track Name for track ${oneBasedIndex} is required`
    }

    const onlyDigits = /^\d+$/

    if (!track.seconds && !track.minutes) {
      // minutes is optional on the off-chance it's less than a minute
      errors[
        `tracks.${index}.seconds`
      ] = `Length for track ${oneBasedIndex} is required`
    } else if (!onlyDigits.test(track.seconds)) {
      errors[
        `tracks.${index}.seconds`
      ] = `Seconds for track ${oneBasedIndex} is not a positive whole number`
    } else if (track.seconds < 0 || track.seconds >= 60) {
      errors[
        `tracks.${index}.seconds`
      ] = `Seconds for track ${oneBasedIndex} is not between 0 and 59`
    }

    if (track.minutes && !onlyDigits.test(track.minutes)) {
      errors[
        `tracks.${index}.seconds`
      ] = `Minutes for track ${oneBasedIndex} is not a number`
    }
  })
  return errors
}

const formatSeconds = (seconds) => {
  if (seconds === '') {
    return seconds
  }

  const secondsNum = Number(seconds)
  return secondsNum < 10 ? `0${secondsNum}` : `${secondsNum}`
}

export default EditAudioPlayerBlock
