import React from 'react'

const Panel = ({
  title,
  children,
  onCancel,
  cancelText = 'Cancel',
  className = '',
  constrainWidth = true,
}) => {
  const classes = ['admin-form-card--dbl-col']
  if (className) {
    classes.push(className)
  }
  if (constrainWidth) {
    classes.push('page-editor-modal')
  }

  return (
    <section className={classes.join(' ')}>
      <div className="section-header section-header--flex">
        <div>{title}</div>
        {onCancel && (
          <div>
            <button className="section-header-button" onClick={onCancel}>
              <i className="fas fa-times l-margin-right-sm" />
              {cancelText}
            </button>
          </div>
        )}
      </div>
      <div className="section-body">{children}</div>
    </section>
  )
}

export default Panel
