import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik } from 'formik'
import { range } from 'lodash'
import Panel from '../../Panel'
import FieldContainer from '../../FieldContainer'
import { loadFeeds } from '../../../../../store/feeds/actions'

const EditFeedSummaryBlock = (props) => {
  const { config, gridPosition, onSave, onDelete, onCancel } = props
  const dispatch = useDispatch()
  const feeds = useSelector(state => state.feeds)

  useEffect(() => {
    dispatch(loadFeeds())
  }, [])

  const displayStyleOptions = () => {
    let values = [{value: "", display: ""}, {value: "carousel", display: "Carousel"}]

    if(gridPosition == "full" || gridPosition == "left" || gridPosition == "right") {
      values.push({value: "list", display: "List"})
    }

    return values
  }

  return (
    <Panel title="Feed Summary">
      <Formik
        initialValues={config}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSave}
        render={({
          values,
          errors,
          handleChange,
          setFieldValue,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <FieldContainer
              containerClasses="flex-1"
              fieldClasses="invisible-select"
              error={errors.feedId}
            >
              <label>Feed</label>
              <select
                value={values.feedId}
                onChange={(event) => setFieldValue('feedId', event.target.value)}
                className="select2-admin"
              >
                <option value=""></option>
                {
                  feeds.map(feed => (
                    <option key={feed.id} value={feed.id}>{feed.title}</option>
                  ))
                }
              </select>
            </FieldContainer>

            <FieldContainer
              containerClasses="flex-1"
              fieldClasses="invisible-select"
              error={errors.style}
            >
              <label>Display Style</label>
              <select
                value={values.style}
                onChange={(event) => setFieldValue('style', event.target.value)}
                className="select2-admin"
              >
                {
                  displayStyleOptions().map(option => (
                    <option key={option.value} value={option.value}>{option.display}</option>
                  ))
                }
              </select>
            </FieldContainer>

            <FieldContainer error={errors.maxEntries}>
              <label># of Entries</label>
              <input
                type="number"
                value={values.maxEntries || 12}
                onChange={e => setFieldValue('maxEntries', e.target.value)}
              />
            </FieldContainer>

            <FieldContainer error={errors.title}>
              <label>Title (Optional)</label>
              <input
                type="text"
                value={values.title || ""}
                placeholder="Optional Title"
                onChange={e => setFieldValue('title', e.target.value)}
              />
            </FieldContainer>

            <div className="df-row margin-bottom-1">
              <label className="container-check-box">
                <input
                  type="checkbox"
                  checked={values.showViewAll}
                  onChange={() => setFieldValue('showViewAll', !values.showViewAll)}
                />
                <span className="checkmark-small" />
                <span>Show "View All" link</span>
              </label>
            </div>

            <div className="df-row margin-bottom-1">
              <label className="container-check-box">
                <input
                  type="checkbox"
                  checked={values.hideCategories}
                  onChange={() => setFieldValue('hideCategories', !values.hideCategories)}
                />
                <span className="checkmark-small" />
                <span>Hide categories</span>
              </label>
            </div>

            <div className="l-margin-top-sm">
              <button
                type="submit"
                data-testid="saveButton"
                onClick={handleSubmit}
                className="bg-green__btn l-margin-right-sm"
              >
                Save Block
              </button>
              <button
                type="submit"
                onClick={onCancel}
                className="bg-gray__btn l-margin-right-sm"
              >
                Cancel
              </button>
              <button
                type="button"
                title="Delete Block"
                onClick={onDelete}
                className="page-editor-edit-form__delete-icon"
              >
                <i className="far fa-trash-alt" />
              </button>
            </div>
          </form>
        )}
      />
    </Panel>
  )
}

const validate = (values) => {
  const errors = {}

  if (!values.feedId) {
    errors.feedId = 'Feed is required'
  }

  if(!values.maxEntries || values.maxEntries <= 0) {
    errors.maxEntries = 'Max entries must be greater than 0'
  }

  return errors
}

export default EditFeedSummaryBlock
