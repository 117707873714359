import React from 'react'

const FieldContainer = ({
  error,
  containerClasses = '',
  fieldClasses = '',
  testId = '',
  children,
}) => (
  <div className={`material-textfield-container ${containerClasses}`}>
    <div
      className={`material-textfield material-textfield--in-container ${fieldClasses} ${
        error ? ' material-textfield--error' : ''
      }`}
    >
      {children}
    </div>
    {error ? (
      <div
        className="material-textfield__error"
        data-testid={`field-error-${testId}`}
      >
        {error}
      </div>
    ) : null}
  </div>
)

export default FieldContainer
