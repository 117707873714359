import { STORE_PAGES } from './actions'

const initialData = []

function pages(state = initialData, action) {
  switch (action.type) {
    case STORE_PAGES:
      return action.pages
    default:
      return state
  }
}

export default pages
