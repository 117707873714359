import { STORE_FEEDS } from './actions'

function feeds(state = [], action) {
  switch (action.type) {
    case STORE_FEEDS: {
      return action.feeds
    }

    default: {
      return state
    }
  }
}

export default feeds
