import React from 'react'
import Dropzone from 'react-dropzone'
import { MediaUploader } from './MediaUploader'
import { Spinner } from './Spinner'
import { componentOr } from '../services/componentOr'

const componentOrSpinner = componentOr(<Spinner />)

export default class MediaUploadBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  uploadFile = (file) => {
    const uploader = new MediaUploader(file)
    return uploader.call()
  }

  handleDrop = (acceptedFiles) => {
    this.setState({ loading: true })

    acceptedFiles
      .map((file) => this.uploadFile(file))
      .reduce(resolvePromisesSerially, Promise.resolve([]))
      .then((responses) => {
        const mediaItems = responses.map((response) => response.data)
        // eslint-disable-next-line no-console
        console.log('Uploading Complete.')
        this.props.onUpload(mediaItems)
      })
      .catch((error) => {
        let message
        if (error === 'File is not a valid file format') {
          message =
            'This file is not an allowed file format. Please upload only images, documents, and MP3 audio files.'
        } else {
          message = `An error occured while attempting to upload the file: ${error}`
        }
        alert(message)
      })
      .finally(() => this.setState({ loading: false }))
  }

  render() {
    return (
      <div>
        <Dropzone
          onDrop={this.handleDrop}
          className="dropbox"
          activeClassName="dropbox dropbox--active"
        >
          <div>
            {componentOrSpinner(
              !this.state.loading,
              <h6>
                Drop files here
                <br />
                or
                <br />
                <button className="bg-primary__btn">Select Files</button>
              </h6>,
            )}
          </div>
        </Dropzone>
      </div>
    )
  }
}

// see https://decembersoft.com/posts/promises-in-serial-with-array-reduce/
const resolvePromisesSerially = (promiseChain, currentPromise) =>
  promiseChain.then((chainResults) =>
    currentPromise.then((currentResult) => [...chainResults, currentResult]),
  )
