import React from 'react'
import { Formik } from 'formik'
import { get } from 'lodash'
import MediaField from '../../../MediaChooser/MediaField'
import Panel from '../../../Panel'
import EventSelectInput from '../../../SelectInput/Event'
import PageSelectInput from '../../../SelectInput/Page'
import MessageSelectInput from '../../../SelectInput/Message'
import MessageSeriesSelectInput from '../../../SelectInput/MessageSeries'
import CardableLoader from './CardableLoader'
import MostRecentMessageLoader from './MostRecentMessageLoader'
import LocationInput from '../../../LocationInput'
import RunValidationTrigger from '../RunValidationTrigger'
import FieldContainer from '../../../FieldContainer'
import { clearExportData, validateLocation } from '../utils'

export const CARD_TYPE = {
  PAGE: {
    key: 'PAGE',
    label: 'Page',
    requiresCardableId: true,
  },
  EVENT_OCCURRENCE: {
    key: 'EVENT_OCCURRENCE',
    label: 'Event',
    requiresCardableId: true,
  },
  MESSAGE: {
    key: 'MESSAGE',
    label: 'Message',
    requiresCardableId: true,
  },
  MOST_RECENT_MESSAGE: {
    key: 'MOST_RECENT_MESSAGE',
    label: 'Most Recent Message',
  },
  MESSAGE_SERIES: {
    key: 'MESSAGE_SERIES',
    label: 'Message Series',
    requiresCardableId: true,
  },
  DIRECT_LINK: {
    key: 'DIRECT_LINK',
    label: 'Direct Link',
    requiresCardableId: false,
    requiresFeaturedImage: true,
    requiresTitles: true,
    promptForCardLink: true,
  },
}

export const FOOTER_TYPE = {
  gray: {
    key: 'gray',
    label: 'Gray',
  },
  shadow: {
    key: 'shadow',
    label: 'Shadow',
  },
  transparent: {
    key: 'transparent',
    label: 'Transparent',
  },
}

export const TEXT_TYPE = {
  dark: {
    key: 'dark',
    label: 'Dark',
  },
  light: {
    key: 'light',
    label: 'Light',
  },
}

const EditCardForm = ({ config, newBlock, onSave, onDelete, onCancel }) => (
  <Panel title="Card">
    <Formik
      initialValues={config}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => onSave(clearExportData(values))}
      render={({
        values,
        errors,
        handleChange,
        setFieldValue,
        validateForm,
        handleSubmit,
      }) => {
        return <CardableLoader
          cardType={values.cardType}
          cardableId={values.cardableId}
          render={({ cardable }) => (
            <form onSubmit={handleSubmit}>
              <p>Card Details</p>
              <FieldContainer
                error={errors.cardType}
                fieldClasses="invisible-select page-editor-select2-container"
              >
                <label>Card Type</label>
                <select
                  name="cardType"
                  value={values.cardType || ''}
                  onChange={(event) =>
                    handleCardTypeChange({ event, setFieldValue })
                  }
                >
                  <option value="">(choose a card type)</option>
                  {Object.keys(CARD_TYPE).map((cardTypeKey) => (
                    <option key={cardTypeKey} value={cardTypeKey}>
                      {CARD_TYPE[cardTypeKey].label}
                    </option>
                  ))}
                </select>
              </FieldContainer>
              {renderCardableSelect({
                cardType: values.cardType,
                cardableId: values.cardableId,
                exportData: values.exportData || {},
                errors,
                setFieldValue,
              })}
              {renderIf(
                (CARD_TYPE[values.cardType] || {}).promptForCardLink,
                <React.Fragment>
                  <FieldContainer
                    error={errors.cardUrl}
                    fieldClasses="invisible-select page-editor-select2-container"
                  >
                    <label>Card Link</label>
                    <input
                      type="text"
                      id="cardUrl"
                      placeholder="https://www.example.com"
                      value={values.cardUrl || ''}
                      onChange={handleChange}
                    />
                  </FieldContainer>
                  <div
                    className="df-row margin-bottom-1"
                    onClick={() =>
                      setFieldValue('cardNewWindow', !values.cardNewWindow)
                    }
                  >
                    <label className="container-check-box">
                      <input
                        type="checkbox"
                        name="cardNewWindow"
                        checked={values.cardNewWindow || false}
                        onClick={(event) => event.stopPropagation()}
                        onChange={() => {
                          /* noop */
                        }}
                      />
                      <span className="checkmark-small" />
                      <span>Open in New Tab</span>
                    </label>
                  </div>
                </React.Fragment>,
              )}
              <p>Custom Card Options</p>
              <MediaField
                label="Featured Image"
                mediaId={values.featuredImageId}
                error={errors.featuredImageId}
                exportedFileName={get(values, 'exportData.featuredImage')}
                onChange={(featuredImageId) =>
                  setFieldValue('featuredImageId', featuredImageId)
                }
              />
              <FieldContainer error={errors.title}>
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  id="title"
                  placeholder={cardable.card_title}
                  value={values.title}
                  onChange={handleChange}
                />
              </FieldContainer>
              <FieldContainer error={errors.subtitle}>
                <label htmlFor="subtitle">Subtitle</label>
                <input
                  type="text"
                  id="subtitle"
                  placeholder={cardable.card_subtitle}
                  value={values.subtitle}
                  onChange={handleChange}
                />
              </FieldContainer>
              <div
                className="df-row"
                onClick={() =>
                  setFieldValue('hideBrandIcon', !values.hideBrandIcon)
                }
              >
                <label className="container-check-box margin-bottom-0">
                  <input
                    type="checkbox"
                    name="hideBrandIcon"
                    checked={values.hideBrandIcon}
                    onClick={(event) => event.stopPropagation()}
                    onChange={() => {
                      /* noop */
                    }}
                  />
                  <span className="checkmark-small" />
                  <span>Hide Brand Icon</span>
                </label>
              </div>
              <MediaField
                label="Brand Icon"
                mediaId={values.brandIconId}
                exportedFileName={get(values, 'exportData.brandIcon')}
                defaultFilename={cardable.brand_icon_filename}
                defaultImageUrl={cardable.brand_icon_url}
                onChange={(brandIconId) =>
                  setFieldValue('brandIconId', brandIconId)
                }
              />
              <div
                className="df-row"
                onClick={() =>
                  setFieldValue('hideCtaButton', !values.hideCtaButton)
                }
              >
                <label className="container-check-box margin-bottom-0">
                  <input
                    type="checkbox"
                    name="hideCtaButton"
                    checked={values.hideCtaButton}
                    onClick={(event) => event.stopPropagation()}
                    onChange={() => {
                      /* noop */
                    }}
                  />
                  <span className="checkmark-small" />
                  <span>Hide Button</span>
                </label>
              </div>
              <div className="df-row df-md-wrap df-row-justify-between form-arrow-centered">
                <div className="small-12 large-5-half columns padding-left-0">
                  <FieldContainer error={errors.ctaButtonTitle}>
                    <label htmlFor="ctaButtonTitle">Button Title</label>
                    <input
                      type="text"
                      id="ctaButtonTitle"
                      placeholder={cardable.cta_button_title}
                      value={values.ctaButtonTitle}
                      onChange={handleChange}
                      maxLength="8"
                    />
                  </FieldContainer>
                </div>
                <div className="small-12 large-5-half columns padding-right-0">
                  <LocationInput
                    locationValues={
                      values.ctaLocation || {
                        type: values.ctaRelativeUrl ? 'URL' : null,
                        url: values.ctaRelativeUrl || null,
                      }
                    }
                    errors={errors.ctaLocation}
                    setLocationFieldValue={(field, value) =>
                      setFieldValue(`ctaLocation.${field}`, value)
                    }
                    urlPlaceholder={cardable.cta_relative_url}
                    exportedPage={values.exportData ? values.exportData['ctaLocation.pageId'] : null}
                  />
                  {values.ctaLocation && values.ctaLocation.type != "Jump Link" && (
                    <div
                      className="df-row margin-bottom-1"
                      onClick={() =>
                        setFieldValue('ctaNewWindow', !values.ctaNewWindow)
                      }
                    >
                      <label className="container-check-box">
                        <input
                          type="checkbox"
                          name="ctaNewWindow"
                          checked={values.ctaNewWindow || false}
                          onClick={(event) => event.stopPropagation()}
                          onChange={() => {
                            /* noop */
                          }}
                        />
                        <span className="checkmark-small" />
                        <span>Open in New Tab</span>
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className="material-textfield invisible-select page-editor-select2-container">
                <label htmlFor="footerType">Footer Style</label>
                <select
                  name="footerType"
                  value={values.footerType || ''}
                  onChange={handleChange}
                >
                  <option value="">
                    (default:{' '}
                    {(FOOTER_TYPE[cardable.footer_type] || {}).label || 'Gray'})
                  </option>
                  {Object.keys(FOOTER_TYPE).map((key) => (
                    <option key={key} value={key}>
                      {FOOTER_TYPE[key].label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="material-textfield invisible-select page-editor-select2-container">
                <label htmlFor="textType">Text Color</label>
                <select
                  name="textType"
                  value={values.textType || ''}
                  onChange={handleChange}
                >
                  <option value="">
                    (default:{' '}
                    {(TEXT_TYPE[cardable.text_type] || {}).label || 'Dark'})
                  </option>
                  {Object.keys(TEXT_TYPE).map((key) => (
                    <option key={key} value={key}>
                      {TEXT_TYPE[key].label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="l-margin-top-sm">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="bg-green__btn l-margin-right-sm"
                >
                  Save Block
                </button>
                <button
                  type="submit"
                  onClick={onCancel}
                  className="bg-gray__btn l-margin-right-sm"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  title="Delete Block"
                  onClick={onDelete}
                  className="page-editor-edit-form__delete-icon"
                >
                  <i className="far fa-trash-alt" />
                </button>
              </div>
              {newBlock ? null : (
                <RunValidationTrigger validateForm={validateForm} />
              )}
            </form>
          )}
        />
      }}
    />
  </Panel>
)

const renderCardableSelect = ({
  cardType,
  exportData,
  cardableId,
  errors,
  setFieldValue,
}) => {
  const cardTypeConfig = CARD_TYPE[cardType]
  if (!cardTypeConfig) {
    return null
  }

  let cardableFieldName = cardTypeConfig.label

  let SelectInput
  switch (cardType) {
    case CARD_TYPE.EVENT_OCCURRENCE.key:
      SelectInput = EventSelectInput
      break
    case CARD_TYPE.PAGE.key:
      SelectInput = PageSelectInput
      break
    case CARD_TYPE.MESSAGE.key:
      SelectInput = MessageSelectInput
      break
    case CARD_TYPE.MESSAGE_SERIES.key:
      SelectInput = MessageSeriesSelectInput
      break
    case CARD_TYPE.MOST_RECENT_MESSAGE.key:
      return <MostRecentMessageLoader />
    default:
      return null
  }

  return (
    <SelectInput
      fieldName={cardableFieldName}
      value={cardableId || ''}
      error={errors.cardableId}
      exportedName={get(exportData, 'cardableId.name')}
      onChange={(event) => setFieldValue('cardableId', event.target.value)}
    />
  )
}

const handleCardTypeChange = ({ event, setFieldValue }) => {
  const cardType = event.target.value
  setFieldValue('cardType', cardType)
  setFieldValue('cardableId', null)
}

const validate = (values) => {
  const typeConfig = CARD_TYPE[values.cardType]
  try {
    const errors = {}
    if (!values.cardType) {
      errors.cardType = 'Card type is required'
      return errors
    }
    if (!values.cardableId && typeConfig.requiresCardableId) {
      let cardableFieldName = typeConfig.label
      errors.cardableId = `${cardableFieldName} is required`
    }
    if (!values.featuredImageId && typeConfig.requiresFeaturedImage) {
      errors.featuredImageId = 'Featured image is required'
    }
    if (!values.title && typeConfig.requiresTitles) {
      errors.title = 'Title is required'
    }
    if (!values.subtitle && typeConfig.requiresTitles) {
      errors.subtitle = 'Subtitle is required'
    }
    if (!values.cardUrl && typeConfig.promptForCardLink) {
      errors.cardUrl = 'Card Link is required'
    }

    if (
      values.ctaButtonTitle ||
      (values.ctaLocation && values.ctaLocation.type) ||
      values.ctaRelativeUrl
    ) {
      if (!values.ctaButtonTitle) {
        errors.ctaButtonTitle = 'Button title is required'
      }

      const locationErrors = validateLocation(values.ctaLocation)

      if(locationErrors) errors.ctaLocation = locationErrors
    }

    return errors
  } catch (err) {
    alert('An error occurred while validating the form: ' + err)
  }
}

const renderIf = (condition, jsx) => condition && jsx

export default EditCardForm
