import React, { Component } from 'react'
import { isEqual } from 'lodash'
import { connect } from 'react-redux'

class IframeBlockView extends Component {
  formRef = React.createRef()
  iframeRef = React.createRef()
  containerRef = React.createRef()

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props)
  }

  componentDidMount() {
    this.loadBlockIntoIframe()
    window.addEventListener('resize', this.resizeIfComplete)
  }

  componentDidUpdate() {
    this.loadBlockIntoIframe()
  }

  loadBlockIntoIframe() {
    this.formRef.current.submit()
    this.scheduleResizeCheck()
  }

  componentWillUnmount() {
    this.unscheduleResizeCheck()
    window.removeEventListener('resize', this.resizeIfComplete)
  }

  scheduleResizeCheck() {
    this.unscheduleResizeCheck()
    this.resizeIntervalID = setInterval(this.resizeIfComplete, 500)
  }

  unscheduleResizeCheck() {
    if (this.resizeIntervalID) {
      clearInterval(this.resizeIntervalID)
      this.resizeIntervalID = null
    }
  }

  resizeIfComplete = () => {
    const iframeDoc = this.iframeRef.current.contentWindow.document
    const bodyPresent = iframeDoc.body && iframeDoc.body.childElementCount !== 0
    const doneLoading = iframeDoc.readyState === 'complete'

    if (bodyPresent && doneLoading) {
      this.resizeIFrameToFitContent()
      this.unscheduleResizeCheck()
    }
  }

  resizeIFrameToFitContent() {
    const iframe = this.iframeRef.current
    const container = this.containerRef.current

    const margin = 0
    const heightWithMargin =
      margin + iframe.contentWindow.document.body.offsetHeight
    iframe.height = heightWithMargin
    container.style.height = `${heightWithMargin + 32}px`
  }

  render() {
    const { page, config, gridPosition, height } = this.props
    const iframeName = `iframe-${config.uuid}`
    return (
      <div
        className="content-block-controller__content-block"
        ref={this.containerRef}
      >
        <form
          method="post"
          action={page.path + '/blocks'}
          target={iframeName}
          ref={this.formRef}
        >
          <input
            type="hidden"
            name="config_json"
            value={JSON.stringify(config)}
          />
          <input type="hidden" name="gridPosition" value={gridPosition} />
          <input type="hidden" name="height" value={height} />
          <input type="hidden" name="iframe" value="true" />
        </form>
        <iframe
          name={iframeName}
          ref={this.iframeRef}
          className="content-block-controller__iframe"
          scrolling="no"
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  page: state.page,
})

export default connect(
  mapStateToProps,
  null,
)(IframeBlockView)
